import React, { useEffect, useState } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Snackbar, Alert  } from '@mui/material';
import { Help } from '@mui/icons-material';

interface marketSalaryDataType{
    MarketDataNum:number|null,
    JobNum:number|null,
    JobTitle:string|null,
    MaximumSalary:number|null,
    MedianSalary:number|null,
    MinimumSalary:number|null
}

interface MarketDataProps {
    marketSalaryData:marketSalaryDataType |null
    handleUptoDateMarketData:()=>void
    message:boolean,
    loading:boolean
}

const MarketData: React.FC<MarketDataProps> = ({marketSalaryData,handleUptoDateMarketData,message,loading}) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);

     // Show the Snackbar when `message` prop is true
    useEffect(() => {
        if (message) {
            setOpenSnackbar(true);
        }
    }, [message]);

    // Handle closing the Snackbar
    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };
    
    return (
        
        <Box
            mb="2.78vw" // Converted to vw
            sx={{
              
                margin: '0vw', 
                display: 'flex',
                justifyContent: 'space-between',
                boxSizing: 'border-box',
            }}
        >
            <Box p="1.39vw 0" borderRadius="0.69vw" borderColor="divider" sx={{ width: '48%' }}>
                <Typography variant="h6">
                    Market data (import) <Help fontSize="small" />
                </Typography>
                <Button
                    variant="outlined"
                    sx={{
                        margin: '1.39vw',
                        marginLeft: 0,
                        color: '#7F56D9',
                        backgroundColor: '#fff',
                        border: '0.07vw solid #7F56D9',
                    }}
                    color="primary"
                    onClick={()=>{handleUptoDateMarketData()}}
                    disabled={loading}
                >
                    {loading?'Fetching market data...':'Import industry market range data'}
                </Button>
                <TableContainer component={Paper} sx={{ mt: '1.39vw' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Position</TableCell>
                                <TableCell>Min</TableCell>
                                <TableCell>Median</TableCell>
                                <TableCell>Max</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ backgroundColor: '#F9F5FF' }}>
                                <TableCell>{marketSalaryData?.JobTitle}</TableCell>
                                <TableCell>₹{marketSalaryData?.MinimumSalary || 'Not Available'}</TableCell>
                                <TableCell>₹{marketSalaryData?.MedianSalary || 'Not Available'}</TableCell>
                                <TableCell>₹{marketSalaryData?.MaximumSalary || 'Not Available'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box p="1.39vw 0" borderRadius="0.69vw" borderColor="divider" sx={{ width: '48%' }}>
                <Typography variant="h6">
                    Market Data (Internal benchmark) <Help fontSize="small" />
                </Typography>
                <Button
                    variant="outlined"
                    sx={{
                        margin: '1.39vw',
                        marginLeft: 0,
                        color: '#7F56D9',
                        backgroundColor: '#fff',
                        border: '0.07vw solid #7F56D9',
                    }}
                >
                    Get internal reference point
                </Button>
                <TableContainer component={Paper} sx={{ mt: '1.39vw' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Position</TableCell>
                                <TableCell>Min</TableCell>
                                <TableCell>Median</TableCell>
                                <TableCell>Max</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ backgroundColor: '#F9F5FF' }}>
                                <TableCell>Associate Director Project</TableCell>
                                <TableCell>$55,000</TableCell>
                                <TableCell>$75,000</TableCell>
                                <TableCell>$95,000</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {/* Snackbar for success message */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={5000} // Hide after 5 seconds
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Market data imported successfully!
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default MarketData;
