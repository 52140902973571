import React, { useEffect, useState } from 'react';
import { StyledWrapper } from './Candidates..styles';
import MailIcon from '@mui/icons-material/Mail';
import {
    Box,
    Button,
    useTheme,
    Typography,
    Menu,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    MenuItem,
    Badge,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import PersonIcon from '@mui/icons-material/Person';
import CandidateTable from './CandidatesTable';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import APIBaseUrl from '../../../../AppConfig/AppConfig.json'

const Candidates: React.FC<{JobNum:number}> = ({JobNum}) => {
    const theme = useTheme();
    const [isMatched, setIsMatched] = useState(true);
    const [matchedCount, setMatchedCount] = useState(0);
    const [unMatchedCount, setUnMatchedCount] = useState(0);
    const [candidateRow, setCandidateRow] = useState([]);
    const [candidateHeader, setCandidateHeader] = useState([]);
    const [candidateData,setCandidateData]=useState([])
    
    const nonHeaderArray = [
        'CandidateNum',
        'CandidateName',
        'SelectedStatus',
        'UpdatedBy',
        'MatchPct',
        'MatchStatus',
        'UpdatedDate',
        'EmailID',
    ];
    const { tenant } = useParams<{ tenant: string }>(); 
    const baseURL = window.location.origin.endsWith('/') 
  ? window.location.origin 
  : `${window.location.origin}/`;

    let candidateTableHeader: any = [];
    const tableHeader = [
        {
            id: 'CandidateName',
            label: 'Name',
        },
        {
            id: 'UpdatedBy',
            label: 'Updated by',
        },
        {
            id: 'MatchPct',
            label: 'Matched',
        },
    ];
    const { id } = useParams();
    const filterOptions = [
        {
            name: 'Show only 95% above',
            startValue: 95,
            endValue: 100,
        },
        {
            name: '80% - 95%',
            startValue: 80,
            endValue: 95,
        },
        {
            name: '70% - 80%',
            startValue: 70,
            endValue: 80,
        },
        {
            name: '60% - 70%',
            startValue: 60,
            endValue: 70,
        },
        {
            name: 'Show below 60%',
            startValue: 1,
            endValue: 60,
        },
    ];
    
    useEffect(() => {
        const getTableDetails = async (JobNum) => {
            try {
                const response = await axios.get(`${APIBaseUrl.apiBaseUrl}/job/candidates/${JobNum}`,
                    {
                        headers: {
                            'X-Tenant': tenant,
                            'X-App': baseURL
                        }
                    });
                setCandidateData(response.data);
            } catch (e) {
                console.log(e);
            }
        };
    
        getTableDetails(JobNum);
    }, [JobNum]);
    
    
    useEffect(() => {
            if(candidateData.length>0) {
                candidateTableHeader = [];
                const filterHeader = Object.keys(candidateData[0]).filter(
                    (val) => !nonHeaderArray.includes(val),
                );
                const structHeader: any = [];
                candidateTableHeader = [...tableHeader];
                filterHeader.forEach((val, i) => {
                    let headerLabel = val
                        .replace(/([A-Z])/g, ' $1')
                        .replace(/^./, function (str) {
                            return str.toUpperCase();
                        });
                    structHeader.push({ id: val, label: headerLabel });
                    candidateTableHeader.splice(i + 1, 0, {
                        id: val,
                        label: headerLabel,
                    });
                });
                setCandidateHeader(candidateTableHeader);
                if (isMatched) {
                    let candidateTableRow: any = [];
                    let matchedNo = 0;
                    let unMatchedNo = 0;
                    candidateData.forEach((val) => {
                        if (val['MatchStatus']) {
                            candidateTableRow.push(val);
                            matchedNo++;
                        }
                        if (!val['MatchStatus']) {
                            unMatchedNo++;
                        }
                    });
                    setMatchedCount(matchedNo);
                    setUnMatchedCount(unMatchedNo);
                    setCandidateRow(candidateTableRow);
                }
            } 
        
       
    }, [candidateData]);

    const getSelectedMatchRows = (event: string = '') => {
        let candidateTableRow: any = [];
        if (event == 'matched') {
            candidateData.forEach((val) => {
                if (val['MatchStatus']) {
                    candidateTableRow.push(val);
                }
            });
        } else {
            candidateData.forEach((val) => {
                if (!val['MatchStatus']) {
                    candidateTableRow.push(val);
                }
            });
        }
        return candidateTableRow;
    };
    

    const matchedValue = 'matched';

    const handleResume = (event: string) => {
        setIsMatched(event == matchedValue);
        handleFilterApply(event);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [selectedFilters, setSelectedFilters] = React.useState([]);
    const [tempSelectedFilters, setTempSelectedFilters] = React.useState([]);
    
    const handleFilterToggle = (index: number) => () => {
        const filters = [...tempSelectedFilters];
        const newlySelectedOption = filterOptions[index];
        const currentIndex = filters.findIndex(
            (existingOption: any) =>
                existingOption.name === newlySelectedOption.name,
        );
        const newSelectedFilters: any = [...filters];
        if (currentIndex === -1) {
            newSelectedFilters.push(newlySelectedOption);
        } else {
            newSelectedFilters.splice(currentIndex, 1);
        }

        setTempSelectedFilters(newSelectedFilters);
    };

    const handleFilterApply = (event: any) => {
        const percentFilteredRows: any = [];
        const param =
            typeof event === 'string' ? event : isMatched ? matchedValue : '';
        const filteredMatchRows = getSelectedMatchRows(param);
        if (!tempSelectedFilters.length) {
            setCandidateRow(filteredMatchRows);
            setSelectedFilters(tempSelectedFilters);
            return;
        }
        filteredMatchRows.forEach((row: any) => {
            if (
                tempSelectedFilters.some(
                    (filter: any) =>
                        row['MatchPct'] >= filter.startValue &&
                        row['MatchPct'] < filter.endValue,
                )
            ) {
                percentFilteredRows.push(row);
            }
        });
        setCandidateRow(percentFilteredRows);
        setSelectedFilters(tempSelectedFilters);
    };
    return (
        <StyledWrapper className="candidate-page-wrapper">
            <Box className="candidates-header">
                <Box className="candidates-lhs">
                    <PersonIcon />
                    <span>Total Candidates : {candidateData.length}</span>
                </Box>
                <Box className="candidates-rhs">
                    <Badge
                        variant="dot"
                        color="primary"
                        invisible={selectedFilters.length === 0}
                        sx={{
                            borderColor: theme.palette.custom.border,
                            px: 0,
                            ml: 1,
                            ['.MuiBadge-badge']: {
                                top: 0,
                                right: '10px',
                                background: theme.palette.error.main,
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                            },
                        }}
                    >
                        <Button
                            className="filter-btn"
                            variant="outlined"
                            size="small"
                            onClick={handleClick}
                        >
                            <FontAwesomeIcon
                                size="xl"
                                color={theme.palette.text.primary}
                                stroke="0"
                                icon={faSliders}
                            ></FontAwesomeIcon>
                        </Button>
                    </Badge>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                        slotProps={{
                            paper: {
                                style: {
                                    padding: '0 8px',
                                    backgroundColor: theme.palette.custom.white,
                                },
                            },
                        }}
                    >
                        <FormControl component="fieldset" variant="standard">
                            <FormGroup>
                                {filterOptions.map((option, index) => {
                                    const labelId = `checkbox-list-label-${index}`;
                                    return (
                                        <MenuItem
                                            key={option.name}
                                            sx={{
                                                backgroundColor:
                                                    theme.palette.custom
                                                        .lightWhiteBg,
                                                color: theme.palette.text
                                                    .primary,
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                my: '3px',
                                                padding: '0 8px 0 0',
                                                borderRadius: '4px',
                                            }}
                                            onClick={handleFilterToggle(index)}
                                        >
                                            <FormControlLabel
                                                htmlFor={option.name}
                                                sx={{
                                                    mx: 0,
                                                    span: {
                                                        fontSize: '12px',
                                                        padding: '4px 8px',
                                                        ['&.MuiTypography-body1']:
                                                            {
                                                                paddingLeft: 0,
                                                            },
                                                    },
                                                    svg: {
                                                        fill: theme.palette
                                                            .custom.border,
                                                    },
                                                    ['.Mui-checked']: {
                                                        svg: {
                                                            fill: theme.palette
                                                                .primary.main,
                                                        },
                                                    },
                                                    ['MuiCheckbox-root']: {
                                                        py: 0,
                                                    },
                                                }}
                                                control={
                                                    <Checkbox
                                                        className="filter-checkbox"
                                                        // onChange={handleChange}
                                                        checked={tempSelectedFilters.some(
                                                            (filteredOption) =>
                                                                filteredOption.name ===
                                                                option.name,
                                                        )}
                                                        tabIndex={-1}
                                                        name={option.name}
                                                        id={labelId}
                                                        sx={{
                                                            px: 2,
                                                        }}
                                                    />
                                                }
                                                label={option.name}
                                            />
                                        </MenuItem>
                                    );
                                })}
                                <Button
                                    className="apply-btn"
                                    variant="contained"
                                    disableElevation
                                    sx={{
                                        mt: 1,
                                        color: theme.palette.custom.white,
                                    }}
                                    onClick={handleFilterApply}
                                >
                                    Apply
                                </Button>
                            </FormGroup>
                        </FormControl>
                    </Menu>
                    <Button
                        variant="contained"
                        className="mail-btn"
                        startIcon={<MailIcon />}
                        size="small"
                    >
                        {/* <MailIcon /> */}
                        <Typography>Send a mail </Typography>
                    </Button>
                </Box>
            </Box>
            <Box className="resume-selection-btn">
                <Button
                    className={
                        isMatched ? 'matched matched-clicked' : 'matched'
                    }
                    size="small"
                    onClick={(event) => handleResume('matched')}
                >
                    Matched Resumes
                    <span className="resume-count">{matchedCount}</span>
                </Button>
                <Button
                    className={
                        !isMatched
                            ? 'un-matched un-matched-clicked'
                            : 'un-matched'
                    }
                    size="small"
                    onClick={(event) => handleResume('unmatched')}
                >
                    Unmatched Resumes
                    <span className="resume-count">{unMatchedCount}</span>
                </Button>
            </Box>
            <Box>
                <CandidateTable
                    rows={candidateRow}
                    headCells={candidateHeader}
                    matchedStatus={isMatched}
                />
            </Box>
        </StyledWrapper>
    );
};

export default Candidates;