import React, { useState } from 'react';
import { Box, Button, Paper } from '@mui/material';
import { theme } from '../../../theme';
import ProfileEdit from './ProfileEdit';
import ProfileChangePassword from './ProfileChangePassword';

interface data {
  UserName: string;
  UserID: string;
  EmailID: string;
  UserNum: number;
}

interface ProfileEditPanelProps {
  onClose: () => void; // Accept an onClose prop
  userData?: data | undefined;
  updateUserData: (newData: data) => void;
  setUpdatedImage:(value:string|null)=> void;
}

const ProfileEditPanel: React.FC<ProfileEditPanelProps> = ({ onClose,userData,updateUserData ,setUpdatedImage}) => {
  const [activeTab, setActiveTab] = useState<'profile' | 'changePassword'>('profile');

  const handleTabChange = (tab: 'profile' | 'changePassword') => {
    setActiveTab(tab);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Light gray transparent overlay
        zIndex: 1200, // Ensure the backdrop is above other content but below dialogs if necessary
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={onClose} // Close the panel when clicking the backdrop
    >
      {/* Stop propagation to prevent closing when clicking inside the panel */}
      <Paper
        onClick={(e) => e.stopPropagation()}
        sx={{
          width: '46vw',
          height: '85vh',
          position: 'relative',
          borderRadius: '16px',
          zIndex: 1300, // Ensure panel is above backdrop
        }}
      >
        <Box
          display="flex"
          bgcolor="white"
          borderRadius="16px"
          sx={{
            height: '100%',
            flexDirection: 'row',
            transition: 'opacity 0.3s ease, top 0.3s ease',
          }}
        >
          {/* Sidebar */}
          <Box
            sx={{
              borderRight: '1px solid #eee',
              width: '232px',
              py: 2,
              px: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              onClick={() => handleTabChange('profile')}
              sx={{
                width: '100%',
                height: '32px',
                padding: '5px 16px',
                borderRadius: '8px',
                marginBottom: '8px',
                backgroundColor: activeTab === 'profile' ? '#F4EBFF' : 'transparent', // Active tab background
                color: theme.palette.primary.main,
                display: 'flex',
                justifyContent: 'flex-start',
                '&:hover': {
                  backgroundColor: '#F4EBFF',
                  color: theme.palette.primary.main,
                },
                '&:focus': {
                  backgroundColor: '#F4EBFF',
                  outline: 'none',
                  color: theme.palette.primary.main,
                },
              }}
            >
              Profile
            </Button>
            <Button
              onClick={() => handleTabChange('changePassword')}
              sx={{
                width: '100%',
                height: '32px',
                padding: '5px 16px',
                borderRadius: '8px',
                marginBottom: '8px',
                backgroundColor: activeTab === 'changePassword' ? '#F4EBFF' : 'transparent', // Active tab background
                color: theme.palette.primary.main, // Active tab color
                display: 'flex',
                justifyContent: 'flex-start',
                '&:hover': {
                  backgroundColor: '#F4EBFF',
                  color: theme.palette.primary.main,
                },
                '&:focus': {
                  backgroundColor: '#F4EBFF',
                  outline: 'none',
                  color: theme.palette.primary.main,
                },
              }}
            >
              Change Password
            </Button>
          </Box>

          {/* Form Section */}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              overflowY: 'auto',
              padding: 2,
            }}
          >
            {activeTab === 'profile' && userData && <ProfileEdit userData={userData} onClose={onClose} updateUserData={updateUserData} setUpdatedImage={setUpdatedImage}/>}
            {activeTab === 'changePassword' && userData && <ProfileChangePassword userNum={userData.UserNum} onClose={onClose} />}
          </Box>
        </Box>

        {/* Close Button */}
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            variant="text"
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 16,
              color: theme.palette.text.secondary,
              minWidth: 0,          // Ensures button width respects the specified width
              width: '30px',         // Sets the width to 30px
              height: '30px',        // Sets the height to 30px
              padding: 0,            // Removes padding for a snug fit
              display: 'flex',       // Centers icon
              alignItems: 'center',  // Centers icon vertically
              justifyContent: 'center', // Centers icon horizontally
              '& .MuiButtonBase-root': {
                width: '30px',
                height: '30px',
                maxWidth: '30px',
              },
            }}
          >
            <i className="fa-solid fa-xmark fa-lg"></i>
          </Button>
        </Box>

      </Paper>
    </Box>
  );
};

export default ProfileEditPanel;
