import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import ImportJobs from '../ImportJobs/ImportJobs';
import { useParams } from 'react-router-dom';

const JobsHead: React.FC = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const { tenant } = useParams<{ tenant: string }>(); 

    const handleCreateClick = () => {
        navigate(`/${tenant}/jobs/create`);
    };

    const handleImportClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="head-content">
            <Box className="title">
                <Typography variant="h5">Jobs</Typography>
            </Box>
            <Box className="action-container">
                {/* <Button
                    className="import-btn"
                    variant="outlined"
                    size="small"
                    startIcon={
                        <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                    }
                    onClick={handleImportClick}
                >
                    <Typography>Import</Typography>
                </Button> */}
                <Button
                    className="create-btn"
                    variant="contained"
                    size="small"
                    disableElevation
                    startIcon={
                        <FontAwesomeIcon icon={faCirclePlus}></FontAwesomeIcon>
                    }
                    onClick={handleCreateClick}
                >
                    <Typography>Create</Typography>
                </Button>
            </Box>
            <ImportJobs open={open} onClose={handleClose} />{' '}
        </Box>
    );
};

export default JobsHead;
