import React, { useState, ChangeEvent } from 'react';
import { Link  } from 'react-router-dom'; // Correct import for linking
import { Grid, Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import {
  ImageContainer, FormContainer, StyledButton, StyledDivider,
  IconContainer, StyledTextField, Heading, TermsContainer,
  TermsText, CheckboxStyled
} from './SignUp.styles';
import BackGround from '../../../Assets/AuthBackGround/AuthBG.png';
import LinkedIn from "../../../Assets/AuthBackGround/LinkedIn.png";
import Facebook from "../../../Assets/AuthBackGround/facebook.png";
import GLogo from "../../../Assets/AuthBackGround/GLogo1.png";
import { useParams } from 'react-router-dom';
import { theme } from '../../../theme';

interface SignUpProps {}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const SignUp: React.FC<SignUpProps> = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { tenant } = useParams<{ tenant: string }>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = () => {
    if (recaptchaToken && termsAccepted) {
      console.log('Form submitted', formValues, recaptchaToken);
    } else {
      console.log('Please complete the reCAPTCHA and accept the terms');
    }
  };

  const handleTermsChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  return (
    <Grid container className="sign-up-container" sx={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
      {/* Left side with image */}
      <Grid item xs={12} md={6} className="image-grid-item">
        <ImageContainer
          sx={{
            backgroundImage: `url(${BackGround})`,
            height: '100vh',
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          className="image-container"
        />
      </Grid>

      {/* Right side with form */}
      <Grid item xs={12} md={6} className="form-grid-item" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <FormContainer className="form-container" sx={{ width: '36vw', padding: 2 }}>
          {/* Group One */}
          <Box className="group-one" sx={{ width: '36vw', marginBottom: '10px' }}>
          <Typography variant="h4" >
              Sign Up
            </Typography>
            <Typography variant="subtitle1" className="existing-account-text" sx={{ color: theme.palette.text.secondary }}>
              Sign up with an existing account
            </Typography>
          </Box>

          {/* Group Two */}
          <Box className="group-two">
            <IconContainer className="icon-container" sx={{ position: 'relative', marginBottom: '10px' }}>
              <img src={LinkedIn} alt="LinkedIn Logo" width={'42vh'} height={'42vh'} className="social-icon" />
              <img src={Facebook} alt="Facebook Logo" width={'42vh'} height={'42vh'} className="social-icon" />
              <img src={GLogo} alt="Google Logo" width={'42vh'} height={'42vh'} className="social-icon" />
            </IconContainer>

            <StyledDivider className="styled-divider"><Typography variant='body2'>or sign up with Email</Typography></StyledDivider>

            {/* Single Grid per Row */}
            <Grid container spacing={2} sx={{ width: '36vw' }}>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="First Name"
                  name="firstName"
                  value={formValues.firstName}
                  onChange={handleChange}
                  fullWidth
                  required
                  size="small"
                  className="styled-text-field"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Last Name"
                  name="lastName"
                  value={formValues.lastName}
                  onChange={handleChange}
                  fullWidth
                  required
                  size="small"
                  className="styled-text-field"
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  label="Email"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  fullWidth
                  required
                  size="small"
                  className="styled-text-field"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Password"
                  type="password"
                  name="password"
                  value={formValues.password}
                  onChange={handleChange}
                  fullWidth
                  required
                  size="small"
                  className="styled-text-field"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Confirm Password"
                  type="password"
                  name="confirmPassword"
                  value={formValues.confirmPassword}
                  onChange={handleChange}
                  fullWidth
                  required
                  size="small"
                  className="styled-text-field"
                />
              </Grid>

         
                <StyledButton
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!recaptchaToken || !termsAccepted}
                  size="small"
                  className="styled-button"
                >
                  Sign Up
                </StyledButton>

              <Grid item xs={12}>
                <TermsContainer className="terms-container">
                  <FormControlLabel
                    control={<CheckboxStyled checked={termsAccepted} onChange={handleTermsChange} size="small"  />}
                    label={
                      <TermsText variant="body2" sx={{ fontSize: '1.5vh'}} className="terms-text">
                        By continuing, you accept our{' '}
                        <Link to="/terms" target="_blank" className="terms-link" style={{textDecoration:'none'}}>standard terms and conditions</Link> and our{' '}
                        <Link to="/privacy" target="_blank" className="privacy-link" style={{textDecoration:'none'}}>privacy policy</Link>.
                      </TermsText>
                    }
                    sx={{ marginLeft: '10px' }}
                  />
                </TermsContainer>
              </Grid>
            </Grid>
          </Box>

          {/* Group Three */}
          <Box mt={2} textAlign="left" className="sign-in-container" sx={{ position: 'relative', top: '-15px', }}>
            <Typography variant="body2" className="sign-in-text" sx={{fontSize: '1.8vh'}}>
              Already have an account?{' '}
              <Link to={`/${tenant}/SignIn`} className="sign-in-link" style={{ fontSize: '1.8vh',textDecoration:'none' }}>Sign In</Link>
            </Typography>
          </Box>
        </FormContainer>
      </Grid>
    </Grid>
  );
};

export default SignUp;
