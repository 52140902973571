// PreboardingContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface PreboardingContextType {
  offerAccepted: boolean;
  offerDeclined: boolean;  // New state for tracking declined offer
  setOfferAccepted: (value: boolean) => void;
  setOfferDeclined: (value: boolean) => void;

}

interface PreboardingProviderProps {
  children: ReactNode;
}

const PreboardingContext = createContext<PreboardingContextType | undefined>(undefined);

export const PreboardingProvider: React.FC<PreboardingProviderProps> = ({ children }) => {
  const [offerAccepted, setOfferAccepted] = useState(false);
  const [offerDeclined, setOfferDeclined] = useState(false);  // Initialize as false

  return (
    <PreboardingContext.Provider value={{ offerAccepted, setOfferAccepted, offerDeclined, setOfferDeclined}}>
      {children}
    </PreboardingContext.Provider>
  );
};

export const usePreboarding = (): PreboardingContextType => {
  const context = useContext(PreboardingContext);
  if (!context) {
    throw new Error('usePreboarding must be used within a PreboardingProvider');
  }
  return context;
};
