import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  IconButton,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StyledTypography } from "../ImportNewJob.styles";

interface Recruiter {
  id: number;
  name: string;
  role: string;
  image: string;
  isAvailable: boolean;
}

interface AssignRecruitersProps {
  open: boolean;
  onClose: () => void;
}

const AssignRecruiters: React.FC<AssignRecruitersProps> = ({ open, onClose }) => {
  const [search, setSearch] = useState<string>("");
  const [selectedRecruiter, setSelectedRecruiter] = useState<number | null>(null);

  const recruiters: Recruiter[] = [
    {
      id: 1,
      name: "Olivia Rhye",
      role: "Product Designer",
      image: "https://i.pravatar.cc/150?img=1",
      isAvailable: true,
    },
    {
      id: 2,
      name: "Olivia Rhye",
      role: "Product Designer",
      image: "https://i.pravatar.cc/150?img=2",
      isAvailable: true,
    },
    {
      id: 3,
      name: "Olivia Rhye",
      role: "Product Designer",
      image: "https://i.pravatar.cc/150?img=3",
      isAvailable: true,
    },
    {
      id: 4,
      name: "Olivia Rhye",
      role: "Product Designer",
      image: "https://i.pravatar.cc/150?img=4",
      isAvailable: true,
    },
  ];

  const handleSelect = (id: number) => {
    setSelectedRecruiter(id);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const filteredRecruiters = recruiters.filter((recruiter) =>
    recruiter.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}

      fullScreen
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      PaperProps={{
        sx: {
          maxWidth: '20vw', // Responsive width
          height: '100vh',
          margin: 0,
          overflow: 'hidden',
          position: 'absolute',
          right: 0,
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" p={1}>
        <Typography variant="h6" px={2}>Assign Recruiters</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          placeholder="Search"
          value={search}
          onChange={handleSearchChange}
          sx={{
            mb: 2,
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
            },
          }}
        />
        <List>
          {filteredRecruiters.map((recruiter) => (
            <ListItem
              key={recruiter.id}
              disableGutters
              sx={{
                display: "flex",
                alignItems: "center",
                '& .MuiListItem-root':{px:0}
              }}
            >
              <ListItemAvatar>
                <Avatar src={recruiter.image} alt={recruiter.name} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <span>
                    {recruiter.name}{" "}
                    <StyledTypography variant="body3" sx={{ py: 0, ml: 0.5 }}>
                      {recruiter.id}
                    </StyledTypography>
                  </span>
                }
                secondary={recruiter.role}
                sx={{ ml: 1 }}
              />

              <Radio
                checked={selectedRecruiter === recruiter.id}
                onChange={() => handleSelect(recruiter.id)}
                value={recruiter.id}
                name="recruiter"
                color="primary"
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <Box display="flex" justifyContent="space-between" p={2} gap={1} borderTop="1px solid #e0e0e0">
        <Button
          variant="outlined"
          onClick={onClose}
          fullWidth
          sx={{
            borderRadius: "8px",
            textTransform: "none",
            color: "#757575",
            borderColor: "#757575",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={selectedRecruiter === null}
          sx={{
            borderRadius: "8px",
            textTransform: "none",
            backgroundColor: "#6B4EFF",
          }}
        >
          Assign
        </Button>
      </Box>
    </Dialog>
  );
};

export default AssignRecruiters;
