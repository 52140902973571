import {create} from 'zustand';

type TJobData = {
    JobTitle: string;
    Department: string;
    Status: string;
    JobNum: number;
    CreatedDate: any|Date;
    JobDescr:string;
    CreatedBy:string;
};

interface JobStore {
    selectedJob: TJobData | null;
    selectJob: (job: TJobData| null) => void;
    updateJobStatus: (jobNum: number, status: string) => void;
    
}

type TuserData={
    UserName:string;
    EmailID:string;
    UserNum:number
    DefaultColumn?:number
}

interface HMData{
    UserData:TuserData[]| null;
    setUserData:(user:TuserData[])=>void;
}

interface RData{
    RecruiterData:TuserData[]
    setRecruiterData:(user:TuserData[])=>void;
}

export const useJobStore = create<JobStore>((set) => ({
    selectedJob: null,
    selectJob: (job) => set({ selectedJob: job }),
    updateJobStatus: (jobNum, status) => set((state) => {
        if (state.selectedJob && state.selectedJob.JobNum === jobNum) {
            return { selectedJob: { ...state.selectedJob, Status: status } };
        }
        return state;
    }),
}));

export const useUserStore = create<HMData>((set) => ({
    UserData: [],
    setUserData: (user) => set({ UserData: user }),
}));

export const useRecruiterStore = create<RData>((set) => ({
    RecruiterData: [],
    setRecruiterData: (user) => set({ RecruiterData: user }),
}));