import { css, styled } from '@mui/material/styles';

export const StyledWrapper = styled('div')(
    ({ theme }: any) => css`
        .nav-bar-wrapper {
            padding: 0px 24px;
            background-color: ${theme.palette.primary.main};
            min-height: 64px;
        }
        .active-nav button {
            color: #ffffff !important;
        }
        .nav-menu-btn {
            margin-right: 15px;
                padding: 0;
                max-height: 38px;
            a {
                display: flex;
                align-items: center;
                padding: 9px 13px;
                color: #013243;
                text-decoration: none;
                svg {
                    position: relative;
                    margin-right: 7px;
                    width: 14px;
                    height: 16px;
                }
                span {
                    position: relative;
                    top: 1px;
                }
            }
            &:has(> a.active-nav) {
                background-color: #013243 !important;
                border-radius: 4px;
                a {
                    color: white;
                }
            }
        }
    `,
);