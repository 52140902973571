import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#7F56D9',
      light: '#F9F5FF',
    },
    secondary: {
      main: '#B692F6',
      light: '#FCFAFF',
    },
    success: {
      main: '#079455',
      light:'#ECFDF3',
    },
    error: {
      main: '#F04438',
      light:'#FEF3F2',
    },
    warning: {
      main: '#F79009',
    },
    info: {
      main: '#4069F5',
    },
    background: {
      default: '#FFFFFF',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#667085',
    },
    divider: '#E3E8EB',
    action: {
      disabledBackground: '#E1E1E1',
    },
    custom: {
      border: '#D0D5DD',
      borderLight: '#E4E7EC',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 960,
      xl: 1280,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '100%', // Default maximum width for all containers
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius:'1vw',
          '&:hover': {
            backgroundColor: '#F9F5FF',
          },
        },
      },},
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #E4E7EC',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '0px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          borderRadius: '8px',
          boxShadow: 'none',
          textTransform: 'none',
          [`@media (max-width:600px)`]: { // xs screen (mobile)
            fontSize: '0.75rem',
          },
          [`@media (min-width:600px) and (max-width:960px)`]: { // sm screen
            fontSize: '0.825rem',
          },
          [`@media (min-width:960px) and (max-width:1280px)`]: { // md screen
            fontSize: '0.875rem',
          },
          [`@media (min-width:1280px) and (max-width:1920px)`]: { // lg screen
            fontSize: '0.925rem',
          },
          [`@media (min-width:1920px)`]: { // xl screen
            fontSize: '1rem',
          },
        },
        outlined: {
          borderColor: '#D0D5DD',
          backgroundColor: '#FFFFFF',
          color: '#000000',
          fontSize: '0.875rem',
          '&:hover': {
            boxShadow: 'none',
            borderColor: '#D0D5DD',
          },
        },
        contained: {
          backgroundColor:"#7F56D9",
          opacity:1,
          boxShadow: 'none',
          fontSize: '0.875rem',
          '&:hover': {
            boxShadow: 'none',            
            backgroundColor:"#7F56D9",
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
 spacing: (factor: number) => `${factor * 0.556}vw`,
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontSize: 12,
    h1: {
      fontWeight: 'bold',
      fontSize: '2.5rem',
      [`@media (max-width:600px)`]: { // xs
        fontSize: '1.5rem',
      },
      [`@media (min-width:600px) and (max-width:960px)`]: { // sm
        fontSize: '2rem',
      },
      [`@media (min-width:960px) and (max-width:1280px)`]: { // md
        fontSize: '2.25rem',
      },
      [`@media (min-width:1280px) and (max-width:1920px)`]: { // lg
        fontSize: '2.5rem',
      },
      [`@media (min-width:1920px)`]: { // xl
        fontSize: '2.75rem',
      },
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '2rem',
      [`@media (max-width:600px)`]: { 
        fontSize: '1.25rem',
      },
      [`@media (min-width:600px) and (max-width:960px)`]: { 
        fontSize: '1.75rem',
      },
      [`@media (min-width:960px) and (max-width:1280px)`]: { 
        fontSize: '1.875rem',
      },
      [`@media (min-width:1280px) and (max-width:1920px)`]: { 
        fontSize: '2rem',
      },
      [`@media (min-width:1920px)`]: { 
        fontSize: '2.25rem',
      },
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '1.75rem',
      [`@media (max-width:600px)`]: { 
        fontSize: '1.125rem',
      },
      [`@media (min-width:600px) and (max-width:960px)`]: { 
        fontSize: '1.5rem',
      },
      [`@media (min-width:960px) and (max-width:1280px)`]: { 
        fontSize: '1.625rem',
      },
      [`@media (min-width:1280px) and (max-width:1920px)`]: { 
        fontSize: '1.75rem',
      },
      [`@media (min-width:1920px)`]: { 
        fontSize: '1.875rem',
      },
    },
    h4: {
      fontWeight: '600',
      fontSize: '1.875rem',
      [`@media (max-width:600px)`]: {
        fontSize: '1.125rem',
      },
      [`@media (min-width:600px) and (max-width:960px)`]: {
        fontSize: '1.5rem',
      },
      [`@media (min-width:960px) and (max-width:1280px)`]: {
        fontSize: '1.625rem',
      },
      [`@media (min-width:1280px) and (max-width:1920px)`]: {
        fontSize: '1.75rem',
      },
      [`@media (min-width:1920px)`]: {
        fontSize: '1.875rem',
      },
    },
    h5: {
      fontWeight: '600',
      color: '#000000',
      fontSize: '1.5rem',
      [`@media (max-width:600px)`]: {
        fontSize: '1rem',
      },
      [`@media (min-width:600px) and (max-width:960px)`]: {
        fontSize: '1.25rem',
      },
      [`@media (min-width:960px) and (max-width:1280px)`]: {
        fontSize: '1.375rem',
      },
      [`@media (min-width:1280px) and (max-width:1920px)`]: {
        fontSize: '1.5rem',
      },
      [`@media (min-width:1920px)`]: {
        fontSize: '1.625rem',
      },
    },
    h6: {
      fontWeight: '500',
      color: '#000000',
      fontSize: '1.25rem',
      [`@media (max-width:600px)`]: {
        fontSize: '0.875rem',
      },
      [`@media (min-width:600px) and (max-width:960px)`]: {
        fontSize: '1rem',
      },
      [`@media (min-width:960px) and (max-width:1280px)`]: {
        fontSize: '1.125rem',
      },
      [`@media (min-width:1280px) and (max-width:1920px)`]: {
        fontSize: '1.25rem',
      },
      [`@media (min-width:1920px)`]: {
        fontSize: '1.375rem',
      },
    },
    subtitle1: {
      fontWeight: '400',
      color:'#475467',
      fontSize: '1rem',
      [`@media (max-width:600px)`]: {
        fontSize: '0.875rem',
      },
      [`@media (min-width:600px) and (max-width:960px)`]: {
        fontSize: '0.95rem',
      },
      [`@media (min-width:960px) and (max-width:1280px)`]: {
        fontSize: '1rem',
      },
      [`@media (min-width:1280px) and (max-width:1920px)`]: {
        fontSize: '1.05rem',
      },
      [`@media (min-width:1920px)`]: {
        fontSize: '1.1rem',
      },
    },
    subtitle2: {
      fontWeight: '600',
      fontSize: '1rem',
      [`@media (max-width:600px)`]: {
        fontSize: '0.875rem',
      },
      [`@media (min-width:600px) and (max-width:960px)`]: {
        fontSize: '0.95rem',
      },
      [`@media (min-width:960px) and (max-width:1280px)`]: {
        fontSize: '1rem',
      },
      [`@media (min-width:1280px) and (max-width:1920px)`]: {
        fontSize: '1.05rem',
      },
      [`@media (min-width:1920px)`]: {
        fontSize: '1.1rem',
      },
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: '500',
      [`@media (max-width:600px)`]: {
        fontSize: '0.75rem',
      },
      [`@media (min-width:600px) and (max-width:960px)`]: {
        fontSize: '0.8rem',
      },
      [`@media (min-width:960px) and (max-width:1280px)`]: {
        fontSize: '0.85rem',
      },
      [`@media (min-width:1280px) and (max-width:1920px)`]: {
        fontSize: '0.875rem',
      },
      [`@media (min-width:1920px)`]: {
        fontSize: '0.95rem',
      },
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: '400',
      [`@media (max-width:600px)`]: {
        fontSize: '0.75rem',
      },
      [`@media (min-width:600px) and (max-width:960px)`]: {
        fontSize: '0.8rem',
      },
      [`@media (min-width:960px) and (max-width:1280px)`]: {
        fontSize: '0.85rem',
      },
      [`@media (min-width:1280px) and (max-width:1920px)`]: {
        fontSize: '0.875rem',
      },
      [`@media (min-width:1920px)`]: {
        fontSize: '0.95rem',
      },
    },
    caption: {
      fontSize: '0.75rem',
    },
    overline: {
      fontSize: '0.75rem',
      textTransform: 'uppercase',
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      [`@media (max-width:600px)`]: {
        fontSize: '0.75rem',
      },
      [`@media (min-width:600px) and (max-width:960px)`]: {
        fontSize: '0.8rem',
      },
      [`@media (min-width:960px) and (max-width:1280px)`]: {
        fontSize: '0.85rem',
      },
      [`@media (min-width:1280px) and (max-width:1920px)`]: {
        fontSize: '0.875rem',
      },
      [`@media (min-width:1920px)`]: {
        fontSize: '0.95rem',
      },
    },
    body3: {
      fontSize: '0.75rem',
      fontWeight: '400',
      color: '#101828',
    },
    subtitle3: {
      fontSize: '0.875rem',
      fontWeight: '300',
      color: '#475467',
    },
  },
});
