import { css, styled } from '@mui/material/styles';

export const StyledWrapper = styled('div')(
    ({ theme: { palette } }: any) => css`
        .app {
            text-align: center;
            font-family: 'Inter';
        }

        .main-content {
            background-color: ${palette.custom.white};
            height: calc(100vh - 134px);
            overflow-y: hidden;
            display: block;
            font-size: calc(10px + 2vmin);
            color: ${palette.text.primary};
        }
    `,
);

export const StyledPageWrapper = styled(
    'div',
    {},
)(
    ({ theme }: any) => css`
        width: 100%;
        height: 100%;
        overflow-y: auto;
    `,
);
