import React, { useState, useCallback } from 'react';
import { Box, Button, Divider, Grid, TextField, Typography, Dialog } from '@mui/material';
import { Area } from 'react-easy-crop';
import { getCroppedImg } from './cropImageHelper';
import CropPanel from './CropPanel';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import APIBaseURL from '../../../AppConfig/AppConfig.json';
import AvatarImage from '../../Navbar/AvatarImage';
import { validateEmail, validateUsername } from '../Validation/validation';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

interface Data {
  UserName: string;
  UserID: string;
  EmailID: string;
  UserNum: number;
}

interface ProfileEditProps {
  userData: Data; // Required userData prop
  onClose: () => void;
  updateUserData: (newData: Data) => void;
  setUpdatedImage: (value: string | null) => void;
}

const ProfileEdit: React.FC<ProfileEditProps> = ({ userData, onClose, updateUserData, setUpdatedImage }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [username, setUsername] = useState(userData.UserName);
  const [email, setEmail] = useState(userData.EmailID);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const { tenant } = useParams<{ tenant: string }>();
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const navigate = useNavigate();
  const [showEditProfile, setShowEditProfile] = useState(true);

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result as string);
        setIsCropDialogOpen(true);
        setShowEditProfile(false);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropSave = async () => {
    if (imageSrc && croppedAreaPixels) {
      const croppedImg = await getCroppedImg(imageSrc, croppedAreaPixels);
      setCroppedImage(croppedImg);
      setIsCropDialogOpen(false);
      setShowEditProfile(true);
    }
  };

  const handleAvatarClick = () => {
    document.getElementById('profileImageInput')?.click();
  };

  const handleSave = async () => {
    const token = localStorage.getItem('access_token');
    const newErrors: { [key: string]: string } = {};

    if (!validateUsername(username)) {
      newErrors.username = 'Username can only contain letters and numbers.';
    }
    if (!validateEmail(email)) {
      newErrors.email = 'Please enter a valid email address.';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if (!token) {
      navigate(`/${tenant}/SignIn`);
      return;
    }

    const hasChanges = username !== userData.UserName || email !== userData.EmailID || croppedImage !== null;

    if (!hasChanges) {
      onClose();
      return;
    } else {
      try {
        const response = await axios.post(`${APIBaseURL.apiBaseUrl}/user/edit_user_by_user`, {
          UserName: username,
          EmailID: email,
          UserNum: userData.UserNum,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Tenant': tenant,
            'X-App': baseURL,
          },
        });

        if (response.status === 200) {
          updateUserData({ ...userData, UserName: username, EmailID: email });

          if (croppedImage) {
            const formData = new FormData();
            formData.append('UserNum', userData.UserNum.toString());
            const responseData = await fetch(croppedImage);
            const blob = await responseData.blob();
            formData.append('Image', blob, `image-${Date.now()}.jpeg`);

            const imageResponse = await axios.post(`${APIBaseURL.apiBaseUrl}/user/edit_user_image`, formData, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
                'X-Tenant': tenant,
                'X-App': baseURL,
              },
            });

            if (imageResponse.status === 200) {
              setUpdatedImage(croppedImage);
            }
          }

          onClose();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} flex={1}>
      {showEditProfile ? (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  Edit profile
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'left', justifyContent: 'start', mt: 2 }}>
              <Typography variant="body2">Profile Picture</Typography>
              <Box onClick={handleAvatarClick} sx={{ cursor: 'pointer', display: 'inline-block' }}>
                {croppedImage ? (
                  <AvatarImage imageUrl={croppedImage} height="80px" width="80px" />
                ) : userData.UserNum ? (
                  <AvatarImage imageUrl={`${APIBaseURL.apiBaseUrl}/user/get_user_image/${userData.UserNum}`} height="80px" width="80px" />
                ) : (
                  <AccountCircleOutlinedIcon sx={{ fontSize: '80px' }} />
                )}
              </Box>
              <input
                type="file"
                accept="image/*"
                id="profileImageInput"
                style={{ display: 'none' }}
                onChange={handleImageChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="User ID"
                value={userData.UserID}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Username"
                placeholder="@"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                error={!!errors.username}
                helperText={errors.username}
                autoComplete="off"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!errors.email}
                helperText={errors.email}
                autoComplete="off"
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 2 }}>
            <Button variant="contained" fullWidth sx={{ mb: 2 }} onClick={handleSave}>
              Save
            </Button>
          </Box>
        </>
      ) : null}

      {isCropDialogOpen && imageSrc && (
        <CropPanel
          imageSrc={imageSrc}
          crop={crop}
          setCrop={setCrop}
          zoom={zoom}
          setZoom={setZoom}
          onCropComplete={onCropComplete}
          onSave={handleCropSave}
          onCancel={() => {
            setIsCropDialogOpen(false);
            setShowEditProfile(true)
          }}
        />
      )}

    </Box>
  );
};

export default ProfileEdit;
