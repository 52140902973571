import React from 'react';
import { Box, Button, FormControl, Select, MenuItem, Typography, InputLabel } from '@mui/material';
import { theme } from '../../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faAnglesRight, faArrowLeft, faArrowRight,} from '@fortawesome/free-solid-svg-icons';


interface PaginationProps {
  totalRows: number;
  rowsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rows: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalRows,
  rowsPerPage,
  currentPage,
  onPageChange,
  onRowsPerPageChange
}) => {
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleFirstPage = () => {
    onPageChange(1);
  };

  const handlePreviousPage = () => {
    onPageChange(Math.max(1, currentPage - 1));
  };

  const handleNextPage = () => {
    onPageChange(Math.min(totalPages, currentPage + 1));
  };

  const handleLastPage = () => {
    onPageChange(totalPages);
  };

  const fromRow = (currentPage - 1) * rowsPerPage + 1;
  const toRow = Math.min(currentPage * rowsPerPage, totalRows);

  return (
    <Box sx={{ display: 'flex', gap: '2vw', justifyContent: 'space-between', width: '100%' }}>
      {/* First and Previous Page Buttons */}
      <Box sx={{ display: 'flex', gap: '1vw' }}>
        <Button
          variant="outlined"
          onClick={handleFirstPage}
          disabled={currentPage === 1}
          sx={{
            minWidth: '3vw',
            maxWidth: '3vw',
            marginLeft: '1vw',
            '&:hover': {
              backgroundColor: '#F9F5FF',
            },
          }}
        >
          <FontAwesomeIcon icon={faAnglesLeft} />
        </Button>

        <Button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          variant="outlined"
          sx={{
            minWidth: '5vw',
            '&:hover': {
              backgroundColor: '#F9F5FF',
            },
          }}
        >
          <FontAwesomeIcon style={{ marginRight: '0.4vw' }} icon={faArrowLeft} />{'Previous'}
        </Button>
      </Box>

      {/* Page Number Buttons */}
      <Box sx={{ display: 'flex', gap: '1vw' }}>
        {pageNumbers
          .filter(
            (number) =>
              number >= Math.max(1, currentPage - 2) &&
              number <= Math.min(totalPages, currentPage + 2)
          )
          .map((number) => (
            <Button
              key={number}
              onClick={() => onPageChange(number)}
              variant={currentPage === number ? 'contained' : 'text'}
              size="small"
              sx={{
                minWidth: '3vw',
                maxWidth: '3vw',
                color: theme.palette.text.secondary,
                boxShadow: 'none',
                backgroundColor: currentPage === number ? '#F9F5FF' : 'transparent',
                '&:focus': {
                  backgroundColor: '#F9F5FF',
                  color: theme.palette.text.primary,
                },
                '&:hover': {
                  backgroundColor: '#F9F5FF',
                },
              }}
            >
              {number}
            </Button>
          ))}
      </Box>

      {/* Display visible row range */}
      <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '1.4vh', color: theme.palette.text.secondary }}>
        <Typography variant="body2">
          {`${fromRow} to ${toRow} of ${totalRows}`}
        </Typography>
      </Box>

      {/* Next and Last Page Buttons */}
      <Box sx={{ display: 'flex', gap: '1vw' }}>
        <Button
          variant="outlined"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          sx={{
            '&:hover': {
              backgroundColor: '#F9F5FF',
            },
          }}
        >
          {'Next'}<FontAwesomeIcon style={{ marginLeft: '0.4vw' }} icon={faArrowRight} />
        </Button>
        <Button
          variant="outlined"
          onClick={handleLastPage}
          disabled={currentPage === totalPages}
          sx={{
            minWidth: '3vw',
            maxWidth: '3vw',
            marginRight: '1vw',
            '&:hover': {
              backgroundColor: '#F9F5FF',
            },
          }}
        >
          <FontAwesomeIcon icon={faAnglesRight} />
        </Button>

        {/* Rows per page selector */}
        <FormControl
          variant="outlined"
          size="small"
          sx={{
            marginRight: '1vw',
            width: '8vw',
            '& .MuiOutlinedInput-root': {
              borderRadius: '1.4vw',
              '& fieldset': {
                borderColor: theme.palette.custom.border,
              },
              '&:hover fieldset': {
                borderColor: theme.palette.custom.border,
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.custom.border,
              },
            },
          }}
        >
          <InputLabel id="rows-per-page-label">row/page</InputLabel>
          <Select
            labelId="rows-per-page-label"
            size="small"
            value={rowsPerPage}
            onChange={(e) => onRowsPerPageChange(Number(e.target.value))}
            label="Rows/Page"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Pagination;
