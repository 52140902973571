import React from 'react';
import { Typography } from '@mui/material';

const UserTableToolbar: React.FC = (props: any) => {
    const { numSelected } = props;
    return (
        <Typography
            sx={{ display: 'flex' }}
            color="inherit"
            variant="subtitle1"
            component="div"
            className="small-font-desc"
        >
            Total Results: {numSelected}
        </Typography>
    );
}

export default UserTableToolbar;