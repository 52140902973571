import CryptoJS from 'crypto-js';

const SECRET_KEY = 'Laserbeam-Compass';

// Function to encrypt data
export const getEncryptValue = (data: string): string => {
    const key = CryptoJS.enc.Utf8.parse(SECRET_KEY.padEnd(24, '\0').slice(0, 24));
    const iv = CryptoJS.enc.Utf8.parse(SECRET_KEY.padEnd(24, '\0').slice(0, 24));
    
    const encrypted = CryptoJS.DES.encrypt(CryptoJS.enc.Utf8.parse(data), key, {
        iv: iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.toString();
};

// Function to decrypt data
export const getDecryptValue = (data: string): string => {
    try {
        // Decode the base64 input
        const decodedData = CryptoJS.enc.Base64.parse(data);

        // Ensure the key is 24 bytes long by padding or trimming
        const key = CryptoJS.enc.Utf8.parse(SECRET_KEY.padEnd(24, '\0').slice(0, 24));

        // Create a cipherParams object with the decoded data
        const cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: decodedData
        });

        // Decrypt the data
        const decrypted = CryptoJS.TripleDES.decrypt(cipherParams, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });

        // Return the decrypted string
        return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error("Decryption error:", error);
        return '';
    }
};