import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

interface JwtPayload {
    exp: number; // Expiration time in seconds
}

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [isOTPVerified, setIsOTPVerified] = useState(false);
    const token = localStorage.getItem('access_token');
    const { tenant } = useParams<{ tenant: string }>(); 
    const candidate_access_token=localStorage.getItem('candidate_access_token')
    const location = useLocation(); // To get the current path
    const [isCandidateTokenExpired, setIsCandidateTokenExpired] = useState(false);
    
    const checkTokenExpiry = () => {
        if (!token) {
            setIsAuthenticated(false);
            return;
        }

        try {
            const decodedToken = jwtDecode<JwtPayload>(token);
            const currentTime = Date.now() / 1000; // Current time in seconds

            // Check if the token has expired
            if (decodedToken.exp < currentTime) {
                localStorage.removeItem('access_token');
                setIsAuthenticated(false);
            } else {
                // Set a timeout for the exact expiration time
                const timeoutDuration = (decodedToken.exp - currentTime) * 1000; // Convert to milliseconds
                if (timeoutDuration > 0) {
                    const logoutTimer = setTimeout(() => {
                        localStorage.removeItem('access_token');
                        setIsAuthenticated(false);
                    }, timeoutDuration);

                    // Cleanup function to clear the timeout if the component unmounts or the token changes
                    return () => clearTimeout(logoutTimer);
                }
            }
        } catch (error) {
            console.error("Token decoding error:", error);
            localStorage.removeItem('access_token');
            setIsAuthenticated(false);
        }
    };

    const checkCandidateAccessToken = () => {
        if (candidate_access_token) {
            try {
                const decodedCandidateToken = jwtDecode<any>(candidate_access_token);
                const otpVerified = decodedCandidateToken["OTPVerified"];
                const currentTime = Date.now() / 1000; // Current time in seconds
                if (otpVerified === true) {
                    setIsOTPVerified(true);
                } else {
                    setIsOTPVerified(false);
                }

                // Check if candidate token has expired
            if (decodedCandidateToken.exp < currentTime) {
                localStorage.removeItem('candidate_access_token');
                setIsCandidateTokenExpired(true);
                setIsOTPVerified(false);
            } else {
                // Set a timeout for the exact expiration time
                const timeoutDuration = (decodedCandidateToken.exp - currentTime) * 1000; // Convert to milliseconds
                if (timeoutDuration > 0) {
                    const logoutTimer = setTimeout(() => {
                        localStorage.removeItem('candidate_access_token');
                        setIsCandidateTokenExpired(true);
                        setIsOTPVerified(false);
                    }, timeoutDuration);

                    // Cleanup function to clear the timeout if the component unmounts or the token changes
                    return () => clearTimeout(logoutTimer);
                }
            }

            } catch (error) {
                console.error("Error decoding candidate access token:", error);
                setIsOTPVerified(false);
            }
        } else {
            setIsOTPVerified(false);
        }
    };
    useEffect(() => {
        checkTokenExpiry(); // Check token validity when component mounts
        checkCandidateAccessToken()
    }, []);

     // If either of the states is still being determined, return a loading state
     if (isAuthenticated === null || isOTPVerified === null) {
        return <div>Loading...</div>;
    }
    
   
    const allowedRoutes = [
        `/${tenant}/CandidateDetails/CandidatePreboarding/your-offer`,
        `/${tenant}/CandidateDetails/CandidatePreboarding/personal-details`,
        `/${tenant}/CandidateDetails/CandidatePreboarding/documents`,
        `/${tenant}/CandidateDetails/CandidatePreboarding/onboarding-experience`,
    ];

     // Check if the current route is allowed
     const isAllowedRoute = allowedRoutes.includes(location.pathname);

     if (isCandidateTokenExpired) {
        return <Navigate to={`/${tenant}/LinkExpired`} />;
    }

    if (!isAuthenticated && isOTPVerified===false) {
        return <Navigate to={`/${tenant}/SignIn`} />;
    }

    // Scenario 2: If there's no access_token and candidate_access_token with OTPVerified is true, allow access to allowedRoutes
    if (!token && isOTPVerified === true && isAllowedRoute) {
        return <>{children}</>; // Render children if OTP is verified and route is allowed
    }

    // Scenario 3: If there's no access_token, candidate_access_token with OTPVerified is true, but the route is not in allowedRoutes, navigate to AccessDenied
    if (!token && isOTPVerified === true && !isAllowedRoute) {
        return <Navigate to={`/${tenant}/AccessDenied`} />;
    }

    // If the user is not authenticated, redirect to SignIn
    if (!isAuthenticated && !isOTPVerified ) {
        return <Navigate to={`/${tenant}/SignIn`} />;
    }
    return <>{children}</>; // Render children if authenticated
};

export default ProtectedRoute;
