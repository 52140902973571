import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const ThankYou: React.FC = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        bgcolor: 'background.default',
        color: 'text.primary',
        textAlign: 'center',
        p: 3,
      }}
    >
      <CheckCircleOutlineIcon 
        sx={{
          fontSize: 60,
          color: 'success.main',
          mb: 2, // Adds space below the icon
        }} 
      />
      <Typography variant="h4" component="h1" gutterBottom>
        Thank You!
      </Typography>
      <Typography variant="body1">
        We appreciate you taking the time to fill in all the details.
      </Typography>
    </Box>
  );
};

export default ThankYou;
