import { css, styled } from '@mui/material/styles';

export const StyledWrapper = styled(
    'div',
    {},
)(({ theme }) => css`
    .candidate-page-wrapper {
        .candidates-header {
            display: flex;
            justify-content: space-between;
        }
    }
`)