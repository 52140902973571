import React, { useState, forwardRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    Box,
    Divider,
    Slide,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { theme } from '../../../../theme'; // Ensure your theme is imported correctly

// Slide transition component
const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

interface AssignTaskProps {
    open: boolean;
    onClose: () => void;
}

const AssignTaskPopup: React.FC<AssignTaskProps> = ({ open, onClose }) => {
    const [instructions, setInstructions] = useState('');
    const [selectedRecruiter, setSelectedRecruiter] = useState('');

    const handleAssign = () => {
        console.log('Assigned with instructions:', instructions, 'to recruiter:', selectedRecruiter);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            fullScreen
            BackdropProps={{ style: { backgroundColor: 'transparent' } }}
            PaperProps={{
                sx: {
                    maxWidth: '20vw',
                    height: '100vh',
                    margin: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    right: 0,
                }, 
            }}
        >
            {/* Header Area */}
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', p: '1.389vw 1.042vw 0 0.694vw' }}>
                <Box
                    color={theme.palette.secondary.main}
                    sx={{
                        border: '0.069vw solid #EEEEEE',
                        bgcolor: theme.palette.secondary.light,
                        margin: '0.417vw',
                        mr: '0.417vw',
                        width: '2.361vw',
                        height: '2.361vw',
                        borderRadius: '0.694vw',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <i className="fa-solid fa-list-check"></i>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">Assign task</Typography>
                    <Typography variant="body2">xaviar</Typography>
                </Box>
                {/* Uncomment if you want the close button */}
                {/* <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton> */}
            </DialogTitle>

            <Divider sx={{ mb: 1.2, mt: 2 }} />

            {/* Content Area */}
            <DialogContent sx={{ overflowY: 'auto', pt: 0, '& .MuiFormControl-root': { mt: 0.8 } }}>
            <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel size='small' id="select-recruiter-label">Select Recruiter</InputLabel>
                    <Select
                    size='small'
                        labelId="select-recruiter-label"
                        value={selectedRecruiter}
                        onChange={(e) => setSelectedRecruiter(e.target.value)}
                        label="Select Recruiter"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="John Doe">John Doe</MenuItem>
                        <MenuItem value="Jane Doe">Jane Doe</MenuItem>
                        <MenuItem value="Alice Smith">Alice Smith</MenuItem>
                    </Select>
                </FormControl>
                <Box sx={{ display: 'flex', alignItems: 'left',flexDirection:'column' }}>
                    <Typography variant="body2" sx={{ flexGrow: 1 }}>
                        Provide instruction to assign <HelpOutlineIcon sx={{ position: 'relative', top: '0.347vw' }} fontSize="small" color="action" />
                    </Typography>
                    <TextField
                    placeholder="A little about the company and the team that you’ll be working with."
                    multiline
                    rows={4}
                    fullWidth
                    margin="normal"
                    value={instructions}
                    onChange={(e) => setInstructions(e.target.value)}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '0.694vw',
                        },
                    }}
                />
                </Box>

                {/* Recruiter Selection Field */}
   

                {/* Instructions Field */}
      
            </DialogContent>

            <Divider />

            {/* Footer Area */}
            <DialogActions sx={{ padding: 2 }}>
                <Button onClick={onClose} variant="outlined" fullWidth>
                    Cancel
                </Button>
                <Button onClick={handleAssign} variant="contained" color="primary" fullWidth>
                    Assign
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssignTaskPopup;
