import React, { useEffect } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Checkbox,
    Stack,
    CircularProgress,
    TableHead,
    Tooltip,
    Chip,
} from '@mui/material';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

const CandidateTable: React.FC = (props: any) => {
    const [selected, setSelected] = React.useState([]);
    const nonHeaderArray = [
        'CandidateNum',
        'CandidateName',
        'SelectedStatus',
        'UpdatedBy',
        'MatchPct',
        'MatchStatus',
        'UpdatedDate',
        'EmailID',
    ];

    const { rows, headCells, matchedStatus } = props;

    const handleSelectAllClick = (event: { target: { checked: any } }) => {
        if (event.target.checked) {
            const newSelected: any = rows.map((n:number|any) => n.CandidateNum);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    useEffect(() => {
        setSelected([]);
    }, [matchedStatus]);

    const handleClick = (id: any) => {
        const selectedIndex: any = selected.indexOf(id);
        let newSelected: any = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleDate = (dateVal: string) => {
        // Convert epoch time to milliseconds and create a new Date object
        const newDate = new Date(parseInt(dateVal) * 1000);

        // Get date components
        const sMonth = newDate.toLocaleString('default', { month: 'short' }); // Abbreviated month name
        const sDay = padValue(newDate.getDate());
        const sYear = newDate.getFullYear();
        let sHour: any = newDate.getHours();
        const sMinute = padValue(newDate.getMinutes());
        let sAMPM = 'AM';

        // Convert 24-hour time to 12-hour time
        if (sHour >= 12) {
            sAMPM = 'PM';
            if (sHour > 12) sHour -= 12;
        } else if (sHour === 0) {
            sHour = 12; // Midnight case
        }

        sHour = padValue(sHour);

        // Return formatted date string
        return `${sDay} ${sMonth} ${sYear} ${sHour}:${sMinute} ${sAMPM}`;
    };

    function padValue(value: number) {
        return value < 10 ? '0' + value : value;
    }
    const toolTip = (event:any) => {
        // let dd = <ul> +
        // ss.map((val)=> {
        //   <li>{val}</li>
        // })
        // </ul>
        const listItems = event.map((person, i) => (
            <li
                key={i}
                style={{
                    listStyle: 'none',
                    textAlign: 'left',
                    margin: '0px',
                    padding: '0px',
                    width: '100%',
                }}
            >
                {person}
            </li>
        ));
        return <ul style={{ textAlign: 'left' }}>{listItems}</ul>;
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    return (
        <Box
            sx={{ width: '100%' }}
            className={
                'candidate-table-wrapper ' +
                (!matchedStatus ? 'un-matched-table' : '')
            }
        >
            <Paper
                className="user-table"
                sx={{ width: '100%', mb: 2, background: 'none' }}
            >
                <TableContainer>
                    <Table
                        stickyHeader
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size="medium"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        indeterminate={
                                            selected.length > 0 &&
                                            selected.length < rows.length
                                        }
                                        checked={
                                            rows.length > 0 &&
                                            selected.length === rows.length
                                        }
                                        onChange={handleSelectAllClick}
                                        inputProps={{
                                            'aria-label': 'select all users',
                                        }}
                                    />
                                </TableCell>
                                {headCells.map((headCell:any) => (
                                    <TableCell key={headCell.id} align={'left'}>
                                        {headCell.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {rows.length >= 1 && (
                            <TableBody>
                                {rows.map((row: any, index: any) => {
                                    const isItemSelected = isSelected(
                                        row.CandidateNum,
                                    );
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.CandidateNum}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    onClick={(event) =>
                                                        handleClick(
                                                            row.CandidateNum,
                                                        )
                                                    }
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby':
                                                            labelId,
                                                    }}
                                                />
                                            </TableCell>

                                            {headCells.map((headCell:any) => (
                                                <TableCell align="left">
                                                    {headCell.id ==
                                                        'CandidateName' && (
                                                            <>
                                                                {/* className="candidate-name" */}
                                                                <div >
                                                                    <span>
                                                                        {
                                                                            row.CandidateName
                                                                        }
                                                                    </span>
                                                                    {row.SelectedStatus &&
                                                                        row.MatchStatus && (
                                                                            <span className="success-tag">
                                                                                S
                                                                            </span>
                                                                        )}
                                                                </div>
                                                                <div className="candidate-email">
                                                                    {row.EmailID}
                                                                </div>
                                                            </>
                                                        )}
                                                    {headCell.id ==
                                                        'UpdatedBy' && (
                                                            <div className="update-by">
                                                                <div>
                                                                    {row.UpdatedBy}
                                                                </div>
                                                                <div>
                                                                    {handleDate(
                                                                        row.UpdatedDate,
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    {headCell.id ==
                                                        'MatchPct' && (
                                                            <div className="match-percentage">
                                                                <Stack
                                                                    spacing={-1}
                                                                    direction="row"
                                                                >
                                                                    <Gauge
                                                                        width={60}
                                                                        height={60}
                                                                        value={row.MatchPct}
                                                                        cornerRadius={50}
                                                                        innerRadius={28}
    
                                                                        sx={{
                                                                            [`& .${gaugeClasses.valueText}`]: {
                                                                                display: 'none'
                                                                            },
                                                                            [`& .${gaugeClasses.valueArc}`]: {
                                                                                fill:row.MatchPct < 50 ? 'red' : '#2B7A77',
                                                                            },
                                                                            [`& .${gaugeClasses.referenceArc}`]: {
                                                                                fill: '#F0F0F0',
                                                                            },
                                                                        }}
                                                                    />
                                                                    <span>
                                                                        {
                                                                            Math.round(row.MatchPct)
                                                                        }
                                                                        %
                                                                    </span>
                                                                </Stack>
                                                                {/* <div>{row.MatchPct}</div> */}
                                                            </div>
                                                        )}
                                                    {!nonHeaderArray.includes(
                                                        headCell.id,
                                                    ) && (
                                                            <div className="table-chip">
                                                                {row[headCell.id]
                                                                    .slice(0, 3)
                                                                    .map((val:number) => (
                                                                        <Chip
                                                                            label={
                                                                                val
                                                                            }
                                                                            variant="outlined"
                                                                        />
                                                                    ))}
                                                                <Tooltip
                                                                    title={toolTip(
                                                                        row[
                                                                        headCell
                                                                            .id
                                                                        ],
                                                                    )}
                                                                >
                                                                    <>
                                                                        <span>
                                                                            {row[
                                                                                headCell
                                                                                    .id
                                                                            ]
                                                                                .length >
                                                                                3
                                                                                ? '+' +
                                                                                (row[
                                                                                    headCell
                                                                                        .id
                                                                                ]
                                                                                    .length -
                                                                                    3) +
                                                                                ' more'
                                                                                : ''}
                                                                        </span>
                                                                    </>
                                                                </Tooltip>
                                                            </div>
                                                        )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        )}
                        {rows.length === 0 && (
                            <TableBody>
                                <TableRow className="">
                                    <TableCell colSpan={8} align="center">
                                        No Rows Found
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};

export default CandidateTable;