import { styled, css } from '@mui/material';

export const StyledDialog = styled('div')(
    ({ theme }) => css`
        width: 701px;
        height: 415px;
        padding: 32px 16px;
        border-radius: 8px;
    `,
);

export const StyledDialogTitle = styled('div')(
    ({ theme }) => css`
        font-size: 18px;
        font-weight: 600;
        text-align: left;
    `,
);

export const StyledRadioGroupContainer = styled('div')(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 16px;
    `,
);

export const StyledDashedBorderArea = styled('div')(
    ({ theme }) => css`
        height: 103px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;
        border: 1px dashed #000;
        margin-bottom: 16px;
        position: relative;
        .upload-icon {
            width: 32px;
            height: 32px;
            padding: 4px 2px;
        }
        .upload-text {
            color: #2b7a77;
            span {
                color: black;
            }
        }
        .upload-caption {
            color: ${theme.palette.text.secondary};
            font-size: 10px;
        }
    `,
);

export const StyledUploadProgressArea = styled('div')(
    ({ theme }) => css`
        height: 73px;
        padding: 8px 0px;
        border-radius: 4px;
        border: 1px solid #000;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .file-name {
            margin-bottom: 4px;
        }
        .progress-bar-container {
            width: 100%;
            height: 10px;
            background-color: #f0f0f0;
            border-radius: 4px;
            position: relative;
        }
        .progress-bar {
            width: 50%;
            height: 100%;
            background-color: green;
            border-radius: 4px;
            position: absolute;
            top: 0;
            left: 0;
            transition: width 0.5s ease-in-out;
        }
        .progress-percentage {
            margin-top: 4px;
            text-align: center;
        }
    `,
);

export const StyledButtonContainer = styled('div')(
    ({ theme }) => css`
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        .cancel-button {
            background-color: #fff;
            color: #000;
        }
        .import-button {
            background-color: green;
            color: #fff;
        }
    `,
);

export const StyledDivider = styled('div')(
    ({ theme }) => css`
        margin: 16px 0;
    `,
);
