import React, { useState, forwardRef, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  Divider,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { theme } from '../../../../../theme'; // Ensure your theme is imported correctly

type Question = {
  QuestionNum: number;
  Question: string;
};

// Slide transition component
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface EditAddQuestionsDialogProps {
  open: boolean;
  onClose: () => void;
  existingQuestions: { QuestionNum: number, Question: string }[];
  onSave: (updatedQuestions: { QuestionNum: number, Question: string }[]) => void;
}

const EditAddQuestionsDialog: React.FC<EditAddQuestionsDialogProps> = ({ open, onClose, existingQuestions, onSave }) => {
  const [questions, setQuestions] = useState<{ QuestionNum: number, Question: string }[]>(existingQuestions);

  useEffect(()=>{
    setQuestions(existingQuestions)
  },[existingQuestions])
  // Handle text field changes

  const handleQuestionChange = (index: number, value: string) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].Question = value;
    setQuestions(updatedQuestions);
  }

  // Add a new question
  const handleAddQuestion = () => {
    const newQuestion = { QuestionNum: 0, Question: '' }; // New question with unique ID
    setQuestions([...questions, newQuestion]);
  };
  

  // Save action handler
  const handleSave = () => {
    onSave(questions); // Pass updated questions back to parent
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullScreen
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      PaperProps={{
        sx: {
          maxWidth: '20vw',
          height: '100vh',
          margin: 0,
          overflow: 'hidden',
          position: 'absolute',
          right: 0,
        },
      }}
    >
      {/* Header Area */}
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', p: '1.389vw 1.042vw 0 0.694vw' }}>
        <Box
          color={theme.palette.secondary.main}
          sx={{
            border: '0.069vw solid #EEEEEE',
            bgcolor: theme.palette.secondary.light,
            margin: '0.417vw',
            width: '2.361vw',
            height: '2.361vw',
            borderRadius: '0.694vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <i className="fa-solid fa-rectangle-xmark"></i>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6">Edit Questions</Typography>
        </Box>
      </DialogTitle>

      <Divider sx={{ mb: 1.2, mt: 2 }} />

      {/* Content Area */}
      <DialogContent
        sx={{
          overflowY: 'auto',
          pt: 0,
          '& .MuiFormControl-root': { mt: '0.4448vw' },
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold', mb: '0.7vw' }}>
          Manage your questions
        </Typography>
        {questions.map((question, index) => (
          <TextField
            key={index}
            fullWidth
            size="small"
            margin="normal"
            value={question.Question}
            onChange={(e) => handleQuestionChange(index, e.target.value)}
            placeholder={`Question ${index + 1}`}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '0.694vw',
              },
            }}
          />
        ))}
        <Button
          onClick={handleAddQuestion}
          size="small"
          variant="outlined"
          color="primary"
          sx={{ mt: '1vw' }}
          fullWidth
        >
          Add Question
        </Button>
      </DialogContent>

      <Divider />

      {/* Footer Area */}
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={onClose} variant="outlined" size="small" fullWidth>
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary" size="small" fullWidth>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAddQuestionsDialog;
