import React, { useState, forwardRef } from 'react';
import {
  TextField,
  Button,
  Box,
  Grid,
  Avatar,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Slide,
  FormHelperText
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { TransitionProps } from '@mui/material/transitions';
import { theme } from '../../../../../theme';
import { validateUsername,validateEmail,validatePassword } from '../../../Validation/validation';
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (newUser: any) => void;
  existingUserIds: string[];
}

const AddNewUser: React.FC<Props> = ({ open, onClose, onSubmit,existingUserIds }) => {
  const [errors,setErrors]=useState<{ [key: string]: string }>({})
  const [formData, setFormData] = useState({
    userid:'',
    username: '',
    email: '',
    password: '',
    role: '',
    status: '',
    photo: null as File | null,
    photoURL: '', // URL for photo preview
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setFormData({
      ...formData,
      status: event.target.value,
    });
  };

  const handleRoleChange = (event: SelectChangeEvent) => {
    setFormData({
      ...formData,
      role: event.target.value,
    });
  };

  const handleAvatarClick = () => {
    const fileInput = document.getElementById('file-upload');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileURL = URL.createObjectURL(file);
      setFormData({
        ...formData,
        photo: file,
        photoURL: fileURL,
      });
    }
  };

  const handleSubmit = () => {
    const newErrors: { [key: string]: string } = {};
    if(!validateUsername(formData.username)){
      newErrors.username =
      'Username can only contain letters and numbers.';
    }
    if(!validateEmail(formData.email)){
      newErrors.email = 'Please enter a valid email address.';
    }

    if(!validatePassword(formData.password)){
      newErrors.password =
                'Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character.';
    }
    if (!formData.role.trim()) {
      newErrors.role = 'Please select a role.';
    }
    if (!formData.status.trim()) {
      newErrors.status = 'Please select a status.';
    }
    if(!formData.userid.trim()){
      newErrors.userid = 'Please enter the userid field.';
    }
    if(existingUserIds.includes(formData.userid)){
      newErrors.userid = 'This User ID already exists. Please choose a different one.';   
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return
  }
    onSubmit(formData);  // Pass formData including the photo back to parent
    setFormData({
      userid:'',
      username: '',
      email: '',
      password: '',
      role: '',
      status: '',
      photo: null,
      photoURL: '',
    });
    setErrors({}); 
    onClose()
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullScreen
      PaperProps={{
        sx: {
          width: '20vw',
          
          height: '100vh',
          margin: 0,
          overflow: 'hidden',
          position: 'absolute',
          right: 0,
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', p: '1.3889vw 1.0417vw  0 10' }}>
        <Box
          sx={{
            border: '0.0694vw solid #EEEEEE',
            bgcolor: theme.palette.secondary.light,
            color: theme.palette.secondary.main,
            margin: '0.4167vw ',
            mr: '1.11vw',
            width: '2.3611vw ',
            height: '2.3611vw ',
            borderRadius: '0.56vw', 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <i className="fa-solid fa-user-plus"></i>
        </Box>
        <Box>
          <Typography variant="h6">Add New User</Typography>
          <Typography variant="body2">Fill in the user information below</Typography>
        </Box>
      </DialogTitle>

      <Divider sx={{ mb: 1.2, mt: 2 }} />

      <DialogContent sx={{ pt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom variant='subtitle2'>Profile picture</Typography>
            <IconButton
              onClick={handleAvatarClick}
              disableRipple
              disableTouchRipple
              sx={{
                padding: 0, // Optional: Remove padding for a tighter fit around the avatar
              }}
            >
              <Avatar
                sx={{
                  width: '3.6111vw',
                  height: '3.6111vw',
                  bgcolor: theme.palette.background.paper,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {!formData.photoURL ? (
                  <span
                    className="fa-regular fa-circle-user fa-3x"
                    style={{
                      color: theme.palette.secondary.main,
                    }}
                  />
                ) : (
                  <img
                    src={formData.photoURL}
                    alt="User Avatar"
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                    }}
                  />
                )}
              </Avatar>
            </IconButton>
            <Divider sx={{ mb: 1.2, mt: 2 }} />
          </Grid>

          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="UserId"
              name="userid"
              value={formData.userid}
              onChange={handleChange}
              variant="outlined"
              required
              error={!!errors.userid}
              helperText={errors.userid}
              autoComplete='off'
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              variant="outlined"
              required
              error={!!errors.username}
              helperText={errors.username}
              autoComplete='off'
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              variant="outlined"
              required
              error={!!errors.email}
              helperText={errors.email}
              autoComplete='off'
            />
          </Grid>
         
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              variant="outlined"
              required
              error={!!errors.password}
              helperText={errors.password}
              autoComplete='off'
            />
          </Grid>
          
          

          <Grid item xs={12}>
            <FormControl fullWidth size="small" required error={!!errors.role}>
              <InputLabel>Role</InputLabel>
              <Select value={formData.role} onChange={handleRoleChange} label="Role">
                <MenuItem value="HR">HR</MenuItem>
                <MenuItem value="Recruiter">Recruiter</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="Superadmin">Super Admin</MenuItem>
              </Select>
              {errors.role && <FormHelperText>{errors.role}</FormHelperText>} 
            </FormControl>
          </Grid>
         
          <Grid item xs={12}>
            <FormControl fullWidth size="small" required error={!!errors.status}>
              <InputLabel>Status</InputLabel>
              <Select value={formData.status} onChange={handleStatusChange} label="Status">
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="In-Active">In-Active</MenuItem>
              </Select>
              {errors.status && <FormHelperText>{errors.status}</FormHelperText>} 
            </FormControl>
          </Grid>
        </Grid>

        {/* Hidden file input for photo upload */}
        <input
          id="file-upload"
          type="file"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleFileChange}
          required
        />
      </DialogContent>

      <Divider />

      <DialogActions sx={{ display: 'flex', gap: 2, width: '100%', p: '0.56vw 1.67vw' }}>
        <Button onClick={onClose} variant="outlined" fullWidth>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" fullWidth>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewUser;
