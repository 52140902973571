import React from 'react';
import { Box, Typography } from '@mui/material';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const CandidateLinkExpired: React.FC = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        bgcolor: 'background.default',
        color: 'text.primary',
        textAlign: 'center',
        p: 3,
      }}
    >
      <HourglassEmptyIcon
        sx={{
          fontSize: 60,
          color: 'warning.main',
          mb: 2, // Adds space below the icon
        }}
      />
      <Typography variant="h4" component="h1" gutterBottom>
        Link Expired
      </Typography>
      <Typography variant="body1">
        The link you are trying to access has expired. Please request a new link to continue.
      </Typography>
    </Box>
  );
};

export default CandidateLinkExpired;
