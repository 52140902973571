import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    IconButton,
    Divider,
    Button,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import {
    StyledDialog,
    StyledDialogTitle,
    StyledRadioGroupContainer,
    StyledDashedBorderArea,
    StyledUploadProgressArea,
    StyledButtonContainer,
    StyledDivider,
} from './ImportJobs.styles';

interface ImportJobsProps {
    open: boolean;
    onClose: () => void;
}

const ImportJobs: React.FC<ImportJobsProps> = ({ open, onClose }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ component: StyledDialog }}
        >
            <DialogTitle>
                <StyledDialogTitle>Import Jobs</StyledDialogTitle>
            </DialogTitle>
            <DialogContent>
                <StyledRadioGroupContainer>
                    <RadioGroup row defaultValue="upload">
                        <FormControlLabel
                            value="upload"
                            control={<Radio />}
                            label="Upload File"
                        />
                        <FormControlLabel
                            value="integration"
                            control={<Radio />}
                            label="Integration"
                        />
                    </RadioGroup>
                </StyledRadioGroupContainer>

                <StyledDashedBorderArea>
                    <IconButton className="upload-icon">
                        <FontAwesomeIcon icon={faUpload} />
                    </IconButton>
                    <Typography className="upload-text">
                        Click to upload <span>or Drag and drop file</span>
                    </Typography>
                    <Typography className="upload-caption">
                        (Max file size: 5MB)
                    </Typography>
                </StyledDashedBorderArea>

                <StyledUploadProgressArea>
                    <Typography className="file-name">
                        File: example.csv
                    </Typography>
                    <Box className="progress-bar-container">
                        <Box className="progress-bar" />
                    </Box>
                    <Typography className="progress-percentage">
                        50% Completed
                    </Typography>
                </StyledUploadProgressArea>

                <StyledDivider />

                <StyledButtonContainer>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        className="cancel-button"
                    >
                        Cancel
                    </Button>
                    <Button variant="contained" className="import-button">
                        Import
                    </Button>
                </StyledButtonContainer>
            </DialogContent>
        </Dialog>
    );
};

export default ImportJobs;
