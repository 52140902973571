import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import JobsDetails from './JobsDetails'
import { useJobStore } from '../useJobStore';
import JobsDetailsPopup from './JobsDetailsPopup';
import Candidates from '../Candidates/Candidates'
import axios from 'axios';
import APIBaseUrl from '../../../../AppConfig/AppConfig.json'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any; }) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


const JobsRightPane: React.FC = (props: any) => {
    const [value, setValue] = React.useState(0);
    const { selectedJob,updateJobStatus} = useJobStore();
    const [status, setStatus] =useState(selectedJob?.Status || '');
    const { tenant } = useParams<{ tenant: string }>(); 
    const baseURL = window.location.origin.endsWith('/') 
  ? window.location.origin 
  : `${window.location.origin}/`;
   
    const navigate=useNavigate()

    const handleEditClick = () => {
        if (selectedJob) {
            navigate(`/${tenant}/jobs/edit`); // Navigate without JobNum in URL
        }
    };
    
    const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
        setValue(newValue);
    };

    const handleUploadSuccess = () => {
        setValue(1); // Change to the Candidates tab
    };

    useEffect(() => {
        if (selectedJob) {
            setStatus(selectedJob.Status);
        }
    }, [selectedJob]);
   
    const handleStatusChange =async (event:any) => {
        const newStatus = event.target.value;
        setStatus(newStatus);

        if (selectedJob) { // Ensure selectedJob is not null
            try {
                await axios.post(`${APIBaseUrl.apiBaseUrl}/job/status_update`, {
                    "JobStatus": newStatus,
                    "JobNum": selectedJob.JobNum,
                },
                {
                    headers: {
                        'X-Tenant': tenant,
                        'X-App': baseURL
                    }
                });
                // Update the Zustand store with the new status
                updateJobStatus(selectedJob.JobNum, newStatus);
            } catch (error) {
                console.error(error);
            }
        }
    };
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };
    

   
    return (
        <Box sx={{ width: '100%' }}>
            {selectedJob?
            <Grid className="right-pane">
                <Box className="job-nav-container">
                    <Box className="head-content">
                        <Box className="title">
                            <Typography variant="h5" className='job-title'>{selectedJob?.JobTitle}</Typography>
                            <Typography className='job-desc-title'>{selectedJob?.Department}</Typography>
                        </Box>
                        <Box className="right-action-container">
                            <Button
                                variant="outlined" size="small"
                                className="save-run-btn"
                                onClick={handleOpenPopup}
                                
                            >
                                Run
                            </Button>
                            {isPopupOpen && (
                                <JobsDetailsPopup
                                    onClose={handleClosePopup}
                                    isOpen={isPopupOpen}
                                    data={selectedJob}
                                    onUploadSuccess={handleUploadSuccess}
                                />
                            )}
                            
                            <Button variant="outlined" size="small" className='status-btn'>
                                <Select
                                    value={status}
                                    onChange={handleStatusChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    renderValue={(value: any) => {
                                        if (value) {
                                            return value;
                                        }
                                        return (
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    mb: 0,
                                                    fontFamily: 'Inter',
                                                }}
                                            >
                                                {selectedJob.Status}
                                            </Typography>
                                        );
                                    }}
                                >
                                    <MenuItem value="Open" >Open</MenuItem>
                                    <MenuItem value="Close">Close</MenuItem> 
                                </Select>
                            </Button>
                            <Button variant="outlined" size="small"
                            onClick={handleEditClick}>
                                <EditIcon />
                                <Typography>Edit</Typography>
                            </Button>
                        </Box>
                    </Box>

                    <Box sx={{ width: '100%', typography: 'body1' }} className="body-content">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Job Details"  {...a11yProps(0)} />
                                <Tab label="Candidates" {...a11yProps(1)} />
                                {/* <Tab label="Job Profiling" {...a11yProps(2)} />
                                <Tab label="Notes" {...a11yProps(3)} />
                                <Tab label="History" {...a11yProps(4)} /> */}
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0} className="tab-panel-wrapper">
                            <JobsDetails />
                        </TabPanel>
                        <TabPanel value={value} index={1} className="tab-panel-wrapper">
                            <Candidates JobNum={selectedJob.JobNum}/>
                        </TabPanel>
                        <TabPanel value={value} index={2} className="tab-panel-wrapper">
                            Jobs Profiling Under Construction
                        </TabPanel>
                        <TabPanel value={value} index={3} className="tab-panel-wrapper">
                            Notes Under Construction
                        </TabPanel>
                        <TabPanel value={value} index={4} className="tab-panel-wrapper">
                            History Under Construction
                        </TabPanel>
                    </Box>
                </Box>
            </Grid>:<></>}
        </Box>
    );
}

export default JobsRightPane;