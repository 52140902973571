import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Grid, Table, TableBody, TableCell, TableHead, TableRow, Button,
  Paper, IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
  Container, Header, ActionButtons, TableWrapper, TableRowStyled, TableDescription, OpenStatus,
  CloseStatus,
  OnHoldStatus
} from './JobListing.styles';
import FilterSortDialog from './FilterSortDialog/FilterSortDialog';
import Pagination from '../../../Common/Pagination/pagination';
import StatusCard from '../../../Common/Card/Card';
import { theme } from '../../../../theme';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import APIBaseURL from '../../../../AppConfig/AppConfig.json'
import axios from 'axios';
import { WidthFull } from '@mui/icons-material';

interface JobData {
  JobNum: number;
  JobTitle: string;
  Department: string;
  Status: string;
  CreatedDate: string;
  CreatedBy: string;
  UpdatedDate: string;
  UpdatedBy: string;
  JobCode: string;
  year_of_experience?: number;
  ctc?: number;
  skills?: string[];
  recruiter?: string;
  [key: string]: any;
  DeletedItem:boolean;
}



const JobListing: React.FC = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // For delete confirmation dialog
  const [jobData, setJobData] = useState<JobData[]>([]);
  const [filteredData, setFilteredData] = useState<JobData[]>([]);
  const { tenant } = useParams<{ tenant: string }>();
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const columnsData = ['Job title', 'Job sector', 'Job status', 'Created date', 'Created by', 'Updated date', 'Updated by', 'Action']
  const navigate = useNavigate()
  const [jobNumToDelete, setJobNumToDelete] = useState<number | null>(null);
  const token = localStorage.getItem('access_token')
  useEffect(() => {
    if (!token) {
      navigate(`/${tenant}/SignIn`)
      return
    }
    else {
      const fetchJobData = async () => {
        try {
          const response = await axios.get(`${APIBaseURL.apiBaseUrl}/job/jobs`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Tenant': tenant,
                'X-App': baseURL
              }
            }
          )
          const data: JobData[] = Array.isArray(response.data) ? response.data : []; // Ensure it's an array
          setJobData(data.filter((job)=>!job.DeletedItem));
          setFilteredData(data.filter((job)=>!job.DeletedItem));
        } catch (error) {
          console.error('Error fetching job data:', error);
          setFilteredData([]);
        }
      };
      fetchJobData();
    }

  }, []);


  const formatEpochToDate = (epochTime: any) => {
    const date = new Date(epochTime * 1000); // Convert seconds to milliseconds
    return date.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDeleteJob = (jobNum: number) => {
    setJobNumToDelete(jobNum); // Set job ID to delete
    setDeleteDialogOpen(true); // Open delete confirmation dialog
  };

  // Confirm deletion
  const confirmDeleteJob = async () => {
    if (jobNumToDelete !== null) {
      if (!token) {
        navigate(`/${tenant}/SignIn`)
        return
      }
      try {
        const response = await axios.post(`${APIBaseURL.apiBaseUrl}/job/delete_job`, {
          "JobNum": jobNumToDelete
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Tenant': tenant,
            'X-App': baseURL
          }
        })
        if (response.status === 200) {
          setFilteredData((prevData) => prevData.filter((job) => job.JobNum !== jobNumToDelete));
          setJobData((prevData) => prevData.filter((job) => job.JobNum !== jobNumToDelete));
        }
      } catch (error) {
        console.log(error)
      }
    }
    setDeleteDialogOpen(false); // Close dialog after deletion
    setJobNumToDelete(null); // Reset job ID to delete
  };

  const cancelDeleteJob = () => {
    setDeleteDialogOpen(false); // Close delete dialog without deleting
    setJobNumToDelete(null); // Reset job ID to delete
  }

  const handleApplyFilters = (newFilters: any) => {
    // setFilters(newFilters);
    applyFilters(newFilters);
    handleCloseDialog();
  };

  const applyFilters = (newFilters: any) => {
    let tempData = [...jobData];

    // Filtering by experience
    if (newFilters.experience) {
      const [minExperience, maxExperience] = newFilters.experience.split('-').map(Number);
      tempData = tempData.filter(job =>
        job.year_of_experience !== undefined &&
        job.year_of_experience >= minExperience && job.year_of_experience <= maxExperience
      );
    }

    // Filtering by CTC
    if (newFilters.ctc) {
      const [minCTC, maxCTC] = newFilters.ctc.split('-').map(Number);
      tempData = tempData.filter(job =>
        job.ctc !== undefined &&
        job.ctc >= minCTC && job.ctc <= maxCTC
      );
    }

    // Filtering by skills
    if (newFilters.skills && newFilters.skills.length > 0) {
      tempData = tempData.filter(job =>
        job.skills?.some(skill => newFilters.skills.includes(skill))
      );
    }

    // Filtering by recruiters
    if (newFilters.recruiter) {
      const recruitersArray = newFilters.recruiter.split(',').map((r: string) => r.trim().toLowerCase());
      tempData = tempData.filter(job => {
        const createdBy = job.CreatedBy.toLowerCase();
        // const updatedBy = job.UpdatedBy.toLowerCase();

        return recruitersArray.some((recruiter: string) =>
          createdBy.includes(recruiter)
          // || updatedBy.includes(recruiter)
        );
      });
    }

    if (newFilters.sortBy) {
      if (newFilters.sortBy === 'CreatedDate') {
        tempData.sort((a, b) => {
          const dateA = new Date(a.CreatedDate).getTime();
          const dateB = new Date(b.CreatedDate).getTime();
          return dateA - dateB; // Ascending order
        });
      }
    }

    setFilteredData(tempData);
    setPage(1);
  };

  const handleSort = (column: string) => {
    // Sorting logic here...
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };


  const handleRowsPerPageChange = (rows: number) => {
    setRowsPerPage(rows);
    setPage(1);
  };

  const handleEditJob = (jobNum: number) => {
    navigate(`/${tenant}/Joblisting/create-job`, { state: { jobNum } })
  }
  return (
    <div style={{width:'100%'}}>
    {filteredData.length === 0 ? 
    <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="90vh"  // Ensures the container takes full viewport height
    textAlign="center"  // Centers the text
    overflow="hidden"  // Prevents scrolling
    sx={{ width: '100%' }}  // Ensures full width
  >
    <i className="fa-solid fa-file-circle-xmark fa-5x" style={{ padding:'0.5vw',marginLeft:'0.5vw',color: theme.palette.text.disabled, marginBottom: 8 }}></i>
  
    <Typography variant="subtitle1" sx={{ mb: 2 }}>
      You haven't created any jobs. Start creating a job and find the best candidates.
    </Typography>
    
    <Link
      to={`/${tenant}/Joblisting/create-job`}
      style={{ textDecoration: 'none' }} // Ensures the link doesn't have an underline
    >
      <Button variant="contained" color="primary">
        Create
      </Button>
    </Link>
  </Box>
  
    :
    <Container sx={{ margin: '0 0vw 0 1vw', p: 0, mb: 2 }}>
      <Header>
        <Box>
          <Typography variant="h5">
            Job listing
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            See numbers of position open, closed, and On-hold here
          </Typography>
        </Box>
        <Link
          style={{ color: 'primary', height: 'inherit', textDecoration: 'none' }}
          to={`/${tenant}/Joblisting/create-job`}
        >
          <Button
            variant="contained"
            sx={{ whiteSpace: 'nowrap', display: 'flex', gap: '0.8vw' }}
            color="primary"
          >
            <i className="fa-solid fa-plus " /> Create new job
          </Button>
        </Link>
      </Header>

      <Grid container spacing={2} sx={{ mb: '6vh' }}>
        <Grid item xs={12} sm={4}>
          <StatusCard
            title="Total Open Position"
            count={filteredData.filter(job => job.Status !== 'Close' && job.Status !== 'On-hold').length}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatusCard
            title="Total Closed Position"
            count={filteredData.filter(job => job.Status === 'Close').length}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatusCard
            title="Position On-hold"
            count={filteredData.filter(job => job.Status === 'On-hold').length}
          />
        </Grid>

      </Grid>

      <ActionButtons>
        {/* <Button variant="outlined">Duplicate post</Button> */}
        <Button
          variant="outlined"
          sx={{ color: theme.palette.primary.main, borderColor: theme.palette.primary.main }}
          onClick={handleOpenDialog}
        >
          Filter & Sort
        </Button>
      </ActionButtons>

      <FilterSortDialog open={dialogOpen} onClose={handleCloseDialog} onApply={handleApplyFilters} />

      <TableWrapper>

        <Box sx={{ overflowX: 'auto' }}>
          <Table >
            <TableHead>
              <TableRow>
                {columnsData.map((column) => (
                  <TableCell key={column} sx={{
                    color: theme.palette.text.secondary,
                    
                    padding: '0.8vh 2.4vw',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: ' 13.89vw',
                    minWidth: ' 6.94vw',
                  }}>
                    <Typography variant='body3'>
                    {column.charAt(0).toUpperCase() + column.slice(1).replace(/_/g, ' ')}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody >
              {filteredData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columnsData.length} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    No jobs data available
                  </TableCell>
                </TableRow>
              ) : (
                filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((data, index) => (
                  <TableRowStyled key={data.JobNum} iseven={(index % 2 === 0).toString()}>
                    {/* <TableDescription >{data.JobNum}</TableDescription> */}
                    <TableDescription>{data.JobTitle}</TableDescription>
                    <TableDescription>{data.Department}</TableDescription>
                    <TableDescription>
                      {data.Status === 'Open' ? (
                        <OpenStatus>{data.Status}</OpenStatus>
                      ) : data.Status === 'Close' ? (
                        <CloseStatus>{data.Status}</CloseStatus>
                      ) :(
                        <OnHoldStatus>{data.Status}</OnHoldStatus>
                      ) 
                      }
                    </TableDescription>
                    <TableDescription>{formatEpochToDate(data.CreatedDate)}</TableDescription>
                    <TableDescription>{data.CreatedBy}</TableDescription>
                    <TableDescription>{data.UpdatedDate ? formatEpochToDate(data.UpdatedDate) : ' '}</TableDescription>
                    <TableDescription>{data.UpdatedBy || ' '}</TableDescription>
                    <TableDescription>
                      <Box sx={{ display: 'flex', gap: '8px', color: theme.palette.primary.main }}>
                        <IconButton onClick={() => { handleEditJob(data.JobNum) }} sx={{ color: theme.palette.primary.main }}>
                          <i className="fa-solid fa-pen fa-xs"></i>
                        </IconButton>
                        <IconButton onClick={() => { handleDeleteJob(data.JobNum) }} sx={{ color: theme.palette.primary.main }}>
                          <i className="fa-solid fa-trash fa-xs"></i>
                        </IconButton>
                      </Box>
                    </TableDescription>
                  </TableRowStyled>
                ))
              )}
            </TableBody>
          </Table>
        </Box>

        <Box sx={{ mt: '2.5vh', display: 'flex', mb: 2, mx: 2, justifyContent: 'center' }}>
          <Pagination
            totalRows={filteredData.length}
            rowsPerPage={rowsPerPage}
            currentPage={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Box>
      </TableWrapper>

      <Dialog
        open={deleteDialogOpen}
        onClose={cancelDeleteJob}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this job posting? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDeleteJob} sx={{color:theme.palette.text.secondary}}>
            Cancel
          </Button>
          <Button onClick={confirmDeleteJob} sx={{ bgcolor: theme.palette.error.main }} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  
  }
  </div>
  );
};

export default JobListing;