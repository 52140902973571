import React, { useState, useEffect } from 'react';
import {
  TableCell, TableRow, IconButton, TextField
} from '@mui/material';
import {Edit, Delete } from '@mui/icons-material';
import { IFieldData } from '../CreateJob/CreateJob';



interface ICreateNewFieldProps {
  fieldKey: number;
  onFieldDataUpdate: (a: IFieldData) => void;
  onDeleteField: (a: number) => void;
  fieldData:IFieldData
}

const CustomFields: React.FC<ICreateNewFieldProps> = (props: ICreateNewFieldProps) => {
  
  const { fieldKey, onFieldDataUpdate, onDeleteField } = props;
  const [fieldName, setFieldName] = useState(props.fieldData.fieldName || '');
  const [values, setValues] = useState<string[]>(props.fieldData.values||[]);

 

  useEffect(() => {
    onFieldDataUpdate({
      fieldKey,
      fieldName: fieldName,
      values: values,
    });
  }, [fieldName, values, fieldKey, onFieldDataUpdate]);

  const handleFieldNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldName(e.target.value);
  };

  const handleOnDeleteField = () => {
    onDeleteField(fieldKey);
  };

 
  return (
    <TableRow
    sx={{
      backgroundColor: fieldKey % 2 === 1 ? '#F9F5FF' : 'transparent'
    }}
    >
      <TableCell sx={{ padding: '1.111vw' }}>
          <TextField
            size="small"
            variant="outlined"
            value={fieldName}
            onChange={handleFieldNameChange}
            placeholder="Enter field name"
            fullWidth
            autoComplete='off'
          />
        </TableCell>
        <TableCell sx={{ padding: '1.111vw' }}>
        <TextField
              size="small" 
              variant="outlined"
              placeholder="Enter values separated by comma"
              value={values}
              onChange={(e) => {
                setValues(e.target.value.split(','))
              }}
              fullWidth
              autoComplete='off'/>
        </TableCell>
        <TableCell sx={{
              padding: '1.11vw',
              display: 'flex',
              ml:'2vw',
              justifyContent: 'center',
              gap: '0.069vw', // Convert to vw
            }}>
        <IconButton sx={{ color: "#7F56D9" }} >
              <Edit />
            </IconButton>
            <IconButton sx={{ color: "#7F56D9" }} onClick={handleOnDeleteField}>
              <Delete></Delete>
            </IconButton>
        </TableCell>
    </TableRow>
  );
};

export default CustomFields;
