import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import MarketData from './MarketData/MarketData';
import SimilarJobPostings from './SimilarJobPostings/SimilarJobPosting';
import CreateJob from './CreateJob/CreateJob';
import { useParams,useNavigate } from 'react-router-dom';
import APIBaseURL from '../../../../AppConfig/AppConfig.json'
import axios from 'axios';

interface marketSalaryDataType{
    MarketDataNum:number|null,
    JobNum:number|null,
    JobTitle:string|null,
    MaximumSalary:number|null,
    MedianSalary:number|null,
    MinimumSalary:number|null
}

interface similarJobsDataType{
    CompanyName:string|null,
    JobTitle:string|null,
    Region:string|null,
    SimilarJobNum:number|null,
    JobLink:string
}
const CreateJobPosting: React.FC = () => {
    const [jobNum,setJobNum]=useState<number>()
    const[marketSalaryData,setMarketSalaryData]=useState<marketSalaryDataType|null>(null)
    const[similarJobsData,setSimilarJobsData]=useState<similarJobsDataType[]>([])
    const[message,setMessage]=useState(false)
    const token=localStorage.getItem('access_token')
    const navigate = useNavigate()
    const { tenant } = useParams<{ tenant: string }>();
    const baseURL = window.location.origin.endsWith('/')
        ? window.location.origin
        : `${window.location.origin}/`;
    const [loading,setLoading]=useState(false)

    useEffect(()=>{
        if (!token) {
            navigate(`/${tenant}/SignIn`);
            return;
        }
        if(jobNum){
            
            const fetchMarketJobData=async()=>{
                try{
                    const response=await axios.get(`${APIBaseURL.apiBaseUrl}/job/get_market_jobdata/${jobNum}`,{
                        headers:{
                            Authorization: `Bearer ${token}`,
                            'X-Tenant': tenant,
                            'X-App': baseURL
                        }
                    })
                    if(response.status===200){
                        setMarketSalaryData(response.data.market_salary_data|| null)
                        setSimilarJobsData(response.data.similar_jobs_data|| [])
                    }
                }catch(error){
                    console.error('Error fetching market job data:', error)
                }
            }
            fetchMarketJobData()
        }
    },[jobNum])

    const handleUptoDateMarketData=async()=>{
        if (!token) {
            navigate(`/${tenant}/SignIn`);
            return;
        }
        if( jobNum ){
            setLoading(true)
            try{
                const response=await axios.post(`${APIBaseURL.apiBaseUrl}/job/get_uptodate_market_jobdata`,{
                    "JobNum":jobNum
                },{
                    headers:{
                        Authorization: `Bearer ${token}`,
                        'X-Tenant': tenant,
                        'X-App': baseURL
                    }
                })
                if(response.status===200){
                    setMarketSalaryData(response.data.market_salary_data|| null)
                    setSimilarJobsData(response.data.similar_jobs_data|| [])
                    setMessage(true)
                    setLoading(false)
                }
            }catch(error){
                setLoading(false)
                console.error('Error fetching upto date market job data:', error)
            }
        }
        
    }
    return (
        <Box 
            sx={{  
                ml:'1vw',
                mr:'0.2vw',
                width:'100%',              
                boxSizing: 'border-box', // Ensure padding does not affect width
                display: 'flex',
                pr:0,
                flexDirection: 'column',
                gap: 4,
            }}
        >
            <Box > 
                <CreateJob setJobNum={setJobNum}/>
            </Box>
            <Box > 
                {marketSalaryData?(<MarketData marketSalaryData={marketSalaryData} handleUptoDateMarketData={handleUptoDateMarketData} message={message} loading={loading}/>):(<></>)}
            </Box>
            <Box>
                {similarJobsData.length>0?(<SimilarJobPostings similarJobsData={similarJobsData} />):(<></>)}
            </Box>
        </Box>
    );
};

export default CreateJobPosting;
