import React, { useState } from "react";
import {
    Box,
    Typography,
    Avatar,
    Grid,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox,
} from "@mui/material";
import { green, red, orange, purple } from "@mui/material/colors";
import Pagination from "../../Common/Pagination/pagination";
import {
    Container,
    TableRowStyled,
    ScreeningStatus,
    AssignmentStatus,
    StyledTableCell,
    TableWrapper,
    StyledTypography,
} from './ImportNewJob.styles';
import { theme } from "../../../theme";
import NotesPopup from "../CandidateManagement/AddNotes/NotesPopup";
import AssignRecruiters from "./AssignRecruiters/AssignRecruiters";

// Dummy Data
const HireRequest = [
    {
        jobId: "J001",
        notes: "Initial interview scheduled",
        jobDescription: "Software Engineer",
        location: "New York",
        function: "Engineering",
        createdBy: "John Doe",
        receivedOn: "2024-11-01",
        status: "Screening",
        approvedBy: "HR Team",
    },
    {
        jobId: "J002",
        notes: "Awaiting approval",
        jobDescription: "UI Designer",
        location: "San Francisco",
        function: "Design",
        createdBy: "Jane Smith",
        receivedOn: "2024-11-10",
        status: "Assignment",
        approvedBy: "Manager Team",
    },
];

const ImportNewJob: React.FC = () => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filteredData, setFilteredData] = useState(HireRequest);
    const [selected, setSelected] = useState<string[]>([]);  // Updated type to string[]
    const [isNotesDialogOpen, setNotesDialogOpen] = useState(false);
    const [selectedCandidateId, setSelectedCandidateId] = useState<string | null>(null);
    const [isAssignRecruiterDialogOpen, setAssignRecruiterDialogOpen] = useState(false);

    const handlePageChange = (newPage: number) => setPage(newPage);
    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setRowsPerPage(newRowsPerPage);
        setPage(1);
    };

    const handleNotesDialogOpen = (candidateId: string) => {
        setSelectedCandidateId(candidateId);
        setNotesDialogOpen(true);
    };

    const handleAssignRecruiterDialogOpen = () => setAssignRecruiterDialogOpen(true);
    const handleAssignRecruiterDialogClose = () => setAssignRecruiterDialogOpen(false);

    const handleCheckboxChange = (id: string) => {  // Updated type to string
        if (selected.includes(id)) {
            setSelected(selected.filter((item) => item !== id)); // Uncheck
        } else {
            setSelected([...selected, id]); // Check
        }
    };

    const isChecked = (id: string) => selected.includes(id);  // Updated type to string

    return (
        <Container sx={{ '& .MuiBox-root': { padding: 0 } }}>
            <Box p={3}>
                <Box mb='3vw'>
                    <Typography gutterBottom variant="h5">New hire request</Typography>
                    <Typography variant="subtitle1" mb={2} sx={{ display: 'inline' }}>
                        Import New hire requests
                    </Typography>
                    <Button variant="outlined" size="small" sx={{ ml: '2vw' }}>Import requests</Button>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="h5" sx={{ fontSize: '2.4vh', fontWeight: 'bold' }}>All new hire requests</Typography>
                    <Button variant="contained" onClick={handleAssignRecruiterDialogOpen}>Assign</Button>
                </Box>

                {/* Candidate List */}
                <TableWrapper elevation={0} sx={{ tableLayout: 'auto', mb: 2 }}>
                    <Box sx={{ overflowX: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={
                                                selected.length > 0 && selected.length < HireRequest.length
                                            }
                                            checked={selected.length === HireRequest.length}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSelected(HireRequest.map((hire) => hire.jobId)); // Select all
                                                } else {
                                                    setSelected([]); // Deselect all
                                                }
                                            }}
                                        />
                                    </StyledTableCell>

                                    <StyledTableCell sx={{ fontWeight: 400 }}>
                                        <Typography variant="body3">Job ID</Typography>
                                    </StyledTableCell>

                                    <StyledTableCell sx={{ color: theme.palette.text.secondary , whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">Notes</Typography>
                                    </StyledTableCell>

                                    <StyledTableCell sx={{ color: theme.palette.text.secondary , whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">Job Description</Typography>
                                    </StyledTableCell>

                                    <StyledTableCell sx={{ color: theme.palette.text.secondary , whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">Location</Typography>
                                    </StyledTableCell>

                                    <StyledTableCell sx={{ color: theme.palette.text.secondary , whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">Function</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ color: theme.palette.text.secondary , whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">Created by</Typography>
                                    </StyledTableCell>

                                    <StyledTableCell sx={{ color: theme.palette.text.secondary , whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">Received on</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ color: theme.palette.text.secondary , whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">status</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ color: theme.palette.text.secondary , whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">Approved by</Typography>
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((hire, index) => (
                                    <TableRowStyled key={index} isEven={index % 2 === 0}>
                                        <StyledTableCell padding="checkbox">
                                            <Checkbox
                                                checked={isChecked(hire.jobId)}
                                                onChange={() => handleCheckboxChange(hire.jobId)}  // Corrected to handle string
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Typography variant="body2">{hire.jobId}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Button
                                                variant="text"
                                                color="primary"
                                                onClick={() => handleNotesDialogOpen(hire.jobId)}
                                                sx={{ gap: 1, position: 'relative', left: -5 }}
                                            >
                                                <i className="fa-solid fa-clipboard"></i>
                                                <Typography variant="body2" color="primary">View notes</Typography>
                                            </Button>
                                        </StyledTableCell>
                                        <StyledTableCell><Typography variant="body2">{hire.jobDescription}</Typography></StyledTableCell>
                                        <StyledTableCell><Typography variant="body2">{hire.location}</Typography></StyledTableCell>
                                        <StyledTableCell><Typography variant="body2">{hire.function}</Typography></StyledTableCell>
                                        <StyledTableCell><Typography variant="body2">{hire.createdBy}</Typography></StyledTableCell>
                                        <StyledTableCell><Typography variant="body2">{hire.receivedOn}</Typography></StyledTableCell>
                                        <StyledTableCell><Typography variant="body2">
                                            {hire.status === 'Screening' ? (
                                                <ScreeningStatus>• Screening</ScreeningStatus>
                                            ) : hire.status === 'Assignment' ? (
                                                <AssignmentStatus>• Assignment</AssignmentStatus>
                                            ) : (
                                                <Typography variant="body2">{hire.status}</Typography>
                                            )}
                                        </Typography></StyledTableCell>
                                        <StyledTableCell><Typography variant="body2">{hire.approvedBy}</Typography></StyledTableCell>
                                    </TableRowStyled>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
               
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', position: 'relative', top: '-0.694vw' }}>
                        <Pagination
                            totalRows={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            currentPage={page}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                        />
                    </Box>
                </TableWrapper>
            </Box>


            <AssignRecruiters
  open={isAssignRecruiterDialogOpen}
  onClose={() => setAssignRecruiterDialogOpen(false)}
/>
            {/* Notes Dialog */}
            {/* <NotesPopup
                open={isNotesDialogOpen}
                onClose={() => setNotesDialogOpen(false)}
                candidateId={selectedCandidateId}
                userStatus={ "Screening"}
            /> */}


        </Container>
    );
};

export default ImportNewJob;
