import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import EmailConfiguration from '../Templates/EmailConfiguration';
import axios from 'axios';
import APIBaseURL from '../../../../../AppConfig/AppConfig.json';
import { useNavigate, useParams } from 'react-router-dom';
import UserList from '../Users/UserListTable'; // Import UserList

const ManageEmailTemplates: React.FC = () => {
    const [isMessageConfigured, setIsMessageConfigured] = useState(false);
    const [emailList, setEmailList] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [emailTemplates, setEmailTemplates] = useState<any[]>([]);
    const { tenant } = useParams<{ tenant: string }>();

    const baseURL = window.location.origin.endsWith('/')
        ? window.location.origin
        : `${window.location.origin}/`;
    const navigate = useNavigate()

    const token = localStorage.getItem('access_token');
    const fetchData = async () => {

        if (!token) {
            navigate(`/${tenant}/SignIn`)
        }
        else {
            try {
                const response = await axios.get(`${APIBaseURL.apiBaseUrl}/communication/get-email-templates`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'X-Tenant': tenant,
                        'X-App': baseURL,
                    },
                });

                if (response.status === 200) {
                    setEmailTemplates(response.data);
                }
            } catch (error) {
                console.error('Error fetching email templates:', error);
            }
        }

    };

    const handleSave = async (name: string, subject: string, body: string, emailNum: number | null) => {
        if (!token) {
            navigate(`/${tenant}/SignIn`)
        }

        else {
            try {
                const response = await axios.post(
                    `${APIBaseURL.apiBaseUrl}/communication/add-email-templates`,
                    {
                        "EmailSubject": subject,
                        "EmailBody": body,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'X-Tenant': tenant,
                            'X-App': baseURL,
                        },
                    }
                );

                if (response.status === 201) {

                    if (emailNum) {
                        setEmailTemplates(prevTemplates =>
                            prevTemplates.map(template =>
                                template.EmailNum === emailNum
                                    ? { ...template, EmailSubject: subject, EmailBody: body }  // Update the existing template
                                    : template
                            )
                        );
                    } else {
                        // If no emailNum, it's a new template, so we add it to the state
                        setEmailTemplates(prevTemplates => [
                            ...prevTemplates,
                            {
                                EmailNum: response.data.EmailNum, // Adjust according to your API response
                                EmailSubject: subject,
                                EmailBody: body,
                            },
                        ]);
                    }
                } else {
                    console.error('Failed to save email configuration:', response);
                }
            } catch (error) {
                console.error('Error saving email configuration:', error);
            }
        }


    };

    const handleDelete = async (id: number | null) => {
        if (!token) {
            navigate(`/${tenant}/SignIn`)
        }

        else {
            if (typeof id === "number") {
                try {
                    const resposne = await axios.post(`${APIBaseURL.apiBaseUrl}/communication/delete-email-templates`, {
                        "EmailNum": id
                    },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'X-Tenant': tenant,
                                'X-App': baseURL,
                            }
                        })
                    if (resposne.status === 200) {
                        setEmailTemplates(prevTemplates => prevTemplates.filter(template => template.EmailNum !== id));
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        }


    }
    // Fetch data on component mount
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Box sx={{ margin: '2.0833vw 0', display: 'flex', width: '100%', flexDirection: 'column' }}>
            {/* Email Configuration */}
            <Box>
                <EmailConfiguration
                    emailList={emailList}
                    setEmailList={setEmailList}
                    message={message}
                    setMessage={setMessage}
                    handleSave={handleSave}
                    handleDelete={handleDelete}
                    setIsMessageConfigured={setIsMessageConfigured}
                    emailTemplates={emailTemplates} // Pass the fetched templates
                />
            </Box>

            {/* Conditionally show the UserList component */}
            <Box>
                {isMessageConfigured && <UserList />} {/* Show UserList if message is configured */}
            </Box>
        </Box>
    );
};

export default ManageEmailTemplates;
