import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import { useNavigate, useLocation ,useSearchParams, useParams} from 'react-router-dom';
import { Grid, Box, Typography, Snackbar, Alert, Chip } from '@mui/material';
import {
  ImageContainer,
  FormContainer,
  StyledButton,
  StyledTextField,
  GridContainer,
} from './OTPVerification.styles';
import BackGround from '../../../../../Assets/AuthBackGround/AuthBG.png';
import { getDecryptValue } from '../../../SignIn/ChangePassword/decrypt';
import axios, { AxiosError } from 'axios';
import APIBaseURL from '../../../../../AppConfig/AppConfig.json'

const OTPVerification: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [otpValues, setOtpValues] = useState<string[]>(Array(6).fill(''));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | undefined>(undefined);
  const { tenant } = useParams<{ tenant: string }>();
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const navigate = useNavigate();
  const location = useLocation();
  const otpSentRef = useRef(false); // Ref to track OTP sending status (not causing re-renders)
  let email ='No Email Provided'; // Retrieve email from location.state
  let candidateNum:number=0
  let expiryTime: number | null = null; 
  let candidateName=''
  let candidateJobNum:number=0

  const encryptedData=searchParams.get('sxormoh5')
  if(encryptedData){
    const decryptedData=getDecryptValue(encryptedData)
    const [encEmail,encNum,encName,encJobNum,decExipry]=decryptedData.split(':')
    email=getDecryptValue(encEmail)
    candidateNum=Number(getDecryptValue(encNum))
    candidateName=getDecryptValue(encName)
    candidateJobNum=Number(getDecryptValue(encJobNum))
    expiryTime=parseInt(decExipry,10)
  }


  useEffect(()=>{
    const validateAndSendOtp =async()=>{
      if (otpSentRef.current) return;
        if(expiryTime!==null){
          const currentTime=Math.floor(Date.now()/1000)
          if(currentTime>expiryTime){
            navigate(`/${tenant}/LinkExpired`);
            return;
          }
        }
        try{
          const response=await axios.post(`${APIBaseURL.apiBaseUrl}/job/send_otp`,{
            "CandidateNum":candidateNum,"JobNum":candidateJobNum,"CandidateName":candidateName,"EmailID":email
          },{
            headers:{
              'X-Tenant': tenant,
              'X-App': baseURL,
            }
          })
          if(response.status===200){
          otpSentRef.current = true; // Mark OTP as sent
          setSnackbarMessage('OTP sent successfully!');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          
          }
        }catch(error){
          console.error('Error sending OTP:', error);
          setSnackbarMessage('Failed to send OTP.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
        
    }
    if (!otpSentRef.current) {
      validateAndSendOtp(); // Execute the validation and OTP sending logic
    }
  },[tenant, candidateNum, candidateJobNum, expiryTime, navigate])

  useEffect(() => {
    if (location.state && location.state.snackbarMessage) {
      setSnackbarMessage(location.state.snackbarMessage);
      setSnackbarSeverity(location.state.snackbarSeverity || 'success');
      setSnackbarOpen(true);
    }
  }, [location.state]);

  const handleOtpChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = value;
      setOtpValues(newOtpValues);

      // Move focus to next input if a value is entered
      if (value && index < otpValues.length - 1) {
        const nextInput = document.getElementById(`otp-${index + 1}`);
        nextInput?.focus();
      }
    }
  };

  const handleSubmit = async() => {
    if (otpValues.join('').length === 6) {
      try{
        const response=await axios.post(`${APIBaseURL.apiBaseUrl}/job/check_otp_matchstatus`,{
          "CandidateNum":candidateNum,"JobNum":candidateJobNum,"OTP":otpValues.join('')
        },{
          headers:{
            'X-Tenant': tenant,
            'X-App': baseURL,
          }
        })
        if(response.status===200){
          const candidate_access_token=response.data?.candidate_access_token
          if(candidate_access_token){
            localStorage.setItem('candidate_access_token',candidate_access_token)
          }
          setSnackbarMessage('OTP Verified Successfully!');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          navigate(`/${tenant}/CandidateDetails/CandidatePreboarding/your-offer`,{state:{candidateNum,candidateJobNum}})
        }
      }catch(error){
        if (error instanceof AxiosError) {
          // Log the error details
  
          // Check the status code of the error response
          if (error.response) {
            // The error has a response from the backend
            if (error.response.status === 404) {
              setSnackbarMessage('OTP does not match. Please try again.');
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            } else if (error.response.status === 400) {
              setSnackbarMessage('Invalid OTP format or missing parameters.');
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            } else {
              setSnackbarMessage('An unexpected error occurred.');
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            }
          } else if (error.request) {
            // The request was made, but no response was received
            setSnackbarMessage('Network error, no response from the server.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          } else {
            // Something else triggered the error
            setSnackbarMessage('An unknown error occurred.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          }
        } else {
          // If the error is not an instance of AxiosError
          console.error('Non-Axios error:', error);
          setSnackbarMessage('An unknown error occurred.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      }
    } else {
      setSnackbarMessage('Please fill in all six OTP digits.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Grid container className="otp-verification-container" sx={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
      {/* Left side with image */}
      <Grid item xs={12} md={6} sx={{ width: '50vw', height: '100vh' }}>
        <ImageContainer
          sx={{
            backgroundImage: `url(${BackGround})`,
            height: '100vh',
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Grid>

      {/* Right side with OTP form */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <FormContainer sx={{ width: '40vw', padding: '2vh 2vw' }}>
          <Box sx={{ width: '100%', marginBottom: '3vh', ml: '0.56vw' }}>
            <Typography variant="h4">Verify OTP</Typography>
            <Typography variant="subtitle1" sx={{ marginBottom: '2vh' }}>
              A verification code has been sent to your email
            </Typography>
            <Typography variant="body1" sx={{ color: '#92929D', marginBottom: '10px' }}>
      Mail: {email}
    </Typography>
          </Box>

          <GridContainer
            container
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '2vh',
              ml: '0.56vw',
            }}
          >
            {otpValues.map((digit, index) => (
              <StyledTextField
                key={index}
                id={`otp-${index}`}
                value={digit}
                onChange={handleOtpChange(index)}
                size="small"
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: 'center' },
                }}
                sx={{ width: '3vw', Height: '3vw', fontSize: '1.8vh' }}
              />
            ))}
          </GridContainer>
          {snackbarSeverity === 'error' && (
          <Typography variant="body2" color="error" sx={{ textAlign: 'center', marginTop: '1vh' }}>
            {snackbarMessage}
          </Typography>
        )}
          <StyledButton
            onClick={handleSubmit}
            variant="contained"
            fullWidth
            size="small"
            sx={{ fontSize: '2vh', marginBottom: '1vh' }}
          >
            Submit OTP
          </StyledButton>
        </FormContainer>
      </Grid>

      {/* Snackbar for messages */}
      <Snackbar open={snackbarOpen} autoHideDuration={7000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default OTPVerification;
