import { css, styled } from '@mui/material/styles';

export const StyledWrapper = styled(
    'div',
    {},
)(
    (props) => css`
        padding: 0 32px;
        .manage-user-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            padding: 20px 0px;
            .manage-user-left-wrapper {
                text-align: left;
                .manage-user-header {
                    margin-bottom: 4px;
                    font-size: 20px;
                    font-weight: 600;
                    font-family: Work sans;
                    line-height: 23px;
                    color: ${props.theme.palette.text.primary};
                }
                .manage-user-decription {
                    font-size: 12px;
                    font-weight: 400;
                    font-family: Inter;
                    line-height: 15px;
                    color: #606060;
                }
            }
            .manage-user-right-wrapper {
                display: flex;
                justify-content: end;
                .bulk-delete-btn {
                    border: 1px solid ${props.theme.palette.custom.border};
                    border-radius: 0;
                    padding: 6px;
                    margin-right: 8px;
                    svg {
                        color: ${props.theme.palette.error.main};
                    }
                }
                .table-search-container .MuiInputBase-root {
                    width: 292px;
                    margin-right: 8px;
                    height: 42px;
                    border: 1px solid ${props.theme.palette.custom.border};

                    .MuiInputAdornment-root {
                        width: 15px;
                        svg {
                            height: 20px;
                            width: 20px;
                            margin-top: 2px;
                        }
                    }
                    input::placeholder,
                    input::-webkit-input-placeholder,
                    input::-moz-placeholder {
                        font-weight: 400;
                        font-size: 12px;
                        font-family: Inter;
                    }
                }
                .add-new-btn {
                    font-size: 12px;
                    font-family: Inter;
                    font-weight: 400;
                    color: ${props.theme.palette.custom.white};
                }
            }
        }
        .manage-count-container {
            display: flex;
            justify-content: start;
        }
        .pagination-textbox {
            width: 75px;
            width: 40px;
            input {
                padding: 5px;
            }
            .MuiInput-underline {
                height: 22px;
                input {
                        font-size: 14px;
                        font-family: Inter;
                        text-indent: 5px;
}
                }
            }
        }
        .pagination-wrapper {
            display: flex;
            justify-content: end;
            align-items: center;
        }
        .pagination-btn {
            font-size: 14px;
            margin-left: 10px;
            cursor: pointer;
        }
        .pagination-text {
            font-size: 14px;
            margin-right: 10px;
        }
        .MuiTablePagination-selectLabel {
            font-size: 14px;
            font-family: Inter;
            color: ${props.theme.palette.text.primary};
        }
        .small-font-desc {
            font-size: 12px;
            font-weight: 400;
            font-family: Inter;
            line-height: 15px;
            color: #606060;
            &.MuiTypography-root {
                margin-bottom: 10px;
                color: #606060;
                font-size: 12px;
            }
        }
        .user-table.MuiPaper-elevation {
            box-shadow: none;
        }
        .user-table {
            padding: 0;
            width: calc(100% - 64px);
            .MuiTableRow-head {
                background: #f5f5f5;
            }
            .MuiCheckbox-root:not(.Mui-checked, .MuiCheckbox-indeterminate)
                svg {
                color: ${props.theme.palette.custom.border};
            }
            .MuiTableCell-head {
                font-size: 14px;
                font-family: Work Sans;
            }
            .MuiTableCell-body {
                font-size: 12px;
                font-family: Inter;
                border-bottom: 1px solid #E3E8EB;
            }
            .user-status {
                padding: 5px 10px;
                text-align: center;
                width: fit-content;
                color: #ffffff;
                border-radius: 4px;
                &.active-color {
                    background: #26b24f;
                }
                &.inactive-color {
                    background: #26b24f;
                    background: ${props.theme.palette.error.main};
                }
            }
            .update-by {
                div:first-child {
                    margin-bottom: 5px;
                }
                div:last-child {
                    color: #808080;
                }
            }
            .edit-btn,
            .delete-btn {
                padding-left: 0;
                svg {
                    height: 20px;
                }
            }
        }
    `,
);
