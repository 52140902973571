import axios from "axios";
export const updateAuthorizationHeader = () => {
    try {
        const token = localStorage.getItem('access_token');
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {

            delete axios.defaults.headers.common['Authorization'];
        }
    } catch (error) {
        console.error('Failed to update authorization header:', error);

        delete axios.defaults.headers.common['Authorization'];
    }
};
