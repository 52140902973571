import React, { useState, forwardRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Box,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { theme } from '../../../../../theme';

// Slide transition component
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface FilterSortDialogProps {
  open: boolean;
  onClose: () => void;
  onApply: (filters: any) => void;
}

// const skillsOptions = [
//   'JavaScript',
//   'TypeScript',
//   'React',
//   'Node.js',
//   'Python',
//   'Java',
//   'C++',
//   'Ruby',
//   'Go',
//   'PHP',
//   'Swift',
// ];

// const experienceRanges = ['0-2', '3-5', '6-8', '9-12', '13+'];

// const ctcRanges = ['2-3', '3-5', '6-8', '9-12', '13+'];

const sortingOptions = [
  // { value: 'year_of_experience', label: 'Experience' },
  // { value: 'ctc', label: 'CTC' },
  { value: 'CreatedDate', label: 'Date Created' },
];

const FilterSortDialog: React.FC<FilterSortDialogProps> = ({ open, onClose, onApply }) => {
  const [recruiter, setRecruiter] = useState<string>('');
  const [experience, setExperience] = useState<string>('');
  const [ctc, setCtc] = useState<string>('');
  const [skills, setSkills] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string>('');

  const handleApplyFilters = () => {
    onApply({
      recruiter,
      experience,
      ctc,
      skills,
      sortBy,
    });
    onClose();
  };

  const handleClearFilters = () => {
    setRecruiter('');
    setExperience('');
    setCtc('');
    setSkills([]);
    setSortBy('');
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition} // Apply the Slide transition
      fullScreen
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      PaperProps={{
        sx: {
          width: '20vw', // Set a specific width in vw
          height: '100vh', // Full height of the viewport
          margin: 0,
          overflow: 'hidden', // Prevent overflow
          position: 'absolute', // Position relative for inner styling
          right: 0, // Align to the right
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', p: '2vw 1.5vw 0vw 1vw' }}>
        <Box
          color={theme.palette.secondary.main}
          sx={{
            border: '0.56vw solid #EEEEEE',
            bgcolor: theme.palette.secondary.light,
            margin: '0.6vw',
            mr: '1.6vw',
            width: '3.4vw',
            height: '3.4vw',
            borderRadius: '1vw',
            display: 'flex',
            alignItems: 'center',  // Center vertically
            justifyContent: 'center' // Center horizontally
          }}
        >
          <i className="fa-solid fa-sliders "></i>
        </Box>
        <Box >
          <Typography variant="h6" component="span" sx={{display:'table-row'}}>Filter & Sort</Typography>
          <Typography variant="body3">
            Filters to get the best results
          </Typography>
        </Box>
      </DialogTitle>

      <Divider sx={{ mb: '1.2vh', mt: '2vh' }} />

      <DialogContent sx={{ overflowY: 'auto', pt: 0, mt: '1vh' }}>
        <DialogActions sx={{ justifyContent: 'space-between', p: 0, height: '2.4vh' }}>
          <Typography variant="subtitle1" whiteSpace={"nowrap"}>Add Filter</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button onClick={handleClearFilters} color="secondary" sx={{ ml: 'auto', position: 'relative', right: '-1vw' }}>
              Clear
            </Button>
          </Box>
        </DialogActions>
        {/* Recruiter Input */}
        <TextField
          fullWidth
          size="small"
          label="Recruiter"
          margin="dense"
          value={recruiter}
          onChange={(e) => setRecruiter(e.target.value)}
          autoComplete='off'
        />
        
        {/* Experience Range */}
        {/* <FormControl fullWidth margin="dense">
          <InputLabel size="small" id="experience-label">Experience</InputLabel>
          <Select
            labelId="experience-label"
            label="Experience"
            value={experience}
            size="small"
            onChange={(e) => setExperience(e.target.value as string)}
          >
            {experienceRanges.map((range) => (
              <MenuItem key={range} value={range}>
                {range}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        {/* CTC Range */}
        {/* <FormControl fullWidth margin="dense">
          <InputLabel id="ctc-label" size="small">CTC</InputLabel>
          <Select
            labelId="ctc-label"
            label='CTC'
            value={ctc}
            size="small"
            onChange={(e) => setCtc(e.target.value as string)}
            sx={{ mb: '0.8vh' }}
          >
            {ctcRanges.map((range) => (
              <MenuItem key={range} value={range}>
                {range}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        {/* Skills Selection */}
        {/* <Autocomplete
          multiple
          options={skillsOptions}
          getOptionLabel={(option) => option}
          value={skills}
          onChange={(event, newValue) => setSkills(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              label="Skills"
              placeholder="Select Skills"
            />
          )}
        />
        {/* Sorting Options */}
        <Typography variant='subtitle1' sx={{ mt:'1.5vh' }} >Sort By</Typography>
        <FormControl fullWidth margin="dense">
          <InputLabel size="small" id="sort-label">Select</InputLabel>
          <Select
            labelId="sort-label"
            label={"Select"}
            value={sortBy}
            size="small"
            onChange={(e) => setSortBy(e.target.value as string)}
          >
            {sortingOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box sx={{ display: 'flex', gap: '2vw', width: '100%', p: '0vw 1.6vw' }}>
        <Button onClick={onClose} variant="outlined" fullWidth>
            Cancel
          </Button>
          <Button onClick={handleApplyFilters} variant="contained" fullWidth>
            Apply
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FilterSortDialog;
