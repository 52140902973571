import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { theme } from '../../../theme';

interface ProfileNavBarPopupProps {
  name?: string;
  email?: string;
  open: boolean;
  onClose: () => void;
  onProfileEditClick: () => void;
  handleLogout: () => void;
}

const ProfileNavBarPopup: React.FC<ProfileNavBarPopupProps> = ({
  name,
  email,
  open,
  onClose,
  onProfileEditClick,
  handleLogout,
}) => {
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<HTMLDivElement>(null); // Ref for the logout confirmation dialog

  const handleLogoutClick = () => {
    setOpenLogoutDialog(true);
  };

  const handleCancelLogout = () => {
    setOpenLogoutDialog(false);
    onClose()
  };

  const handleConfirmLogout = () => {
    handleLogout();
    setOpenLogoutDialog(false);
    onClose(); // Close the popup after logout
  };

  // Close the popup when clicking outside, excluding the dialog
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node) &&
        (!dialogRef.current || !dialogRef.current.contains(event.target as Node))
      ) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, onClose]);

  return (
    <>
      <Paper
        ref={popupRef}
        sx={{
          width: 'fit-content',
          position: 'absolute',
          border: 'none',
          boxShadow: '0px 20px 50px -20px rgba(0, 0, 0, 0.5)',
          top: open ? '60px' : '50px',
          right: '30px',
          borderRadius: '12px 12px 8px 8px',
          opacity: open ? 1 : 0,
          display: 'block',
          transition: 'opacity 0.3s ease, top 0.3s ease',
          pointerEvents: open ? 'auto' : 'none',
          zIndex: 1300,
        }}
        elevation={3}
      >
        <Box p={2} pb={0}>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 'bold',
              marginBottom: '4px',
              cursor: 'pointer',
            }}
            onClick={onProfileEditClick}
          >
            {name ?? 'User Name'}
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              color: '#6B7280',
              marginBottom: '8px',
              cursor: 'pointer',
            }}
            onClick={onProfileEditClick}
          >
            {email ?? 'Email Address'}
          </Typography>
          <Divider />
          <Button
            variant="text"
            size="small"
            sx={{
              color: theme.palette.text.primary,
              justifyContent: 'start',
              p: 1,
              ':hover': {
                bgcolor: 'transparent',
              },
            }}
            onClick={handleLogoutClick}
            fullWidth
            endIcon={
              <Box pl={1}>
                <i className="fa-solid fa-arrow-right-from-bracket"></i>
              </Box>
            }
          >
            <Typography variant="subtitle1">Log Out</Typography>
          </Button>
        </Box>
      </Paper>

      {/* Logout Confirmation Dialog */}
      <Dialog open={openLogoutDialog} onClose={handleCancelLogout} ref={dialogRef}>
        <DialogTitle>Log Out</DialogTitle>
        <DialogContent>
          <Typography variant="body2">Are you sure you want to log out?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelLogout} sx={{ color: theme.palette.text.secondary }}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmLogout}
            variant="contained"
            sx={{
              bgcolor: theme.palette.error.main,
              '&:hover': {
                bgcolor: theme.palette.error.dark,
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileNavBarPopup;
