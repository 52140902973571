import React, { useState, useEffect } from 'react';
import { StyledWrapper } from './Login.styles';
import { Box, Button } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { updateAuthorizationHeader } from '../../../utils/tokenUtils';
import RecEzy_logo from '../../../assets/images/logos/RecEzy_logo2.svg'
import APIBaseUrl from '../../../AppConfig/AppConfig.json'

const Login: React.FC = () => {
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState<string | null>(null);
    const { tenant } = useParams<{ tenant: string }>(); 
    const navigate = useNavigate()
    const baseURL = window.location.origin.endsWith('/') 
  ? window.location.origin 
  : `${window.location.origin}/`;


    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token) {
            navigate(`/${tenant}/jobs`);
        }
    }, [navigate]);

    const handleForm = async (event: React.FormEvent) => {
        event.preventDefault()

        if (userName.trim().length === 0) {
            setError('Username is required.');
            return;
        }
        if (password.trim().length === 0) {
            setError('Password is required.');
            return;
        }
        try {
            const response = await axios.post(`${APIBaseUrl.apiBaseUrl}/account/get_token`, {
                userName, password
            },
            {
                headers: {
                    'X-Tenant': tenant,
                    'X-App': baseURL
                }
            }
        )
            if (response.status === 200) {
                const access_token = response.data;
                localStorage.setItem('access_token', access_token);
                updateAuthorizationHeader()
                navigate(`/${tenant}/jobs`)
            }
            else {
                setError('Login failed. Please try again.');
            }
        } catch (error: any) {
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        setError('Invalid username or password.');
                        break;
                    case 404:
                        setError('Tenant not found ');
                        break;
                    default:
                        setError('An error occurred. Please try again.');
                        break;
                }
            } else {
                // Network error or no response
                setError(error);
            }

        }

    }
    return (
        <StyledWrapper className="login-page" >
            <Box className="login-container"  >
                <Box className='logo-container'>
                    <img src={RecEzy_logo} style={{ height: "40px", width: "40px" }} alt="" />
                    <h2>RecEzy</h2>
                </Box>
                <p>Login and start managing your candiates!</p>
                <form className='login-form' onSubmit={handleForm}>
                    <Box className='login-label-input'>
                        <label htmlFor="username">Username</label>
                        <input type="text" placeholder='Enter Username' value={userName} onChange={(e) => (setUserName(e.target.value))} />
                    </Box>

                    <Box className='login-label-input'>
                        <label htmlFor="password">Password</label>
                        <input type="password" name="password" id="password" placeholder='Enter Password' value={password} onChange={(e) => { setPassword(e.target.value) }} />
                    </Box>

                    {error ? <Box className='error-message'>{error}</Box> : <></>}

                    <Link to={`/${tenant}/forgotpassword`}>Forgot Password</Link>
                    <Button type="submit" variant="contained">Log in</Button>
                </form>
            </Box>
        </StyledWrapper>
    );
};

export default Login;
