import React from 'react';
import { StyledWrapper } from './Jobs.styles';
import { Box, Grid } from '@mui/material';
import JobsSearchPane from './JobsSearchPane/JobsSearchPane';
import JobsRightPane from './JobsRightPane/JobsRightPane'

const Jobs: React.FC = (props) => {
    return (
        <StyledWrapper className="jobs-page">
            <Grid container spacing={0} className="container">
                <Grid item className="left-pane" xs={3.4}>
                    <Box className="job-search-container">
                        <JobsSearchPane></JobsSearchPane>
                    </Box>
                </Grid>
                <Grid item className="content-pane" xs={8.6}>
                    <Box className="job-details-container">
                        <JobsRightPane />
                    </Box>
                </Grid>
            </Grid>
        </StyledWrapper>
    );
};

export default Jobs;
