import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useParams } from 'react-router-dom';

interface JwtPayload {
    exp: number;
}

const ProtectedRoute: React.FC = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const token = localStorage.getItem('access_token');
    const { tenant } = useParams<{ tenant: string }>(); 

   
        const checkTokenExpiry = () => {
            if (!token) {
                setIsAuthenticated(false);
                return;
            }
    
            try {
                const decodedToken = jwtDecode<JwtPayload>(token);
                const currentTime = Date.now() / 1000;
    
                if (decodedToken.exp < currentTime) {
                    localStorage.removeItem('access_token');
                    setIsAuthenticated(false);
                }
            } catch (error) {
                localStorage.removeItem('access_token');
                setIsAuthenticated(false);
            }
        };
    useEffect(() => {
        // Check the token immediately
        checkTokenExpiry();
    
        // Set up an interval to check the token every 30 minutes
        const intervalId = setInterval(checkTokenExpiry, 1800000); // 1800000ms = 30 minutes
    
        // Clean up the interval on unmount
        return () => clearInterval(intervalId);
    }, []);

    if (!isAuthenticated) {
        return <Navigate to={`${tenant}`} />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
