import { css, styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, InputLabel, Button, Typography, Select } from '@mui/material';

export const PopupContainer = styled('div')(
    (props) => css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80vw;
        max-width: 510px;
        height: auto;
        max-height: 80vh;
        padding: 15px;
        gap: 10px;
        border-radius: 8px;
        background-color: ${props.theme.palette.background.default};
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        overflow: auto;
        display: flex;
        flex-direction: column;
    `,
);

export const ProfilePicContainer = styled('div')(
    (props) => css`
        width: 100px;
        height: 100px;
        margin: 10px 20px;
        padding: 4px 5px;
        gap: 1px;
        border: 1px solid ${props.theme.palette.custom.border};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    `,
);

export const ProfilePicLabel = styled('label')`
    display: block;
    cursor: pointer;
`;

export const ProfilePic = styled(FontAwesomeIcon)(
    (props) => css`
        width: 38px;
        height: 40px;
        border-radius: 50%;
        color: ${props.theme.palette.custom.textColor};
    `,
);

export const InputContainer = styled('div')`
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
`;

export const CustomInputLabel = styled(InputLabel)`
    font-weight: 500;
    color: ${(props) => props.theme.palette.custom.textColor};
    margin-bottom: 8px;
`;

export const CustomInput = styled(Input)`
    width: 100%;
    height: 46px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.palette.custom.border};
    margin-bottom: 8px;
`;

export const ButtonContainer = styled('div')`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 20px;
`;

export const CancelButton = styled(Button)`
    background-color: ${(props) => props.theme.palette.custom.cancel};
    color: ${(props) => props.theme.palette.custom.textColor};
    &:hover {
        background-color: ${(props) => props.theme.palette.custom.cancel};
    }
`;

export const SaveButton = styled(Button)`
    background-color: ${(props) => props.theme.palette.primary.main};
    color: white;
    &:hover {
        background-color: ${(props) => props.theme.palette.primary.dark};
    }
`;

export const StyledTypography = styled(Typography)`
    font-weight: 600;
    color: ${(props) => props.theme.palette.custom.textColor};
    margin-bottom: 20px;
`;

export const Asterisk = styled('span')`
    color: red;
    font-size: 1rem;
`;

export const CroppingContainer = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    padding: 20px;
    box-sizing: border-box;
`;

export const RoleSelect = styled(Select)`
    width: 100%;
    height: 46px;
    border-radius: 8px;
    margin-top: 8px;
    padding: 10px;
`;

export const ErrorText = styled('div')`
    color: red;
    font-size: 0.75rem;
    margin-top: 5px;
`;
