import React, { useEffect, useState, useCallback } from 'react';
import {
    Search as SearchIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import { StyledWrapper } from './Users.styles';
import {
    Box,
    IconButton,
    InputAdornment,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import UserTable from './UserTable';
import AddUserPopup from './AddUserPopup/AddUserPopup';
import axios from 'axios';
import { deleteItem } from './DeleteUser';
import { getUser, UserImage } from './GetUser';
import EditUserPopup from './EditUserPopup/EditUserPopup';
import { deleteMultipleUsers } from './DeleteMultipleUsers';
import APIBaseUrl from '../../../AppConfig/AppConfig.json'
import { useParams } from 'react-router-dom';

const Users: React.FC = (props) => {
    const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false)
    const [selectedId, setSelectedId] = useState([]);
    const [userData, setUserData] = useState<any>([]);
    const [filteredUserData, setFilteredUserData] = useState<any>([]);
    const [open, setOpen] = React.useState(false);
    const [itemToDelete, setItemToDelete] = useState<number>()
    const [getUserData, setGetUserData] = useState<any>(null);
    const [userDataImage, setUserDataImage] = useState<any>(null);
    const { tenant } = useParams<{ tenant: string }>();
    const baseURL = window.location.origin.endsWith('/')
        ? window.location.origin
        : `${window.location.origin}/`;


    const fetchData = async () => {
        try {
            const response = await axios.get(`${APIBaseUrl.apiBaseUrl}/user/get_users`, {
                headers: {
                    'X-Tenant': tenant,
                    'X-App': baseURL
                }
            });
            if (response.status === 200 && response.data) {
                setUserData(response.data);
            }
        } catch (err) {
            console.error("An error occurred while fetching user data:", err);

        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        const filteredData = userData.filter((row: any) => !row.DeleteItem);
        setFilteredUserData(filteredData);
    }, [userData]);

    const handleAddOpenPopup = () => {
        setIsAddPopupOpen(true);
    };

    const handleAddClosePopup = () => {
        setIsAddPopupOpen(false);
    };

    const handleSaveAddUser = async ({
        username,
        email,
        password,
        role,
        profilePic: croppedImage,
    }: any) => {
        // Add your save logic here

        try {
            const response = await axios.post(`${APIBaseUrl.apiBaseUrl}/user/add_user`, { "UserName": username, "EmailID": email, "UPassword": password, "UserRole": role[0] }, {
                headers: {
                    'X-Tenant': tenant,
                    'X-App': baseURL
                }
            });
            if (response.status === 201) {
                const userNum = response.data.UserNum.UserNum;

                if (croppedImage) {
                    const formData = new FormData();
                    formData.append('UserNum', userNum);

                    const response = await fetch(croppedImage);
                    const blob = await response.blob();

                    formData.append('Image', blob);
                    const imageResponse = await axios.post(`${APIBaseUrl.apiBaseUrl}/user/add_user_image`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'X-Tenant': tenant,
                            'X-App': baseURL
                        }
                    });

                }
                fetchData();
            }
        } catch (error) {
            console.error("Error saving user data:", error);
        }
        setIsAddPopupOpen(false);
    };


    const handleEditClosePopup = () => { setIsEditPopupOpen(false) }

    const handleSaveEditUser = async ({
        username,
        email,
        role,
        profilePic: croppedImage,
        userNum
    }: any) => {
        try {
            const response = await axios.post(`${APIBaseUrl.apiBaseUrl}/user/edit_user`, { "UserName": username, "EmailID": email, "RoleKey": role, "UserNum": userNum }, {
                headers: {
                    'X-Tenant': tenant,
                    'X-App': baseURL
                }
            });
            // Save image if it was changed

            if (croppedImage) {
                const formData = new FormData();
                formData.append('UserNum', userNum);

                const response = await fetch(croppedImage);
                const blob = await response.blob();

                formData.append('Image', blob);
                const imageResponse = await axios.post(`${APIBaseUrl.apiBaseUrl}/user/edit_user_image`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'X-Tenant': tenant,
                        'X-App': baseURL
                    }
                });
            }
            fetchData()

        } catch (error) {
            console.error("Error saving user data:", error);
        }

        setIsEditPopupOpen(false)
    }

    const handleSearchedVal = useCallback((value: string) => {
        const val = value.toLowerCase();
        const searchSortRow = userData.filter((item: any) => {
            return (
                !item.DeleteItem && // Ignore deleted items
                (
                    item.UserName.toLowerCase().includes(val) ||
                    item.EmailID.toLowerCase().includes(val) ||
                    item.UserRole.toLowerCase().includes(val) ||
                    item.UpdatedBy.toLowerCase().includes(val) ||
                    item.UserStatus.toLowerCase().includes(val)
                )
            );
        });
        setFilteredUserData(searchSortRow);
    }, [userData]);

    const handleDeleteIdToFromChild = (value: any) => {
        setItemToDelete(value);
        setOpen(true);
    };


    const handleEditIdToFromChild = async (value: any) => {
        setIsEditPopupOpen(true)
        try {
            const data = await getUser(value, tenant);
            setGetUserData(data)
            const ImageData = await UserImage(value, tenant)
            setUserDataImage(ImageData)
        } catch (err) {
            console.log(err);
        }

    };

    const handleItem = (params: string, val: string) => {
        return params.toLowerCase().includes(val)
    };

    const handleClose = (value: boolean) => {
        setOpen(false);
        if (value) {
            deleteItem(itemToDelete, tenant);
            fetchData()
        }

    };

    const handleSelectedId = (selectedId: any) => {
        setSelectedId(selectedId);
    };

    const handleBulkDelete = async () => {
        if (selectedId.length !== 0) {
            await deleteMultipleUsers(selectedId, tenant)
            fetchData()
        }
    };

    return (
        <StyledWrapper className="users">
            <Box className="manage-user-container">
                <Box className="manage-user-left-wrapper">
                    <Box className="manage-user-header">Manage Users</Box>
                    <Box className="small-font-desc">
                        Set user roles from a single place to manage recruitment
                        activities seamlessly and take actions as you like.
                    </Box>
                </Box>
                <Box className="manage-user-right-wrapper">
                    <Box className="table-search-container">
                        <FormControl className="table-search">
                            <TextField
                                id="tableSearchBar"
                                placeholder="Search"
                                onChange={(e) =>
                                    handleSearchedVal(e.target.value)
                                }
                                type="search"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                    <IconButton
                        type="button"
                        className="bulk-delete-btn"
                        onClick={(event) => handleBulkDelete()}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <Button
                        variant="contained"
                        color="primary"
                        className="add-new-btn"
                        onClick={handleAddOpenPopup}
                    >
                        Add new users
                    </Button>
                </Box>
            </Box>
            
            <UserTable
                rows={filteredUserData}
                sendDeleteIdToParent={handleDeleteIdToFromChild}
                sendEditIdToParent={handleEditIdToFromChild}
                selectedId={handleSelectedId}
            />


            <AddUserPopup
                onClose={handleAddClosePopup}
                onSave={handleSaveAddUser}
                isPopupOpen={isAddPopupOpen}
            />

            <EditUserPopup
                onClose={handleEditClosePopup}
                onSave={handleSaveEditUser}
                EditUserData={getUserData}
                EditUserImageData={userDataImage}
                isPopupOpen={isEditPopupOpen}
            />

            <Dialog
                open={open}
                onClose={(event) => handleClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Alert'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(event) => handleClose(false)}>
                        Cancel
                    </Button>
                    <Button onClick={(event) => handleClose(true)} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </StyledWrapper>
    );
};

export default Users;
