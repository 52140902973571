import { styled } from '@mui/material/styles';
import { Button, TextField, Divider, Box, Typography, Checkbox, Grid, Link } from '@mui/material';
import {theme} from '../../../../../theme';

// Custom styled components
export const ImageContainer = styled(Box)({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100vh',
});

export const FormContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // gap: '20px',
  padding: '20px',
  height: '100vh',
  lineHeight: '1rem',
});

export const Heading = styled(Typography)({
  fontSize: '40px',
  alignSelf: 'flex-start',
  // marginBottom: '10px',  
  color: theme.palette.text.primary,
});

export const IconContainer = styled(Box)({
  display: 'flex',
  gap: '30px',
  justifyContent: 'center',
  marginBottom: '0px',
});

export const StyledDivider = styled(Divider)({
  width: '96%',
  margin: '25px 0 30px 0',
  paddingRight: '0px',
});

export const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    transition: 'border-color 0.3s',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#7F56D9',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#7F56D9',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#ccc',
  },
  '& .MuiInputLabel-root': {
    // color: '#7F56D9',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#7F56D9',
  },
  width: '100%',
  marginTop: '1px',
  marginBottom: '25px',
  color: '#92929D',
});

export const StyledButton = styled(Button)({
  height: '45px',
  borderRadius: '8px', // Maintain consistent border-radius
  alignSelf: 'flex-start',  // Ensure button stretches to fit the container
  // width:'98%',
  //  marginLeft: '0',
  width: '102%',
  marginTop: '20px',
  marginBottom: '20px',
  // marginLeft: '14px',
  backgroundColor: '#7F56D9',
});


export const GridContainer = styled(Grid)({
  maxWidth: '510px',
  width: '100%',
  
});

export const TermsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  margin: '20px 0',
  gap: '10px',
  width: '100%',
});

export const TermsText = styled(Typography)({
  fontSize: '12px',
});

export const CheckboxStyled = styled(Checkbox)({
  padding: '0',
  marginRight: '10px',
});

export const SignInLink = styled(Link)({
  color: '#3f51b5',
});
