import React, { useEffect, useState } from 'react';
import { StyledWrapper, FormContainer } from './CreateNewField.styles';
import {
    Autocomplete,
    Box,
    Grid,
    IconButton,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { HighlightOff, Close } from '@mui/icons-material';
import { IFieldData } from '../CreateJob';

interface ICreateNewFieldProps {
    fieldKey: number;
    onFieldDataUpdate: (a: IFieldData) => void;
    onDeleteField: (a: number) => void;
}
const CreateNewField: React.FC<ICreateNewFieldProps> = (
    props: ICreateNewFieldProps,
) => {
    const { fieldKey, onFieldDataUpdate, onDeleteField } = props;
    const theme = useTheme();
    const [fieldName, setFieldName] = useState('');
    const [values, setValues] = useState<string[]>([]);
    const id = 'newField' + fieldKey;

    useEffect(() => {
        const element = document.getElementById(id);
        element?.scrollIntoView({ behavior: 'smooth' });
    }, [id]);

    useEffect(() => {
        onFieldDataUpdate({
            fieldKey,
            fieldName: fieldName,
            values: values,
        });
    }, [fieldName, values,fieldKey, onFieldDataUpdate]);

    const handleFieldNameChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        setFieldName(e.target.value);
    };

    const handleOnDeleteField = () => {
        onDeleteField(fieldKey);
    };

    return (
        <StyledWrapper>
            <FormContainer id={'newField' + fieldKey}>
                <Grid container>
                    <Grid xs={3} item={true} className="field-name">
                        <TextField
                            variant="outlined"
                            value={fieldName}
                            onChange={handleFieldNameChange}
                            placeholder="Enter field name"
                        ></TextField>
                    </Grid>
                    <Grid xs={9} item={true} className="field-value">
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Autocomplete
                                sx={{ width: '100%' }}
                                clearIcon={false}
                                freeSolo
                                multiple
                                options={[]}
                                value={values}
                                onChange={(e, newval:any, reason) => {
                                    setValues(newval);
                                }}
                                ChipProps={{
                                    deleteIcon: (
                                        <Close
                                            sx={{
                                                fill: theme.palette.custom
                                                    .white,
                                            }}
                                        />
                                    ),
                                }}
                                renderInput={(params:any) => {
                                    const isSpaceEntered =
                                        params.inputProps.value.charAt(
                                            params.inputProps.value.length - 1,
                                        ) === ' ';
                                    if (isSpaceEntered) {
                                        params.inputProps.value = '';
                                    }
                                    return (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Enter values separated by space"
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.stopPropagation();
                                                }
                                                if (
                                                    e.keyCode === 32 &&
                                                    e.target?.value
                                                ) {
                                                    setValues(
                                                        values.concat(
                                                            e.target?.value,
                                                        ),
                                                    );
                                                    

                                                }
                                            }}
                                        />
                                    );
                                }}
                            />
                            <IconButton
                                type="button"
                                className="delete-btn"
                                onClick={handleOnDeleteField}
                            >
                                <HighlightOff
                                    sx={{ fill: theme.palette.error.main }}
                                ></HighlightOff>
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </FormContainer>
        </StyledWrapper>
    );
};

export default CreateNewField;
