import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import MessageConfiguration from '../Templates/MessageConfiguration';
import UserList from '../Users/UserListTable';
import { useNavigate, useParams } from 'react-router-dom';
import APIBaseURL from '../../../../../AppConfig/AppConfig.json'
import axios from 'axios';

const DashboardMessage: React.FC = () => {
  const [isMessageConfigured, setIsMessageConfigured] = useState(false); // State to track when a template is selected
  const [messageList, setMessageList] = useState<string>(''); // Single string for selected message template
  const [configureDashboardMessage, setConfigureDashboardMessage] = useState<string>('');
  const [messageTemplates, setMessageTemplates] = useState<any[]>([]);
  const { tenant } = useParams<{ tenant: string }>();

  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const token = localStorage.getItem('access_token');
  const navigate = useNavigate()

  const fetchData = async () => {
    if (!token) {
      navigate(`/${tenant}/SignIn`)
    }
    else {
      try {
        const response = await axios.get(`${APIBaseURL.apiBaseUrl}/communication/get-dashboard-messages`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Tenant': tenant,
            'X-App': baseURL,
          },
        });

        if (response.status === 200) {
          setMessageTemplates(response.data);
        }
      } catch (error) {
        console.error('Error fetching email templates:', error);
      }
    }

  };

  const handleSave = async (messageList: string, dashboardMessage: string, configureDashboardMessage: string, messageNum: number | null) => {
    if (!token) {
      navigate(`/${tenant}/SignIn`)
    }
    else {
      try {
        const response = await axios.post(`${APIBaseURL.apiBaseUrl}/communication/add-dashboard-messages`, {
          "MessageSubject": dashboardMessage,
          "MessageBody": configureDashboardMessage
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Tenant': tenant,
            'X-App': baseURL,
          }
        })
        if (response.status === 201) {


          if (messageNum) {
            setMessageTemplates(preTemplates => preTemplates.map(template => template.MessageNum === messageNum ? { ...template, MessageSubject: dashboardMessage, MessageBody: configureDashboardMessage } : template))
          }
          else {
            setMessageTemplates(prevTemplates => [
              ...prevTemplates,
              { // Assuming response.data has the new message object structure
                MessageNum: response.data.MessageNum, // Adjust this based on your API response
                MessageSubject: dashboardMessage,
                MessageBody: configureDashboardMessage,
              }
            ]);
          }
        }
      } catch (error) {
        console.log('Error saving message configuration:', error)
      }
    }

  };

  const handleDelete = async (id: number | null) => {
    console.log(id)
    if (!token) {
      navigate(`/${tenant}/SignIn`)
    }

    else {
      if (typeof id === "number") {
        try {
          const response = await axios.post(`${APIBaseURL.apiBaseUrl}/communication/delete-dashboard-messages`, {
            "MessageNum": id
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Tenant': tenant,
              'X-App': baseURL,
            }
          })
          if (response.status === 200) {
            setMessageTemplates(prevTemplates => prevTemplates.filter(template => template.MessageNum !== id));
          }
        } catch (error) {
          console.log(error)
        }
      }
    }

  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Box sx={{ margin: '2.0833vw 0', display: 'flex', width: '100%', flexDirection: 'column' }}>
      <Box>
        <MessageConfiguration
          messageList={messageList} // Pass the selected template name as a string
          setMessageList={(newList) => {
            setMessageList(newList); // Update the selected message list // Show the UserList as soon as a message is selected
          }}
          configureDashboardMessage={configureDashboardMessage}
          setConfigureDashboardMessage={setConfigureDashboardMessage}
          handleSave={handleSave}
          handleDelete={handleDelete}
          setIsMessageConfigured={setIsMessageConfigured}
          messageTemplates={messageTemplates}
        />
      </Box>

      {/* Conditionally show the UserList component */}
      <Box>
        {isMessageConfigured && <UserList />}
      </Box>
    </Box>
  );
};

export default DashboardMessage;
