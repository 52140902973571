import React, { useState, ChangeEvent, useRef, useEffect } from 'react'; 
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Box, Typography, Snackbar, Alert } from '@mui/material';
import {
  ImageContainer,
  FormContainer,
  StyledButton,
  StyledTextField,
  GridContainer,
} from '../ChangePassword/ChangePassword.styles';
import BackGround from '../../../../Assets/AuthBackGround/AuthBG.png';
import axios from 'axios';
import APIBaseURL from '../../../../AppConfig/AppConfig.json'
import { useParams } from 'react-router-dom';

interface ForgetPasswordProps {}

interface FormValues {
  email: string;
}

const ForgetPassword: React.FC<ForgetPasswordProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { userId } = location.state || { userId: '' };
  const { tenant } = useParams<{ tenant: string }>();
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const [formValues, setFormValues] = useState<FormValues>({
    email: userId || '',
  });

  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | undefined>(undefined);
  const [emailSent, setEmailSent] = useState(false);
  const [snackbarWidth, setSnackbarWidth] = useState<number>(0);
  const snackbarRef = useRef<HTMLDivElement | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setEmailError(false);
    setEmailErrorMessage('');
  };

  const handleSubmit = async () => {
    const email = formValues.email;

    try {
      const response = await axios.post(`${APIBaseURL.apiBaseUrl}/user/reset_password`, { "UserID": email }, {
        headers: {
          'X-Tenant': tenant,
          'X-App': baseURL
        }
      });

      if (response.status === 200) {
        // If the email was sent successfully
        setEmailSent(true);
        setSnackbarMessage('A link to reset your password has been sent to your email.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        // Navigate to Sign In with snackbar message
        navigate(`/${tenant}/SignIn`, {
          state: {
            snackbarMessage: 'A link to reset your password has been sent to your email.',
            snackbarSeverity: 'success'
          }
        });
      }
    } catch (error) {
      // Handle different error responses
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 404) {
          setEmailError(true);
          setEmailErrorMessage('Email not found.');
          setSnackbarMessage('Email not found.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage('Failed to send email. Please try again.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      } else {
        setSnackbarMessage('An unexpected error occurred. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  const handleBackToSignIn = () => {
    navigate(`/${tenant}/SignIn`);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
   // Handle key press event for Enter key
   const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };
  useEffect(() => {
    if (snackbarRef.current) {
      setSnackbarWidth(snackbarRef.current.offsetWidth);
    }
  }, [snackbarOpen]);

  return (
    <Grid container sx={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
      {/* Left side with image */}
      <Grid item xs={12} md={6} sx={{ width: '50%', height: '100vh' }}>
        <ImageContainer
          sx={{
            backgroundImage: `url(${BackGround})`,
            height: '100vh',
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Grid>

      {/* Right side with form */}
      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <FormContainer sx={{ width: '32vw', padding: '2rem' }}>
          <Box sx={{ width: '100%', marginBottom: '20px' }}>
            <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: 600, marginBottom: '15px' }}>
              Forgot Password
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: '18px' }}>
              We will send an email to reset your password.
            </Typography>
          </Box>

          <Box sx={{ width: '100%' }}>
            <GridContainer container spacing={2}>
              <Grid item xs={12}>
                <StyledTextField
                  type="email"
                  label="Email"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  required
                  size="small"
                  sx={{ width: '30vw' }}
                  error={emailError}
                  helperText={emailError ? emailErrorMessage : ''}
                autoComplete='off'
                onKeyDown={handleKeyPress}
                />
              </Grid>
            </GridContainer>

            <GridContainer container spacing={3} sx={{ alignItems: 'center' }}>
              <Grid item xs={12}>
                <StyledButton
                  onClick={emailSent ? handleBackToSignIn : handleSubmit}
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ width: '30vw' }}
                >
                  {emailSent ? 'Back to Sign In' : 'Send Reset Email'}
                </StyledButton>
              </Grid>
            </GridContainer>
          </Box>
        </FormContainer>
      </Grid>

      <Snackbar open={snackbarOpen} autoHideDuration={7000} onClose={handleCloseSnackbar}>
        <Alert
          ref={snackbarRef}
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          icon={false}
          sx={{
            position: 'fixed',
            top: '15px',
            right: `calc(25% - ${snackbarWidth / 2}px)`,
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default ForgetPassword;
