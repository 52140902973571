import React from 'react';
import { Navigate,useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

interface RoleProtectedRouteProps {
  children: React.ReactNode;
  requiredRoles?: string[]; // Optional array of roles that can access the route
}

const RoleProtectedRoute: React.FC<RoleProtectedRouteProps> = ({ children, requiredRoles = [] }) => {
  const token = localStorage.getItem('access_token');
  let userRole = '';
  const { tenant } = useParams<{ tenant: string }>(); 

  if (token) {
    const decoded: any = jwtDecode(token);
    userRole = decoded.RoleKey; // Assuming `RoleKey` is the field that holds the user role
  }

  // If the user does not have the required role, redirect to the home page or an unauthorized page
  if (requiredRoles.length > 0 && !requiredRoles.includes(userRole)) {
    return <Navigate to={`/${tenant}/Joblisting`} />; // Redirect to Unauthorized page or login
  }

  return <>{children}</>;
};

export default RoleProtectedRoute;
