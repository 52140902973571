import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { NavLink } from 'react-router-dom';
import { StyledWrapper } from './Navbar.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { faBriefcase, faCalendarCheck, faChartLine, faUser, faUserTie } from '@fortawesome/free-solid-svg-icons';

const Navbar: React.FC = (props) => {
    const { tenant } = useParams<{ tenant: string }>(); 
    return (
        <StyledWrapper>
            <Box sx={{ display: 'block' }}>
                <AppBar component="nav" sx={{ position: 'unset' }}>
                    <Toolbar className="nav-bar-wrapper">
                        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                            {/* <Button
                                className="nav-menu-btn"
                                key={'Dashboard'}
                                sx={{}}
                            >
                                <NavLink
                                    to={'/dashboard'}
                                    className={({ isActive, isPending }) =>
                                        isPending
                                            ? 'pending'
                                            : isActive
                                                ? 'active-nav'
                                                : ''
                                    }
                                >
                                    <FontAwesomeIcon icon={faChartLine} />
                                    <span>{'Dashboard'}</span>
                                </NavLink>
                            </Button> */}
                            <Button
                                className="nav-menu-btn"
                                key={'Jobs'}
                                sx={{}}
                            >
                                <NavLink
                                    to={`/${tenant}/jobs`}
                                    className={({ isActive, isPending }) =>
                                        isPending
                                            ? 'pending'
                                            : isActive
                                                ? 'active-nav'
                                                : ''
                                    }
                                >

                                    <FontAwesomeIcon icon={faBriefcase} />
                                    <span>{'Jobs'}</span>
                                </NavLink>
                            </Button>
                            {/* <Button
                                className="nav-menu-btn"
                                key={'Candidates'}
                                sx={{}}
                            >
                                <NavLink
                                    to={'/candidates'}
                                    className={({ isActive, isPending }) =>
                                        isPending
                                            ? 'pending'
                                            : isActive
                                                ? 'active-nav'
                                                : ''
                                    }
                                >
                                    <FontAwesomeIcon icon={faUserTie} />
                                    <span>{'Candidates'}</span>
                                </NavLink>
                            </Button>
                            <Button
                                className="nav-menu-btn"
                                key={'Calender'}
                                sx={{}}
                            >
                                <NavLink
                                    to={'/calender'}
                                    className={({ isActive, isPending }) =>
                                        isPending
                                            ? 'pending'
                                            : isActive
                                                ? 'active-nav'
                                                : ''
                                    }
                                >
                                    <FontAwesomeIcon icon={faCalendarCheck} />
                                    <span>{'Calender'}</span>
                                </NavLink>
                            </Button> */}
                            <Button
                                className="nav-menu-btn"
                                key={'Users'}
                                sx={{}}
                            >
                                <NavLink
                                    to={`/${tenant}/users`}
                                    className={({ isActive, isPending }) =>
                                        isPending
                                            ? 'pending'
                                            : isActive
                                                ? 'active-nav'
                                                : ''
                                    }
                                >
                                    <FontAwesomeIcon icon={faUser} />
                                    <span>{'Users'}</span>
                                </NavLink>
                            </Button>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
        </StyledWrapper>
    );
};

export default Navbar;