import { styled } from '@mui/material/styles';
import { Box, Card, Paper, TableRow, TableCell, Avatar, Button, Chip } from '@mui/material';

// Container styling
export const Container = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
  margin: '0 auto',

  boxSizing: 'border-box',
}));

// Header styling
export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

// Card for Stats styling
export const StatsCard = styled(Card)(({ theme }) => ({
  borderRadius: '0.833vw',
  padding: theme.spacing(2),
}));

// Table Wrapper styling with Paper
export const TableWrapper = styled(Paper)(({ theme }) => ({
  borderRadius: '0.833vw',
  width: '100%',  // Ensure this spans the full width
}));


// Styled table cell
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.833vw',        
  whiteSpace: 'nowrap',
  padding: '1.111vw 1.667vw', 
}));

// Table row with alternating background color
interface TableRowStyledProps {
  isEven: boolean;
}
export const TableRowStyled = styled(TableRow)<TableRowStyledProps>(({ isEven, theme }) => ({
  backgroundColor: isEven ? '#F9F5FF' : 'transparent',
  cursor:"pointer"
}));

// Chip styling for Status
export const StatusChip = styled(Chip)(({ theme }) => ({
  fontWeight: 500,
  borderRadius: '50%',
  padding: theme.spacing(0.5, 2),
}));

// Candidate Avatar styling
export const CandidateAvatar = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  marginRight: theme.spacing(1),
}));

// Notes Button styling
export const NotesButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
}));

// Assignment status styling
export const AssignmentStatus = styled('span')(({ theme }) => ({
  color: '#B54708',
  border: '0.069vw solid #FECDCA',
  background: '#FEF3F2',
  padding: '0.278vw 0.833vw',
  borderRadius: '50%',
  fontWeight: 500,
}));

// Screening status styling
export const ScreeningStatus = styled('span')(({ theme }) => ({
  color: '#067647',
  border: '0.069vw solid #47CD89',
  background: '#ECFDF3',
  padding: '0.278vw 10.278vw',
  borderRadius: '50%',
  fontWeight: 500,
}));
