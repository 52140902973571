import { css, styled } from '@mui/material/styles';

export const StyledHeader = styled(
    'header',
    {},
)(
    ({ theme }: any) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        background-color: ${theme.palette.secondary.main};
        color: ${theme.palette.custom.border};
        padding: 0 32px;
        .logo-container {
            .logo {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: left;
                align-items: center;
                img {
                    margin-right: 8px;
                }
                    p {
                        font-weight: 700;
                        font-family: 'Work Sans';
                    }
            }
        }
        .search-container {
            padding: 10px 0;
            text-align: center;
            .search {
                .MuiOutlinedInput-root {
                    color: ${theme.palette.custom.border};
                    svg {
                        color: ${theme.palette.custom.border};
                    }
                    background-color: ${theme.palette.custom.searchBg};
                    fieldset {
                        border-color: ${theme.palette.custom.border};
                    }
                    .MuiInputBase-inputTypeSearch {
                        font-size: 12px;
                        font-family: Inter;
                        color: #ffffff;
                    }
                }
            }
        }
        .header-menu-container {
            .header-menu {
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: right;
                align-items: center;
                .menu-item {
                    margin: 0 4px;
                }
                .notification-btn svg {
                    color: ${theme.palette.custom.border};
                }
                .MuiBadge-badge {
                    top: 5px;
                    right: 6px;
                    background: ${theme.palette.error.main};
                }
                .MuiAvatar-root {
                    height: 36px;
                }
                .profile-menu {
                    button {
                        padding: 0 16px;
                    }
                }
            }
        }
    `,
);
