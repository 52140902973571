import { css, styled } from '@mui/material/styles';

export const StyledWrapper = styled(
    'div',
    {},
)(
    ({ theme }) => css`
        .container {
            margin: 0;
            height: 100%;
        }
        .job-details-dialog .job-desc {
            ul {
                margin-top: 2px;
            }
        }
        width: 100%;
        display: flex;
        height: 100%;
        text-align: center;
        background-color: white;
        color: #000;
        .left-pane {
            border-right: 1px solid ${theme.palette.divider};
            padding: 20px 0;
            height: 100%;
        }
        .content-pane {
            height: 100%;
            overflow-y: auto;
        }
        .right-pane {
            .job-nav-container {
                padding: 16px 32px;
                .head-content {
                    display: flex;
                    text-align: left;
                    justify-content: space-between;
                    .job-title {
                        font-family: Work sans;
                        font-size: 26px;
                        color: ${theme.palette.text.primary};
                        font-weight: 600;
                        line-height: 38px;
                    }
                    .job-desc-title {
                        font-family: Inter;
                        font-size: 12px;
                        color: #606060;
                    }
                }
                .right-action-container {
                    color: ${theme.palette.custom.border};
                    button {
                        border: 1px solid ${theme.palette.custom.border};
                        color: ${theme.palette.text.primary};
                        padding: 10px;
                        font-family: Inter;
                        .save-run-btn {
                                border: 1px solid rgba(0, 0, 0, 0.23);
                                color: #121315;
                                padding: 10px;
                                font-family: Inter;
                        }
                        &.status-btn {
                            margin-right: 10px;
                            border: none;
                            p {
                                margin-right: 5px;
                            }
                            .MuiOutlinedInput-input {
                                padding: 10px 36px 10px 16px;
                                font-size: 12px;
                            }
                        }
                        &:last-of-type {
                            p {
                                margin-left: 5px;
                            }
                        }
                        p {
                            font-size: 12px;
                        }
                        svg {
                            width: 26px;
                        }
                    }
                }
                .body-content {
                    .MuiTabs-root {
                        button {
                            font-size: 12px;
                            font-weight: 400;
                            font-family: Inter;
                            color: ${theme.palette.text.primary};
                            padding-bottom: 0px;
                            &.Mui-selected {
                                font-weight: 600;
                            }
                        }
                    }
                    .tab-panel-wrapper {
                        > .MuiBox-root {
                            padding: 24px 0;
                        }
                        .job-details {
                            .MuiGrid-root.MuiGrid-item {
                                padding: 0px;
                                border: none;
                                .created-detail .MuiTypography-root.MuiTypography-caption {
                                    span {
                                        font-size: 12px;
                                        color: #121315;
                                    }
                                    span:first-of-type {
                                        width: 150px;
                                        display: inline-block;
                                        font-weight: 600;
                                    }
                                    span:last-of-type {
                                        padding-left: 20px;
                                    }
                                    :last-of-type {
                                        margin-bottom: 24px;
                                    }
                                }
                            }
                            h6.MuiTypography-title {
                                margin-bottom: 0px;
                                color: ${theme.palette.text.primary};
                                font-size: 12px;
                            }
                            ul {
                                margin-top: 0px;
                                margin-right:5px;
                                padding-left: 20px;
                                text-align: left;
                                font-size: 13px;
                                color: #333333;
                            }
                            p {
                                color: #333333;
                                font-size: 12px;
                                font-family: Inter;
                            }
                            .skill-chip {
                                .MuiStack-root {
                                    flex-wrap: wrap;
                                    .MuiChip-root {
                                        margin: 5px 6px 0 0;
                                        background: none;
                                        .MuiChip-label {
                                            font-size: 12px;    
                                        }
                                        &:nth-child(3n-1) {
                                            color: #26B24F;
                                            border: 1px solid #26B24F;
                                        } 
                                        &:nth-child(3n-2) {
                                            color: #598EFC;
                                            border: 1px solid #598EFC;
                                        }
                                        &:nth-child(3n-3) {
                                            color: #FE5F87;
                                            border: 1px solid #FE5F87;
                                        }
                                    }
                                }
                                p {
                                    margin-top: 0px;
                                }
                            }
                            .MuiCard-root {
                                .MuiCardActions-root {
                                    justify-content: space-between;
                                    display: flex;
                                    padding: 16px 16px 0;
                                    .MuiTypography-root {
                                        margin: 0;
                                        font-weight: 600;
                                    }
                                    .MuiButtonBase-root {
                                        font-weight: 600;
                                        font-size: 14px;
                                        p, span {
                                            color: #9747FF;
                                        }
                                    }
                                }
                                .MuiCardContent-root {
                                    padding-bottom : 0px;
                                    :last-child {
                                        padding-bottom: 16px;
                                    }
                                    .card-content-wrapper {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        text-align: left;
                                        
                                        .card-content-left {
                                            display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            .MuiTypography-root:first-child {
                                                margin-right: 10px;
                                            }
                                            p {
                                                margin: 0px;
                                                :first-of-type {
                                                    font-weight: 600;
                                                }
                                                :last-of-type {
                                                    font-size: 13px;
                                                    color: ${theme.palette.text.secondary};
                                                }
                                            }
                                        }
                                        .card-content-right button {
                                            justify-content: right;
                                            span {
                                                margin-right: 0px;
                                                svg {
                                                    color: ${theme.palette.error.main};
                                                    width: 11px;
                                                    height: 14px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
            
    .candidate-page-wrapper {
        .candidates-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            .candidates-lhs {
                display: flex;
                align-items: center;
                svg {
                    width: 20px;
                    height: 20px;
                }
                span {
                    font-size: 12px;
                    font-family: 'Inter';
                    margin: 3px 0px 0px 3px;
                }
            }
            .candidates-rhs {
                .filter-btn {
                    margin-right: 10px;
                    height: 40px;
                    padding: 0;
                    min-width: 40px;
                }
                .mail-btn {
                    color: #ffffff;
                    height: 40px;
                    p {
                        font-size: 12px;
                        font-family: 'Inter';
                    }
                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
        .resume-selection-btn {
            display: flex;
            button {
                font-size: 12px;
                font-family: 'Inter';
                font-weight: 500;
                border: 1px solid #ccc;
                padding: 7px 9px;
                border-radius: 24px;
                margin-right: 10px;
                color: #121315;
                .resume-count {
                    padding: 0 7px;
                    border-radius: 12px;
                    color: #fff;
                    margin-left: 8px;
                }
                &.matched {
                    &.matched-clicked {
                        border: 1px solid #E3E8EB;
                        background: #EBFFFE;
                    }
                    .resume-count {
                        border: 1px solid #2B7A77;
                        background: #2B7A77;
                    }
                }
                &.un-matched {
                    &.un-matched-clicked {
                        background: #FFEBEB;
                        border: 1px solid #FFA6A6
                    }
                    .resume-count {
                        border: 1px solid #F14949;
                        background: #F14949;
                    }
                }
            }
        }
            
        .candidate-table-wrapper{
            .candidate-name {
                font-size: 12px;
                font-family: 'Inter';
                margin-bottom: 2px;
                span:first-of-type {
                    text-decoration: underline;
                }
                span:last-of-type {
                    background: #26B24F;
                    color: #ffffff;
                    font-weight: 600;
                    border-radius: 36px;
                    padding: 1px 4px;
                }
            }
            .candidate-email {
                color: #808080;
                font-size: 10px;
            }
            .update-by {
                div:first-child {
                    margin-bottom: 5px;
                }
                div:last-child {
                    color: #808080;
                }
            }
            .match-percentage {
            display: flex;
            
                .MuiStack-root {
                    display: flex;
                    gap:10px;
                    align-items: center;
                    span:last-of-type {
                        font-size: 18px;
                        font-weight: 600;
                        // margin-left: 8px;
                        text-decoration: underline;
                        padding-bottom: 0px;
                    }
                }
            }
            .table-chip {
                max-width: 200px;
                .table-chip-span{
                    border-radius: 18px;
                    font-size: 10px;
                    display: inline-block;
                    padding: 3px 8px;   
                    border: 1px solid var(--Accent-CTA, #3AAFA9);
                    font-family: 'Inter';
                    margin-right: 3px;
                    margin-bottom: 4px;
                }
                span:last-of-type{
                    white-space: nowrap;
                    color: #5277F5;
                    font-size: 10px;

                }
            }
        }

    }
    `,
);
