import styled from "@emotion/styled";
import { Card, CardContent } from "@mui/material";


export const CardItem = styled(Card)(({ theme }) => ({
    backgroundColor: '#FCFCFD',
    
    width: '100%',
    minHeight: '120px',
    borderRadius: '12px 12px 0 0',
    '&:hover': {
      borderBottom: '4px solid #7F56D9',
      backgroundColor: '#FCFAFF',
      
  },
  }));

  export const CardContentStyled = styled(CardContent)(({ theme }) => ({
    padding: 2,
  }));