import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import Sidebar from '../../Navbar/Sidebar';
import { Outlet } from 'react-router-dom';
import TopNav from '../../Navbar/TopNav'; // Import TopNav
 
const Dashboard: React.FC = () => {
  const token=localStorage.getItem('access_token')
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
      }}
    >
      <CssBaseline />
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {/* Sidebar with fixed width */}
        {token && (
          <Box
            sx={{
              width: {
                xs: '20vw', // for small screens (less than 600px)
                sm: '20vw', // for medium screens (600px - 960px)
                lg: '20vw', // for large screens (960px - 1280px)
                xl: '18vw', // for extra-large screens (1280px and up)
              },
              position: 'relative',
              flexShrink: 0,
            }}
          >
            <Sidebar />
          </Box>
        )}
 
        {/* Main content takes the rest of the available width */}
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh',width:'100%' }}>
        {token && <TopNav />}
          <Box
            sx={{
              flexGrow: 1,
              width: token ? { lg: '80vw', xl: '82vw' } : '100%', // Adjust width if TopNav is hidden
              display: 'flex',
              justifyContent: 'flex-start',
              padding: '1vw',
              overflow: 'auto', // Ensures a scrollbar appears only when needed
              '@media (max-width:600px)': {
                padding: 1, // Adjust padding for smaller screens
              },
            }}
          >
  <Outlet />
</Box>
    </Box>
    </Box>
    </Box>
  );
};
 
export default Dashboard;