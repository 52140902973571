import { styled } from '@mui/material/styles';
import { Box, Button, TableCell, TableRow } from '@mui/material';

// Root wrapper
export const Root = styled('div')({
  padding: '16px',
});

// Add User Button
export const AddButton = styled(Button)({
  // Add your button styles here
});
interface TableRowStyledProps {
  isEven: boolean;
}

export const TableRowStyled = styled(TableRow)<TableRowStyledProps>(({ isEven }) => ({
  backgroundColor: isEven ? '#F9F5FF' : 'transparent',
}));

// User Table Cell
export const UserCell = styled(TableCell)({
  display: 'flex',
  alignItems: 'center',
});

// User Avatar
export const UserAvatar = styled('div')({
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  backgroundColor: '#1976d2', // Change color as needed
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '8px',
});

// Active Status
export const ActiveStatus = styled('span')({
  color: 'green',
});

// Inactive Status
export const InactiveStatus = styled('span')({
  color: 'red',
});

// Pagination Container
export const PaginationContainer = styled('div')({
  marginTop: '16px',
  display: 'flex',
  justifyContent: 'flex-end',
});


// TableWrapper to style the table container
export const TableWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: theme.shadows[1],
}));