import React from 'react'; 
import { Box, Button, Typography } from '@mui/material';
import Cropper, { Area } from 'react-easy-crop';
import { theme } from '../../../theme';
import { relative } from 'path';

// Define and export CropPanelProps interface
export interface CropPanelProps {
  imageSrc: string | null;
  crop: { x: number; y: number };
  setCrop: React.Dispatch<React.SetStateAction<{ x: number; y: number }>>;
  zoom: number;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  onCropComplete: (croppedArea: Area, croppedAreaPixels: Area) => void;
  onSave: () => void;
  onCancel: () => void;
}

const CropPanel: React.FC<CropPanelProps> = ({
  imageSrc,
  crop,
  setCrop,
  zoom,
  setZoom,
  onCropComplete,
  onSave,
  onCancel,
}) => (
  <Box sx={{ width: '32vw', height: '80vh', display: 'flex', flexDirection: 'column', px: 2}}>
    {/* Title aligned to the left */}
    <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
      Crop Image
    </Typography>
    {/* Delete icon positioned on a new line to the right */}
    {/* <Box sx={{ display: 'flex',mt:2,color:theme.palette.primary.main, justifyContent: 'flex-end', mb: 1 ,mr:8}}>
      <i className="fa-solid fa-trash" style={{ cursor: 'pointer' }} onClick={onCancel}></i>
    </Box> */}
    {imageSrc && (
      <Box sx={{mx:5,mb:3, width: '280px', height: '280px', position: 'relative' }}>
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={1} // Ensures a 1:1 aspect ratio
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{ containerStyle: { height: '280px', width: '280px' } }} // Ensure Cropper respects dimensions
        />
      </Box>
    )}
    {/* Full-width buttons at the bottom */}
    <Box sx={{ display: 'flex', gap: 1, mt: 2 ,position:'relative',bottom:'-42vh'}}>
      <Button onClick={onCancel} variant="outlined" sx={{ flex: 1 }}>
        Cancel
      </Button>
      <Button onClick={onSave} variant="contained" sx={{ flex: 1 }}>
        Set
      </Button>
    </Box>
  </Box>
);

export default CropPanel;
