import React from 'react'
import { StyledWrapper } from './ForgotPassword.styles'
import { Box, Button, FormControl } from '@mui/material'
import { Link } from 'react-router-dom'
import RecEzy_logo from '../../../../assets/images/logos/RecEzy_logo2.svg'
import { useParams } from 'react-router-dom'
const ForgotPassword: React.FC = () => {
    const { tenant } = useParams<{ tenant: string }>(); 
    return (
        <StyledWrapper>
            <Box className='forgotpassword-container'>
                <Box className='logo-container'>
                    <img src={RecEzy_logo} style={{height:"40px",width:"40px"}}  alt="" />
                    <h2>RecEzy</h2>
                </Box>
                <form className='forgotpassword-form'>
                    <Box className='forgotpassword-label-input'>
                        <label htmlFor="userid">UserID</label>
                        <input type="text" placeholder='Enter UserID' />
                    </Box>
                    <Link to={`/${tenant}/changepassword`}><Button type="submit" variant="contained">Continue</Button></Link>
                </form>
            </Box>
        </StyledWrapper>
    )
}

export default ForgotPassword