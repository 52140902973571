import React from 'react'
import { StyledWrapper } from './ChangePassword.styles'
import { Box, Button } from '@mui/material'
import RecEzy_logo from '../../../../assets/images/logos/RecEzy_logo2.svg'

const ChangePassword: React.FC = () => {
  return (
    <StyledWrapper>
      <Box className='changepassword-container'>
        <Box className='logo-container'>
         <img src={RecEzy_logo} style={{height:"40px",width:"40px"}}  alt="" />
          <h2>RecEzy</h2>
        </Box>
        <form action="" method='POST' className='changepassword-form'>
          <Box className='changepassword-label-input'>
            <label htmlFor="newpassword">New Password</label>
            <input type="password" name="newpassword" id="newpassword" placeholder='New Password' />
          </Box>

          <Box className='changepassword-label-input'>
            <label htmlFor="confirmpassword">Confirm Password</label>
            <input type="password" name="confirmpassword" id="confirmpassword" placeholder='Confirm Password' />
          </Box>

          <Button type="submit" variant="contained" >Continue</Button>
        </form>
      </Box>
    </StyledWrapper>
  )
}

export default ChangePassword