import React from 'react';
import {
    Badge,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    Menu,
    MenuItem,
    TextField,
    useTheme,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSliders } from '@fortawesome/free-solid-svg-icons';

interface JobsSearchBarProps {
    filters: string[];
    setFilters: React.Dispatch<React.SetStateAction<string[]>>;
    onSearch: (searchTerm: string) => void;
}

const JobsSearchBar: React.FC<JobsSearchBarProps> = ({ filters, setFilters,onSearch}) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedFilters, setSelectedFilters] = React.useState<string[]>(filters);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const options = ['Open', 'Close'];

    const handleToggle = (option: string) => () => {
        const currentIndex = selectedFilters.indexOf(option);
        const newFilters = [...selectedFilters];

        if (currentIndex === -1) {
            newFilters.push(option);
        } else {
            newFilters.splice(currentIndex, 1);
        }

        setSelectedFilters(newFilters); 
    };

    const handleApply = () => {
        setFilters(selectedFilters);
        handleClose(); // Close the menu after applying
    };

  

    return (
        <Box
            className="search-bar-wrapper"
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <TextField
                className="job-search"
                id="jobSearch"
                type="search"
                placeholder="Search"
                variant="outlined"
                size="small"
                onChange={(e)=>{onSearch(e.target.value)}}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <FontAwesomeIcon
                                icon={faSearch}
                                color={theme.palette.text.primary}
                            />
                        </InputAdornment>
                    ),
                }}
            />
            <Badge
                variant="dot"
                color="primary"
                invisible={selectedFilters.length === 0}
                sx={{
                    borderColor: theme.palette.custom.border,
                    px: 0,
                    ml: 1,
                    ['.MuiBadge-badge']: {
                        top: '2px',
                        right: '2px',
                        background: theme.palette.error.main,
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                    },
                }}
            >
                <Button
                    className="filter-btn"
                    variant="outlined"
                    size="small"
                    onClick={handleClick}
                >
                    <FontAwesomeIcon
                        size="xl"
                        color={theme.palette.text.primary}
                        stroke="0"
                        icon={faSliders}
                    />
                </Button>
            </Badge>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            padding: '0 8px',
                            backgroundColor: theme.palette.custom.white,
                        },
                    },
                }}
            >
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        {options.map((option) => (
                            <MenuItem
                                key={option}
                                sx={{
                                    backgroundColor: theme.palette.custom.lightWhiteBg,
                                    color: theme.palette.text.secondary,
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    my: '3px',
                                    padding: '0 8px 0 0',
                                    borderRadius: '4px',
                                }}
                                onClick={handleToggle(option)}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedFilters.includes(option)}
                                            tabIndex={-1}
                                            name={option}
                                            sx={{
                                                px: 2,
                                            }}
                                        />
                                    }
                                    label={option}
                                />
                            </MenuItem>
                        ))}
                        <Button
                            className="apply-btn"
                            variant="contained"
                            disableElevation
                            onClick={handleApply}
                            sx={{
                                mt: 1,
                                color: theme.palette.custom.white,
                            }}
                        >
                            Apply
                        </Button>
                    </FormGroup>
                </FormControl>
            </Menu>
        </Box>
    );
};

export default JobsSearchBar;

