import React, { useState } from 'react';
import { Box, Button, Divider, Grid, TextField, Typography} from '@mui/material';
import { validatePassword } from '../Validation/validation';
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import APIBaseURL from '../../../AppConfig/AppConfig.json'

interface ProfileChangePasswordProps {
  onClose: () => void; 
  userNum:number; // Required userData prop
}

const ProfileChangePassword: React.FC<ProfileChangePasswordProps> = ({onClose,userNum}) => {
  const [currentPassword,setCurrentPassword]=useState('')
  const [newPassword,setNewPassword]=useState('')
  const [confirmPassword,setConfirmPassword]=useState('')
  const [errors,setErrors]=useState<{ [key: string]: string }>({})
  const navigate=useNavigate()
  const { tenant } = useParams<{ tenant: string }>();
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;

  const handleSave= async()=>{
    const token=localStorage.getItem('access_token')
    const newErrors: { [key: string]: string } = {};
    if(currentPassword.trim().length===0){
      newErrors.currentpassword ='Please fill this field.';
    }
    if(!validatePassword(newPassword)){
      newErrors.newpassword='Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character.'
    }
    if(newPassword!==confirmPassword){
      newErrors.confirmpassword='Passwords do not match.'
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return
  }
  if(!token){
    navigate(`/${tenant}/SignIn`)
    return 
  }
  else{
    try{
      const response = await axios.post(`${APIBaseURL.apiBaseUrl}/user/change_password_by_authorizedUser`,{"UserNum":userNum,"current_password":currentPassword,"confirm_password":confirmPassword},{
        headers:{
          Authorization: `Bearer ${token}`,
          'X-Tenant': tenant,
          'X-App': baseURL
        }
      })
      if(response.status===200){
        onClose();
      }
    }catch(error){
      const newErrors: { [key: string]: string } = {};
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 401) {
          newErrors.currentpassword = 'Invalid current password. Please try again.'; // Show error if password is incorrect
        } else {
         newErrors.error = 'An error occurred. Please try again.';
        } 
      } else {
        newErrors.error = 'An unexpected error occurred. Please try again.';
      }
      setErrors(newErrors);
    }
  }
  }
  return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} flex={1}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Change your password
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          
          <Grid item xs={12}>
                <TextField
                    fullWidth
                    size="small"
                    label="Current Password"
                    type="password"
                    variant="outlined"
                    value={currentPassword}
                    onChange={(e)=>{setCurrentPassword(e.target.value)}}
                    error={!!errors.currentpassword}
                    helperText={errors.currentpassword}
                    autoComplete='off'
                />
            </Grid>
            
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    size="small"
                    label="New Password"
                    type="password"
                    variant="outlined"
                    value={newPassword}
                    onChange={(e)=>{setNewPassword(e.target.value)}}
                    error={!!errors.newpassword}
                    helperText={errors.newpassword}
                    autoComplete='off'
                />
            </Grid>
           
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    size="small"
                    label="Confirm Password"
                    type="password"
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e)=>{setConfirmPassword(e.target.value)}}
                    error={!!errors.confirmpassword}
                    helperText={errors.confirmpassword}
                    autoComplete='off'
                />
            </Grid>
        </Grid>

        {/* Save Button at the Bottom */}
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              mb:2
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    
  );
};

export default ProfileChangePassword;
