import { styled, css } from '@mui/material';

export const StyledHeadWrapper = styled('div')(
    ({ theme: { palette } }) => css`
        .head-content {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.5rem;
            padding: 0px 2rem;
            .title {
                h5 {
                    font-family: 'Work Sans';
                    font-weight: 600;
                    font-size: 26px;
                    color: ${palette.text.primary};
                }
                justify-content: left;
            }
            .action-container {
                justify-content: right;
                button {
                    margin-left: 4px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    &.import-btn {
                        margin-right: 4px;
                    }
                    &.create-btn {
                        color: ${palette.custom.white};
                    }
                    p {
                        font-size: 12px;
                    }
                }
            }
        }
        .job-search-result {
            padding: 0px 2rem;
            max-height: calc(100vh - 275px);
            overflow-y: auto;

            /* width */
            ::-webkit-scrollbar {
                width: 5px;
            }

            /* Track */
            ::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: #d9d9d9;
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
                background: #7c7878;
            }
        }
        .search-bar-wrapper {
            display: flex;
            margin-bottom: 14px;
            padding: 0px 2rem;
            .job-search {
                width: 100%;
                margin: 0;
                #jobSearch::placeholder {
                    color: ${palette.text.primary};
                    font-size: 12px;
                    opacity: 1;
                }
                fieldset {
                    border-color: ${palette.custom.border};
                }
            }
            .filter-btn {
                min-width: 0;
                width: 40px;
                height: 40px;
            }
        }
    `,
);

export const StyledCardWrapper = styled('div')(
    ({ selectedCard, theme }: any) => css`
        .job-card {
            margin-bottom: 8px;
            cursor: pointer;
            span {
                color: ${selectedCard ? theme.palette.custom.white : ''};
                font-size: 12px;
                &.subtitle {
                    display: block;
                }
                &.card-created {
                    font-size: 10px;
                }
            }
            .job-status {
                li,
                span {
                    font-family: 'Inter';
                    font-size: 10px;
                    color: ${theme.palette.primary.light};
                }
                li {
                    font-size: 1rem;
                    margin-left: 8px;
                }
            }
        }
    `,
);
