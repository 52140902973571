import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    Container,
    BackLink,
    HeaderContainer,
    StyledHeading,
    ButtonContainer,
    FormContainer,
    Label,
    RequiredMark,
    StyledTextField,
    StyledSelect,
    StyledNewFieldContainer,
} from './CreateJob.styles';
import {
    Box,
    Button,
    Divider,
    Grid,
    Typography,
    useTheme, MenuItem
} from '@mui/material';
import CreateNewField from './CreateNewField/CreateNewField';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIBaseUrl from '../../../../AppConfig/AppConfig.json'
export interface IFieldData {
    fieldKey: number;
    fieldName: string;
    values: string[];
}
import { useParams } from 'react-router-dom';

const CreateJob: React.FC = () => {
    const [jobTitle, setJobTitle] = useState('');
    const [jobSector, setJobSector] = useState<any | string>('');
    const [jobDescription, setJobDescription] = useState('');
    const theme = useTheme();
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const [newFieldsData, setNewFieldsData] = useState<IFieldData[]>([]);
    const [showNewFieldContainer, setShowNewFieldContainer] = useState(
        !!newFieldsData.length,
    );
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            [
                { align: '' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' },
            ],
            [{ indent: '-1' }, { indent: '+1' }],
        ],
    };

    const { tenant } = useParams<{ tenant: string }>(); 
    const baseURL = window.location.origin.endsWith('/') 
  ? window.location.origin 
  : `${window.location.origin}/`;

    const handleCancel = () => {
        navigate(`/${tenant}/jobs`);
    };

    const handleSave = async (event: React.FormEvent) => {
        // Handle the save logic here
        event.preventDefault()

        if (jobTitle.trim().length === 0) {
            setError('JobTitle is required.');
            return;
        }
        if (jobSector.trim().length === 0) {
            setError('JobSector is required.');
            return;
        }
        if (jobDescription.trim().length === 0) {
            setError('JobDescription is required.');
            return;
        }
        setError(null);

        try {
            const response = await axios.post(`${APIBaseUrl.apiBaseUrl}/job/add_job`, {
                "JobTitle": jobTitle, "Department": jobSector, "JobDescr": jobDescription, "Fields": newFieldsData
            },
                {
                    headers: {
                        'X-Tenant': tenant,
                        'X-App': baseURL
                    }
                })
            if (response.status === 201) {
                navigate(`/${tenant}/jobs`);
            } else {
                setError('An unexpected error occurred.');
            }
        } catch (error: any) {
            console.log(error);
        }


    };

    const handleAddMoreField = (e: React.FormEvent) => {
        if (!showNewFieldContainer) setShowNewFieldContainer(true);
        const initFieldData: IFieldData = {
            fieldKey: newFieldsData.length + 1,
            fieldName: '',
            values: [],
        };
        setNewFieldsData([...newFieldsData, initFieldData]);
    };

    const onFieldDataUpdate = (newData: IFieldData) => {
        const updatedFieldsData = newFieldsData.map((data) =>
            data.fieldKey === newData.fieldKey ? newData : data,
        );
        setNewFieldsData(updatedFieldsData);
    };


    const onDeleteField = (deleteKey: number) => {
        const updatedFieldsData = newFieldsData.filter(
            (data) => data.fieldKey !== deleteKey,
        );
        setNewFieldsData(updatedFieldsData);
    };

    return (
        <Container>
            <BackLink onClick={() => navigate(`/${tenant}/jobs`)}>
                <FontAwesomeIcon
                    icon={faAngleLeft}
                    size="lg"
                    color={theme.palette.text.primary}
                />
                Back to job listing
            </BackLink>
            <Divider sx={{ my: 2 }} />
            <HeaderContainer>
                <StyledHeading>Create a New Job</StyledHeading>
                <ButtonContainer>
                    <Button variant="outlined" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button
                        className="save-btn"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </ButtonContainer>
            </HeaderContainer>
            {error && <div style={{ color: 'red' }}>{error}</div>}
            <FormContainer>
                <Label>
                    Job Sector<RequiredMark>*</RequiredMark>
                </Label>
                <StyledSelect
                    variant="outlined"
                    value={jobSector}
                    onChange={(e) => setJobSector(e.target.value)}
                    displayEmpty={true}
                    renderValue={(value: any) => {
                        if (value) {
                            return value;
                        }
                        return (
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    mb: 0,
                                    fontFamily: 'Inter',
                                }}
                            >
                                Select the sector
                            </Typography>
                        );
                    }}
                >
                    <MenuItem value="Development">Development</MenuItem>
                    <MenuItem value="Designer">Designer</MenuItem>
                </StyledSelect>
                <Label>
                    Job Title<RequiredMark>*</RequiredMark>
                </Label>
                <StyledTextField
                    variant="outlined"
                    value={jobTitle}
                    onChange={(e) => setJobTitle(e.target.value)}
                    placeholder="Enter job title"
                    required
                />
                <Label>
                    Job Description<RequiredMark>*</RequiredMark>
                </Label>
                <ReactQuill
                    value={jobDescription}
                    onChange={setJobDescription}
                    theme="snow"
                    placeholder="Enter job description"
                    modules={modules}
                    style={{ height: '150px', marginBottom: '50px' }}
                />
                <Box className="add-more">
                    <Button
                        variant="contained"
                        disableElevation
                        sx={{
                            width: '100%',
                            mb: 2,
                            py: '10px',
                            borderRadius: '30px',
                            backgroundColor: theme.palette.custom.infoBg,
                            '&:hover': {
                                color: theme.palette.custom.white,
                            },
                        }}
                        onClick={handleAddMoreField}
                    >
                        Add More Field
                    </Button>
                </Box>
                {showNewFieldContainer && newFieldsData.length > 0 && (
                    <StyledNewFieldContainer>
                        <Grid container>
                            <Grid item xs={3}>
                                <Typography
                                    variant="body1"
                                    className="title-text"
                                >
                                    Input Field
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography
                                    variant="body1"
                                    className="title-text"
                                >
                                    Value
                                </Typography>
                            </Grid>
                        </Grid>
                        {newFieldsData.map((data) => (
                            <CreateNewField
                                key={data.fieldKey}
                                fieldKey={data.fieldKey}
                                onFieldDataUpdate={onFieldDataUpdate}
                                onDeleteField={onDeleteField}
                            />
                        ))}
                    </StyledNewFieldContainer>
                )}
            </FormContainer>

        </Container>
    );
};

export default CreateJob;
