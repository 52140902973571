import { styled } from '@mui/material/styles';
import { Box, Typography, TextField, Link, Select, css } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
}));

export const BackLink = styled(Link)(({ theme }) => ({
    display: 'inline-block',
    cursor: 'pointer',
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '12px',
    color: theme.palette.text.primary,
    '&:hover': {
        textDecoration: 'underline',
    },
    svg: {
        marginRight: '12px',
    },
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
}));

export const StyledHeading = styled(Typography)(({ theme }) => ({
    fontFamily: 'Work Sans',
    fontSize: '18px',
    fontWeight: 600,
    textAlign: 'left',
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',

    gap: theme.spacing(1),
    '.cancel-btn': {
        borderColor: theme.palette.custom.border,
        color: theme.palette.text.primary,
        height: '40px',
    },
    '.save-btn': {
        color: theme.palette.custom.white,
        height: '40px',
    },
}));

export const FormContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}));

export const Label = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
}));

export const RequiredMark = styled('span')(({ theme }) => ({
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0.5),
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    marginBottom: 24,
    fontFamily: 'Inter',
    '& label.Mui-focused': {
        color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-input': {
        height: '12px',
        '&::placeholder': {
            fontSize: '12px',
        },
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main,
        },
    },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
    width: '100%',
    height: '46px',
    marginBottom: 12,
    fontFamily: 'Inter',
    '& label.Mui-focused': {
        color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main,
        },
    },
}));

export const StyledNewFieldContainer = styled(Box)(
    ({ theme }) => css`
        border: 1px solid ${theme.palette.custom.border};
        padding: 4px;
        margin-bottom: 16px;
        .title-text {
            padding: 4px;
            font-size: 14px;
            font-weight: 600;
        }
    `,
);