import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Paper,
  Divider,
  Grid,
  Button,
} from '@mui/material';
import EditAddQuestionsDialog from './EditQuestionsPopup';  // Ensure correct import path
import APIBaseURL from '../../../../../AppConfig/AppConfig.json'
import { useParams,useNavigate ,useOutletContext} from 'react-router-dom';
import axios from 'axios';

type Question = {
  QuestionNum: number;
  Question: string;
};


interface savedOnboardingResponseType{
  Question:string;
  QuestionNum:number;
  Response:string
}

type Answer = 'Excellent' | 'Meet' | 'Can improve';



const OnboardingExperience: React.FC = () => {
  const [answers, setAnswers] = useState<Record<number, Answer>>({});
  const [feedback, setFeedback] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false); // Track dialog visibility
  const token = localStorage.getItem('access_token')
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const navigate=useNavigate()
  const { tenant } = useParams<{ tenant: string }>();
  const {setOnboardingData,userRole,onboardingErrors,feedbackError,questions,setQuestions,savedOnboardingResponses,savedFeedbackData,isSubmitted}=useOutletContext<{setOnboardingData:any;userRole:any;onboardingErrors:any,feedbackError:any;questions:Question[];setQuestions:React.Dispatch<React.SetStateAction<Question[]>>;savedOnboardingResponses:savedOnboardingResponseType[];savedFeedbackData:string;isSubmitted:boolean}>()

  useEffect(() => {
    if (savedOnboardingResponses) {
      const populatedAnswers: Record<number, Answer> = savedOnboardingResponses.reduce(
        (acc, { QuestionNum, Response }) => {
          acc[QuestionNum] = Response as Answer; // Ensure Response is cast correctly
          return acc;
        },
        {} as Record<number, Answer> // Provide initial state type explicitly
      );
      setAnswers(populatedAnswers);
    }
    if(savedFeedbackData){
      setFeedback(savedFeedbackData)
    }
  }, [savedOnboardingResponses,savedFeedbackData]);

  const handleAnswerChange = (questionId: number, value: Answer) => {
    setAnswers((prev) => ({ ...prev, [questionId]: value }));
  };

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(event.target.value);
  };

  
  const handleEditButtonClick = () => {
    setOpenDialog(true); // Open the dialog
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Close the dialog
  };

  const handleSaveQuestions =async (updatedQuestions: Question[]) => {
    if(!token){
      navigate(`/${tenant}/SignIn`)
        return
    }
    try{
      const response=await axios.post(`${APIBaseURL.apiBaseUrl}/job/save_onboardingquestions`,{
        updatedQuestions
      },{
        headers:{
          Authorization: `Bearer ${token}`,
          'X-Tenant': tenant,
          'X-App': baseURL
        }
      })
      if(response.status===200){
        setQuestions(response.data); // Update the main questions state
        setOpenDialog(false); // Close the dialog after saving
      }
    }catch(error){
      console.log(error)
    }
  };

  useEffect(()=>{
    setOnboardingData({answers,feedback})
  },[answers,feedback])

  return (
    <Box py={'2.778vw'} width="100%">
            {userRole && (
              <Button onClick={handleEditButtonClick} variant="text" color="primary" sx={{ mb: 3,position:'absolute',right:{ sm: '18vw',md: '18vw',lg: '1vw',xl:'1vw'},top:{md: '4vw',lg: '2.5vw'} }}>
              Edit Questions
            </Button>
            )}

      <Grid container gap={'6vw'}>
        {/* Onboarding Experience Section - Left Side */}
        <Grid item xs={7}>
          <Typography variant="h6">
            Onboarding Experience
          </Typography>
          <Typography variant="body2" mb={2}>
            Please provide your feedback on our hiring process, let us know what went well and give us your suggestions for us to improve!
          </Typography>

          {/* Edit Button */}
    
          <Divider sx={{ mb: 3 }} />

          {/* Questions Section */}
          {questions.map((question, index) => (
            <Box key={question.QuestionNum} mb={2}>
              <Typography variant="body1" mt={4}>
              {question.Question}
              </Typography>
              <RadioGroup
                row
                value={answers[question.QuestionNum] || ''}
                onChange={(e) => handleAnswerChange(question.QuestionNum, e.target.value as Answer)}
              >
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: '0.833vw' }}>
                  <FormControlLabel value="Excellent" control={<Radio color="primary" disabled={isSubmitted}/>} label="Excellent" />
                  <FormControlLabel value="Meet" control={<Radio color="primary" disabled={isSubmitted}/>} label="Meet" />
                  <FormControlLabel value="Can improve" control={<Radio color="primary" disabled={isSubmitted}/>} label="Can improve" />
                </Box>
              </RadioGroup>
              {onboardingErrors[question.QuestionNum] && (
                <Typography color="error" variant="caption">
                  Please select an answer.
                </Typography>
              )}
              {/* Divider after each question, except the last one */}
              {index < questions.length - 1 && <Divider sx={{ my: '1.111vw' }} />}
            </Box>
          ))}
        </Grid>

        {/* Feedback Section - Right Side */}
        <Grid item xs={4}>
          <Paper variant="outlined" sx={{ p: '1.111vw', borderRadius: '1.111vw' }}>
            <Typography gutterBottom variant="subtitle1" fontWeight="bold" mb={'0.56vw'}>
              Feedback on how we can improve
            </Typography>
            <TextField
              // multiline
              rows={6}
              variant="outlined"
              fullWidth
              placeholder="Add feedback"
              value={feedback}
              onChange={handleFeedbackChange}
              disabled={isSubmitted}
              error={feedbackError}
              helperText={feedbackError ? 'Feedback is required.' : ''}
              sx={{ mb: '1.111vw' }}
            />

            
          </Paper>
        </Grid>
      </Grid>

     

      {/* EditAddQuestionsDialog - Opened when the edit button is clicked */}
      <EditAddQuestionsDialog
        open={openDialog}
        onClose={handleDialogClose}
        existingQuestions={questions}
        onSave={handleSaveQuestions}
      />
    </Box>
  );
};

export default OnboardingExperience;
