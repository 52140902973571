import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Container,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Divider,
  Grid,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { theme } from '../../../../../theme';
import { FileCopy } from '@mui/icons-material';
import { useNavigate, useParams, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import APIBaseURL from '../../../../../AppConfig/AppConfig.json'

interface Document {
  DocumentNum: number;
  DocumentName: string;
  UploadedStatus: boolean;
  UploadedDate: number;
  VerificationDate: number | null;
  VerifiedStatus: boolean;
  Comment: string | null;
  DocumentData: string;
  Mimetype: string// File URL for preview or download
}

const Documents: React.FC = () => {

  const { documents, setDocuments, setUpdateDocumentStatus ,isSubmitted} = useOutletContext<{ documents: Document[]; setDocuments: React.Dispatch<React.SetStateAction<Document[]>>; setUpdateDocumentStatus: React.Dispatch<React.SetStateAction<{ DocumentNum: number; VerifiedStatus: boolean }[]>> ;isSubmitted:boolean}>()
  const { personalInfoNum,userRole } = useOutletContext<any>()
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedDocUrl, setSelectedDocUrl] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const { tenant } = useParams<{ tenant: string }>();
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const [documentVerified,setDocumentVerified]=useState(false)

  // const {personalInfoNum}=useOutletContext<any>()
  
  const formatEpochToDate = (epochTime: any) => {
    const date = new Date(epochTime * 1000); // Convert seconds to milliseconds
    return date.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
  };



  const onDrop = (acceptedFiles: File[]) => {
    // Handle files as needed
    if (acceptedFiles.length > 0) {
      handleFileUpload(acceptedFiles[0])
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      // 'image/svg+xml': ['.svg'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    maxFiles: 1,
  });

  const handleCheckboxChange = (index: number, docNum: number, event: React.ChangeEvent<HTMLInputElement>) => {
    // if (userRole === 'recruiter') {
    setUpdateDocumentStatus((prevStatus = []) => {
      // Check if the document number already exists
      const existingDocIndex = prevStatus.findIndex(doc => doc.DocumentNum === docNum);

      if (existingDocIndex !== -1) {
        // If document already exists, update the Verified status
        const updatedStatus = [...prevStatus];
        updatedStatus[existingDocIndex] = {
          ...updatedStatus[existingDocIndex],
          VerifiedStatus: event.target.checked,
        };
        return updatedStatus;
      } else {
        // If document doesn't exist, add a new entry
        return [
          ...prevStatus,
          { DocumentNum: docNum, VerifiedStatus: event.target.checked },
        ];
      }
    });
    setDocuments((prevDocs) =>
      prevDocs.map((doc, docIndex) => {
        if (docIndex === index) {
          return {
            ...doc,
            VerifiedStatus: event.target.checked,
          };
        }
        return doc;
      })
    );
    // }
  };

  const base64ToUrl = (base64Data: string, mimeType: string) => {
    return `data:${mimeType};base64,${base64Data}`;
  };

  const handleDownloadClick = (documentData: string, mimeType: string, documentName: string) => {
    if (documentData) {
      const fileUrl = base64ToUrl(documentData, mimeType); // Generate file URL based on MIME type
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = documentName; // Set the download filename
      a.click(); // Trigger the download
    } else {
      console.log('Document data is missing.');
    }
  };

  // Updated functionality to open document in a popup (Dialog)
  const handleActionClick = (documentData: string, mimeType: string) => {
    if (documentData) {
      const fileUrl = base64ToUrl(documentData, mimeType); // Generate file URL based on MIME type
      setSelectedDocUrl(fileUrl);
      setOpenPopup(true); // Open the popup (Dialog)
    } else {
      console.log('Document data is missing.');
    }
  };

  const closePopup = () => {
    setOpenPopup(false);
    setSelectedDocUrl(''); // Clear the URL when the popup is closed
  };

  const handleFileUpload = async (file: File) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('file', file)
    formData.append('GroupKey', 'Pre-Offer')
    formData.append('PersonalInfoNum', personalInfoNum)
    try {
      const response = await axios.post(`${APIBaseURL.apiBaseUrl}/job/analyze_candidate_document`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Tenant': tenant,
          'X-App': baseURL,
        }
      })
      if (response.status === 200) {
        console.log(response.data)
        const uploadedDate = Math.floor(new Date().getTime() / 1000);

        // Extract MIME type and document data (base64) asynchronously
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);  // This will convert the file to base64

        // Wait for the fileReader to load the file before proceeding
        fileReader.onloadend = () => {
          const documentData = fileReader.result as string;
          const mimeType = file.type;
          const cleanDocumentData = documentData.split(',')[1];

          // Create the new document object
          const newDocument: Document = {
            DocumentNum: response.data.DocumentNum, // Assuming this comes from the API response
            DocumentName: response.data.DocumentName,
            UploadedStatus: true,
            UploadedDate: uploadedDate,
            VerificationDate: null,
            VerifiedStatus: false,
            Comment: null,
            DocumentData: cleanDocumentData, // Base64 data
            Mimetype: mimeType, // MIME type of the uploaded file
          };

          // Add the new document to the state
          setDocuments((prevDocs) => [...prevDocs, newDocument]);
        };

      }

    } catch (error) {
      console.log(error)
    }
    finally {
      setLoading(false)
    }
  }

  const renderDocumentsTable = (title: string) => (
    <Box sx={{ mb: 4 }}>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <TableContainer component={Paper} sx={{ borderRadius: '16px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body3">Document</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body3">Uploaded</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body3">Uploaded Date</Typography>
              </TableCell>
              {(userRole === 'Recruiter' || userRole==='HR' || userRole==='Admin' || userRole==='Superadmin' || documentVerified) && (
                <>
                  <TableCell>
                    <Typography variant="body3">Verified</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body3">Verification Date</Typography>
                  </TableCell>
                </>
              )}
              {/* <TableCell>
                <Typography variant="body3">Comments</Typography>
              </TableCell> */}
              <TableCell>
                <Typography variant="body3">Action</Typography>
              </TableCell> {/* Action Column */}
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.length === 0 ? (<TableRow>
              <TableCell colSpan={5} align="center">
                <Typography variant="body2" color="textSecondary">
                  No documents available
                </Typography>
              </TableCell>
            </TableRow>) : (documents.map((doc: Document, index: number) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor: index % 2 === 0 ? theme.palette.primary.light : 'inherit',
                }}
              >
                <TableCell>
                  <Typography variant="body2"
                    sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
                    onClick={() => handleDownloadClick(doc.DocumentData, doc.Mimetype, doc.DocumentName)}
                  >{doc.DocumentName}</Typography>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      width: 16,
                      height: 16,
                      borderRadius: 0.3,
                      border: '2px solid',
                      borderColor: doc.UploadedDate ? theme.palette.success.main : theme.palette.action.disabled,
                      backgroundColor: doc.UploadedDate ? theme.palette.success.main : 'transparent',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {doc.UploadedDate && (
                      <Box sx={{ color: 'white', borderRadius: '8px' }}>
                        <i className="fa-solid fa-check"></i>
                      </Box>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{formatEpochToDate(doc.UploadedDate) || 'Not uploaded'}</Typography>
                </TableCell>
                {(userRole === 'Recruiter' || userRole==='HR' || userRole==='Admin' || userRole==='Superadmin' || documentVerified) && (
                  <>
                    <TableCell>
                      <Checkbox
                        checked={doc.VerifiedStatus}
                        onChange={(event) => {handleCheckboxChange(index, doc.DocumentNum, event);setDocumentVerified(doc.VerifiedStatus)}}
                        color="success"
                        disabled={!!doc.VerificationDate}
                        sx={{
                          color: doc.VerificationDate
                            ? theme.palette.success.main
                            : theme.palette.success.main,
                          '&.Mui-disabled': {
                            color: theme.palette.success.main, // Ensure disabled checkbox remains green
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{doc.VerificationDate ? formatEpochToDate(doc.VerificationDate) : 'Not verified'}</Typography>
                    </TableCell>
                  </>
                )}
                {/* <TableCell>
                  <Chip
                    label={doc.Comment}
                    color={doc.Comment === 'Looks good' ? 'success' : 'error'}
                    sx={{
                      backgroundColor:
                        doc.Comment === 'Looks good'
                          ? theme.palette.success.light
                          : theme.palette.error.light,
                    }}
                    variant="outlined"
                  />
                </TableCell> */}
                <TableCell>
                  <IconButton
                    onClick={() => handleActionClick(doc.DocumentData, doc.Mimetype)} // Open document in popup
                    color="primary"
                    aria-label="view document"
                  >
                    <FileCopy sx={{ fontSize: 20 }} />
                  </IconButton>
                </TableCell> {/* Action Button */}
              </TableRow>
            )))}
            { }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Container sx={{ width: '76vw', p: '0 !important', mt: 4, marginLeft: 0, marginRight: 0, maxWidth: '76vw !important' }}>
      <Grid container xs={12} sx={{ px: 0, justifyContent: 'space-between', gap: 1 }}>
        <Grid item xs={8}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Government Identification Document
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {renderDocumentsTable('Pre-Offer')}
          {/* {renderDocumentsTable('Post-Offer')} */}
          {/* {renderDocumentsTable('Post-Joining')} */}
        </Grid>
        {isSubmitted && isSubmitted?(<></>):(
          <Grid item xs={3} sx={{ pl: 0, pr: 0, mt: 9.5 }}>
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <Paper
              variant="outlined"
              {...getRootProps()}
              sx={{
                p: 4,
                width: '100%',
                maxWidth: 400,
                textAlign: 'center',
                borderRadius: 2,
                borderColor: 'primary.main',
                cursor: 'pointer',
              }}
            >
              <input {...getInputProps()} disabled={loading} />
              <IconButton color="primary" aria-label="upload document" component="span">
                <CloudUploadIcon sx={{ fontSize: 40 }} />
              </IconButton>
              <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                Click to upload or drag and drop
              </Typography>
              <Typography variant="body2" color="textSecondary">
                JPEG, PNG, JPG (max. 800x400px)
              </Typography>
              {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <CircularProgress size={40} color="primary" />
                </Box>
              )}
            </Paper>
          </Box>
        </Grid>
        )}
        
      </Grid>



      <Dialog open={openPopup} onClose={closePopup} maxWidth="md" fullWidth>
        <DialogTitle>Document Preview</DialogTitle>
        <DialogContent sx={{ width: '45vw', height: '80vh', maxHeight: '80vh' }}>
          <Box sx={{ maxWidth: '100%', height: '100%' }}>
            {selectedDocUrl.includes('pdf') ? (
              <iframe
                src={selectedDocUrl}
                width="100%"
                height="100%"
                title="Document Preview"
              />
            ) : (
              <img
                src={selectedDocUrl}
                alt="Document Preview"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closePopup} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Documents;
