import axios from "axios";
import APIBaseUrl from '../../../AppConfig/AppConfig.json'

export const deleteMultipleUsers=async (UserNums:[]| any,tenant:string | undefined)=>{
    const baseURL = window.location.origin.endsWith('/')
            ? window.location.origin
            : `${window.location.origin}/`;
    try{
        await axios.post(`${APIBaseUrl.apiBaseUrl}/user/delete_multiple_users`,{"UserNums":UserNums},{
            headers:{
                'X-Tenant': tenant,
                'X-App': baseURL
            }
        })
        
    }catch(error){
        console.error('Error deleting users:',error);
    }
}