import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme, Checkbox, CircularProgress } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Pagination from '../../../Common/Pagination/pagination';
import { AssignmentStatus, Container, ScreeningStatus, StyledTypography, TableRowStyled, TableWrapper } from './ImportCandidates.styles';
import { useParams, useNavigate } from 'react-router-dom';
import APIBaseURL from '../../../../AppConfig/AppConfig.json'
import axios from 'axios';
import { CloseStatus, OnHoldStatus, OpenStatus, TableDescription } from '../../JobManagement/JobListing/JobListing.styles';
interface JobData {
  JobNum: number;
  JobTitle: string;
  Department: string;
  Status: string;
  CreatedDate: string;
  CreatedBy: string;
  UpdatedDate: string;
  UpdatedBy: string;
  JobCode: string;
  year_of_experience?: number;
  ctc?: number;
  skills?: string[];
  recruiter?: string;
  [key: string]: any;
}


const ImportCandidates: React.FC = () => {
  const theme = useTheme();
  const [fileName, setFileName] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const { tenant } = useParams<{ tenant: string }>();
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const token = localStorage.getItem('access_token')
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openjobData,setOpenJobData]=useState<JobData[]>([])
  const [filteredData, setFilteredData] = useState<JobData[]>([]);
  const [selectedJobs, setSelectedJobs] = useState<Set<number>>(new Set()); // Track selected job IDs
  const [selectAll, setSelectAll] = useState(false); // Track if "Select All" checkbox is checked
  const columnsData = ['Job title', 'Job sector', 'Job status', 'Created date', 'Created by', 'Updated date', 'Updated by']

  useEffect(() => {
      const fetchJobData = async () => {
        if(!token){
          navigate(`/${tenant}/SignIn`)
          return
        }
        try {
          const response = await axios.get(`${APIBaseURL.apiBaseUrl}/job/get_openjobs`, {
            headers: { Authorization: `Bearer ${token}`, 'X-Tenant': tenant , 'X-App': baseURL},
          });
          const data: JobData[] = Array.isArray(response.data) ? response.data : [];
          setOpenJobData(data);
        } catch (error) {
          console.error('Error fetching job data:', error);
        }
      };
      fetchJobData();
  }, []);

  
  useEffect(() => {
    setFilteredData(openjobData)
  }, [openjobData]);

  const formatEpochToDate = (epochTime: any) => {
    const date = new Date(epochTime * 1000); // Convert seconds to milliseconds
    return date.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
  };

  useEffect(() => {
    if (selectAll) {
      const allJobIds = new Set(filteredData.map(row => row.JobNum));
      setSelectedJobs(allJobIds);
    } else {
      setSelectedJobs(new Set());
    }
  }, [selectAll, filteredData]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0 && selectedJobs.size > 0) {
      const uploadedFile = acceptedFiles[0];
      setFileName(uploadedFile.name);
      setFile(uploadedFile);
    }
  }, [selectedJobs]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: 5 * 1024 * 1024, // 5MB max file size
    multiple: false, // Only allow single file uploads if required
    accept: {
      'application/zip': ['.zip'],
      // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      // 'application/vnd.ms-excel': ['.xls'],
      // 'text/csv': ['.csv'],
    },
  });

  const handleRowsPerPageChange = (rows: number) => {
    setRowsPerPage(rows);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleCheckboxChange = (jobId: number) => {
    const updatedSelectedJobs = new Set(selectedJobs);
    if (updatedSelectedJobs.has(jobId)) {
      updatedSelectedJobs.delete(jobId);
    } else {
      updatedSelectedJobs.add(jobId);
    }
    setSelectedJobs(updatedSelectedJobs);
    setSelectAll(updatedSelectedJobs.size === filteredData.length);
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
  };

  // const handleImport=async()=>{
  //   if(!file){
  //     return
  //   }
  //   if(!token){
  //     navigate(`/${tenant}/SignIn`)
  //     return
  //   }
  //   try{
  //     const formData=new FormData()
  //     formData.append('file',file)
  //     const response=await axios.post(`${APIBaseURL.apiBaseUrl}/job/import_candidate_files`,formData,{
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         Authorization: `Bearer ${token}`,
  //         'X-Tenant': tenant,
  //         'X-App': baseURL,
  //     }
  //     })
  //     if(response.status===200){
  //       console.log(response.data.Names)
  //     }
  //   }catch(error){
  //     console.log(error)
  //   }
  //   setFile(null)
  //   setFileName(null)
  // }

 

  const handleImport = async () => {
    if (!file) {
      return
    }
    if (!token) {
      navigate(`/${tenant}/SignIn`)
      return
    }
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append('file', file)
      const selectedJobsArray = Array.from(selectedJobs);  // Convert Set to Array if needed
      formData.append('selectedJobs', JSON.stringify(selectedJobsArray));
      const response = await axios.post(`${APIBaseURL.apiBaseUrl}/job/upload_file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'X-Tenant': tenant,
          'X-App': baseURL,
        }
      })
      if (response.status === 200) {
        console.log(response.data)
        navigate(`/${tenant}/CandidateExperience`)
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
    setFile(null)
    setFileName(null)
  }
  return (
    <Container ml={'1vw'} >
      <Box >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '2vh',
          }}
        >
          <Box mb='2vh'>
            <Typography variant="h5" textAlign="left">
              Import candidates
            </Typography>
            <Typography variant="subtitle1" textAlign="left" color="textSecondary">
              Quickly upload and integrate your candidates.
            </Typography>
          </Box>
        </Box>

        <Box
          {...getRootProps()}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          border={`2px dashed ${theme.palette.primary.main}`}
          borderRadius="8px"
          padding="4vh"
          height="25vh"
          sx={{
            width: '100%', // Ensuring it fills the parent width
            cursor: selectedJobs.size > 0 ? 'pointer' : 'not-allowed',
            backgroundColor: isDragActive
              ? theme.palette.action.hover
              : theme.palette.background.paper,
            opacity: selectedJobs.size > 0 ? 1 : 0.5, // Disable file selection if no checkboxes are selected
          }}
        >
          <input {...getInputProps()} disabled={selectedJobs.size === 0} />
          <CloudUploadIcon sx={{ fontSize: '4vh', color: theme.palette.primary.main }} />
          <Typography
            variant="body1"
            color="primary"
            sx={{ textDecoration: 'underline', cursor: selectedJobs.size > 0 ? 'pointer' : 'not-allowed' }}
          >
            Click to upload
          </Typography>
          <Typography variant="body2" color="textSecondary">
            or drag and drop
          </Typography>
          <Typography variant="caption" color="textSecondary">
            (Max file size: 5MB)
          </Typography>
        </Box>

        {fileName && (
          <Typography variant="body2" color="textSecondary" sx={{ marginTop: '1vh' }}>
            Selected file: {fileName}
          </Typography>
        )}

        {/* Instruction message: Add this to inform the user */}
        {selectedJobs.size <= 0 && (
          <Typography variant="body2" color="error" sx={{ marginTop: '2vh', textAlign: 'center' }}>
            Please select at least one job before uploading a file.
          </Typography>
        )}
        <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '2vh' }}>
          <Button variant="contained" color="primary" disabled={selectedJobs.size === 0 || loading} onClick={handleImport}

            startIcon={loading && <CircularProgress size={20} />} >
            {loading ? 'Uploading...' : 'Import'}
          </Button>
        </Box>
      </Box>

      <Box>
        <TableWrapper>
          <Box sx={{ overflowX: 'auto', width: '100%' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '5%' }}>
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  </TableCell>
                  {columnsData.map((column) => {
                    // Skip the first column (index 0) by checking the column name
                    if (column === 'job_id') {
                      return null; // This will skip rendering the 'job_id' column in the header
                    } else {
                      return (
                        <TableCell
                          key={column}
                          sx={{
                            color: theme.palette.text.secondary,
                            padding: '0.8vh 2.4vw',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '13.89vw',
                            minWidth: '6.94vw',
                          }}
                        >
                          <Typography variant="body3">
                            {column.charAt(0).toUpperCase() + column.slice(1).replace(/_/g, ' ')}
                          </Typography>
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>

              </TableHead>
              <TableBody>
                {filteredData.length===0?(
                  <TableRow>
                  <TableCell colSpan={columnsData.length} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    No jobs data available
                  </TableCell>
                </TableRow>
              ):(
                filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row, index) => (
                  <TableRowStyled key={row.JobNum} isEven={index % 2 === 0}>
                    <TableCell sx={{ width: '5%' }}>
                      <Checkbox
                        checked={selectedJobs.has(row.JobNum)}
                        onChange={() => handleCheckboxChange(row.JobNum)}
                      />
                    </TableCell>
                    <TableDescription>{row.JobTitle}</TableDescription>
                    <TableDescription>{row.Department}</TableDescription>
                    <TableDescription>
                      {row.Status === 'Open' ? (
                        <OpenStatus>{row.Status}</OpenStatus>
                      ) : row.Status === 'Close' ? (
                        <CloseStatus>{row.Status}</CloseStatus>
                      ) :(
                        <OnHoldStatus>{row.Status}</OnHoldStatus>
                      ) 
                      }
                    </TableDescription>
                    <TableDescription>{formatEpochToDate(row.CreatedDate)}</TableDescription>
                    <TableDescription>{row.CreatedBy}</TableDescription>
                    <TableDescription>{row.UpdatedDate ? formatEpochToDate(row.UpdatedDate) : ' '}</TableDescription>
                    <TableDescription>{row.UpdatedBy || ' '}</TableDescription>
                  </TableRowStyled>
                )))}
                
              </TableBody>
            </Table>
          </Box>

          <Box sx={{ mt: '2.5vh', display: 'flex', mb: 2, mx: 2, justifyContent: 'center' }}>
            <Pagination
              totalRows={filteredData.length}
              rowsPerPage={rowsPerPage}
              currentPage={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        </TableWrapper>
      </Box>
    </Container>
  );
};

export default ImportCandidates;
