import { styled } from '@mui/material/styles';
import { Box, Card, CardContent, Chip, TableRow, TableCell, Avatar, Button, Paper, Typography } from '@mui/material';
import { Padding } from '@mui/icons-material';

// Container
export const Container = styled(Box)(({ theme }) => ({
  width:'100%',
  margin: '0 auto',
  padding: '0 1vw 0 1vw',
  boxSizing: 'border-box',
}));

// Header
export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2vh',
}));

// Card for Stats
export const StatsCard = styled(Card)(({ theme }) => ({
  borderRadius: '1vw',
  padding: '2vw',
}));

// Table Wrapper with Paper styling
export const TableWrapper = styled(Paper)(({ theme }) => ({
  borderRadius: '1vw',  
}));

// Table Cell styling
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  
  whiteSpace: 'nowrap',
  padding: '1.5vh 2vw',
}));
// Styled Typography for Candidate ID
export const StyledTypography = styled(Typography)(({ theme }) => ({
  border: '0.069vw solid',  // Set the border color here if desired, e.g., '#000'
  borderColor: theme.palette.grey[400],
  backgroundColor: '#ffffff', // Adjust as per theme
  padding: '0.139vw 0.556vw', // Padding within the border
  borderRadius: '0.278vw', // Rounded corners
  display: 'inline-block', // Ensures it's only as wide as content
}));


// TableRow with alternating background color
interface TableRowStyledProps {
  isEven: boolean;
}
export const TableRowStyled = styled(TableRow)<TableRowStyledProps>(({ isEven, theme }) => ({
  backgroundColor: isEven ? '#F9F5FF' : 'transparent',
}));

// Chip styling for Status
export const StatusChip = styled(Chip)(({ theme }) => ({
  fontWeight: 500,
  borderRadius: '50vw',
  padding: '0.5vh 2vw',
}));

// Candidate Avatar
export const CandidateAvatar = styled(Avatar)(({ theme }) => ({
  width: '2vw',
  height: '2vw',
  marginRight: '1vw',
}));

// Notes Button
export const NotesButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
}));

export const AssignmentStatus = styled('span')(({ theme }) => ({
  color: '#B54708',
  border: '0.069vw solid #FECDCA',
  background: '#FEF3F2',
  padding: '0.5vh 2vw',
  borderRadius: '50vw',
  fontWeight: 500,
}));

export const ScreeningStatus = styled('span')(({ theme }) => ({
  color: '#067647',
  border: '0.069vw solid #47CD89',
  background: '#ECFDF3',
  padding: '0.5vh 2vw',
  borderRadius: '50vw',
  fontWeight: 500,
}));
