import * as React from 'react'; 
import { useState, useEffect } from 'react';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { useLocation, Link } from 'react-router-dom';
import {
  StyledListItemButtonParent,
  StyledListItemButtonChild,
  StyledListItemIcon,
  StyledListItemText,
  StyledAvatar,
  CompanyLogoContainer,
  StyledMainListItemText,
} from './Sidebar.styles';
import RElogo from '../../Assets/AuthBackGround/RecEzyLogo.png';
import { Box } from '@mui/material';
import { theme } from '../../theme';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Import jwt-decode

export default function Sidebar() {
  const location = useLocation();
  const [open, setOpen] = useState<string | null>(null);
  const { tenant = 'defaultTenant' } = useParams<{ tenant: string }>();
  const [activeChildPath, setActiveChildPath] = useState<string>(`/${tenant}`);
  const [lastActiveParent, setLastActiveParent] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null); // State for storing user role


  const routeToParentMap: Record<string, string> = {
    [`/${tenant}/Joblisting`]: 'jobManagement',
    [`/${tenant}/EkoMatching`]: 'ekoRecruit',
    [`/${tenant}/CandidateProfile`]: 'candidateManagement',
    [`/${tenant}/CandidateExperience`]:'candidateManagement',
    [`/${tenant}/AssignTask`]:'candidateManagement',
    [`/${tenant}/ImportCandidates`]:'candidateManagement',
    [`/${tenant}/ImportNewJob`]: 'internalJobs',
    [`/${tenant}/CandidateDetails`]: 'preboarding',
    [`/${tenant}/Dashboard`]: 'insights',
    [`/${tenant}/Users`]: 'admin',
    [`/${tenant}/Communication`]: 'admin',
  };
  
  useEffect(() => {
    try {
      const token = localStorage.getItem('access_token'); // Assuming JWT token is stored in localStorage
      if (token) {
        const decodedToken: any = jwtDecode(token); // Decode the token
        setUserRole(decodedToken.RoleKey); // Set the role from decoded token
      }
    } catch (error) {
      console.error("Error decoding JWT token:", error);
    }
  }, []);

  // Handle the parent section click events
  const handleClick = (section: string) => {
    if (open === section) {
      setOpen(null); 
      setOpen(lastActiveParent); // Reopen the previously active parent if a different parent was closed
    } else {
      setLastActiveParent(open); // Update last active parent
      setOpen(section);          // Open the clicked parent
    }
  };
  

  // Update the active parent and child based on the location
  useEffect(() => {
    const currentPath = location.pathname.toLowerCase();
    let foundActiveChild = false;
  
    Object.entries(routeToParentMap).forEach(([path, section]) => {
      const normalizedPath = path.toLowerCase();
  
      if (currentPath.includes(normalizedPath)) {
        setActiveChildPath(normalizedPath);
        setOpen(section);
        setLastActiveParent(section); // Set last active parent on component load
        foundActiveChild = true;
      }
    });
  
    if (!foundActiveChild) {
      setOpen('jobManagement');
      setActiveChildPath(`/${tenant}/Joblisting`);
      setLastActiveParent('jobManagement');
    }
  }, [location.pathname]);
  

  return (
    <List
      sx={{
        width: {
          xs: '20vw', // for small screens (less than 600px)
          sm: '20vw', // for medium screens (600px - 960px)
          md: '20vw', // for large screens (960px - 1280px)
          lg: '20vw',
          xl:'18vw' // for extra-large screens (1280px and up)
        },
        height: '100vh',
        maxWidth: '20vw',
        bgcolor: '#FCFAFF',
        borderRight: '1px solid #E4E7EC',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        position: 'fixed',
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {/* Group A: Company Logo */}
      <CompanyLogoContainer sx={{ bgcolor: '#FCFAFF', pl: '2vw' }}>
        <StyledAvatar sx={{ width: '2.5vw', height: '2.5vw', ml: '0.5vw', mt: '0.2vh' }} src={RElogo} />
        <Typography variant="h5" sx={{fontSize:'1.2vw',position:'relative',top:'0.1vh'}}>RecEzy</Typography>
      </CompanyLogoContainer>
 
      {/* Group B: Main Menu (Scrollable) */}
      <Box
        sx={{
          overflowY: 'auto',
          scrollbarWidth: 'thin',
        }}
      >
        {['jobManagement',
        //  'ekoRecruit', 
         'candidateManagement', 
        //  'internalJobs', 
         'preboarding', 
        //  'insights'
        ].map(section => (
          <React.Fragment key={section}>
            <StyledListItemButtonParent
              onClick={() => handleClick(section)}
              sx={{
                backgroundColor: open === section ? theme.palette.primary.main : 'transparent',
                color: open === section ? '#FFFFFF' : theme.palette.primary.main,
              }}
            >
              <StyledListItemIcon sx={{ color: 'inherit' }}>
                <i style={{ position: 'relative', left: '-0.3vw' }} className={getIconClass(section)} />
              </StyledListItemIcon>
              <StyledMainListItemText sx={{ color: 'inherit' }} primary={getDisplayName(section)} />
              {open === section ? (
                <i style={{ position: 'relative', right: '0.5vw' }} className="fa-solid fa-chevron-down" />
              ) : (
                <i style={{ position: 'relative', right: '0.5vw' }} className="fa-solid fa-chevron-up" />
              )}
            </StyledListItemButtonParent>
            <Collapse in={open === section} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {getChildItems(section,tenant).map(child => (
                  <Link
                    key={child.path}
                    to={child.path.toLowerCase()}
                    style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                  >
                    <StyledListItemButtonChild
                      sx={{
                        pl: '2vw',
                        backgroundColor: activeChildPath === child.path.toLowerCase() ? '#F4EBFF' : 'transparent',
                        color: activeChildPath === child.path.toLowerCase() ? theme.palette.primary.main : '#000000',
                      }}
                    >
                      <StyledListItemText primary={child.name} />
                    </StyledListItemButtonChild>
                  </Link>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
        {(userRole === 'Superadmin' || userRole === 'Admin') && (
          <React.Fragment>
            <StyledListItemButtonParent
              onClick={() => handleClick('admin')}
              sx={{
                backgroundColor: open === 'admin' ? theme.palette.primary.main : 'transparent',
                color: open === 'admin' ? '#FFFFFF' : theme.palette.primary.main,
              }}
            >
              <StyledListItemIcon sx={{ color: 'inherit' }}>
                <i style={{ position: 'relative', right: '0.5vw' }} className="fa-solid fa-user-shield" />
              </StyledListItemIcon>
              <StyledMainListItemText sx={{ color: 'inherit' }} primary="Admin" />
              {open === 'admin' ? (
                <i style={{ position: 'relative', right: '0.5vw' }} className="fa-solid fa-chevron-down" />
              ) : (
                <i style={{ position: 'relative', right: '0.5vw' }} className="fa-solid fa-chevron-up" />
              )}
            </StyledListItemButtonParent>
            <Collapse in={open === 'admin'} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {getChildItems('admin', tenant).map(child => (
                  <Link
                    key={child.path}
                    to={child.path.toLowerCase()}
                    style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                  >
                    <StyledListItemButtonChild
                      sx={{
                        pl: '2vw',
                        backgroundColor: activeChildPath === child.path.toLowerCase() ? '#F4EBFF' : 'transparent',
                        color: activeChildPath === child.path.toLowerCase() ? theme.palette.primary.main : '#000000',
                      }}
                    >
                      <StyledListItemText primary={child.name} />
                    </StyledListItemButtonChild>
                  </Link>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        )}
      </Box>
    </List>
  );
}

// Helper Functions
const getIconClass = (section: string) => {
  const icons: Record<string, string> = {
    jobManagement: 'fa-solid fa-briefcase fa-fw',
    // ekoRecruit: 'fa-brands fa-uncharted fa-fw',
    candidateManagement: 'fa-solid fa-database fa-fw',
    // internalJobs: 'fa-solid fa-circle-nodes fa-fw',
    preboarding: 'fa-regular fa-clock fa-fw',
    // insights: 'fa-solid fa-chart-line fa-fw',
    admin: 'fa-solid fa-user-shield fa-fw',
  };
  return icons[section];
};

const getDisplayName = (section: string) => {
  const displayNames: Record<string, string> = {
    jobManagement: 'Job Management',
    // ekoRecruit: 'Eko Recruit',
    candidateManagement: 'Candidate Management',
    // internalJobs: 'Internal Jobs',
    preboarding: 'Pre-boarding',
    // insights: 'Insights',
    admin: 'Admin',
  };
  return displayNames[section];
};

const getChildItems = (section: string,tenant: string) => {
  if (!tenant) {
    // Handle the case where tenant is undefined, return an empty array or throw an error
    return [];
  }
  const childItems: Record<string, { path: string; name: string }[]> = {
    jobManagement: [
      { path: `/${tenant}/Joblisting`, name: 'Job Listing' },
      // { path: `/${tenant}/Askekotocreateajobposting`, name: 'Ask Eko to Create a Job Posting' },
      // { path: `/${tenant}/Importjobs`, name: 'Import Jobs' },
    ],
    // ekoRecruit: [{ path: `/${tenant}/EkoMatching`, name: 'Eko Matching' }],
    candidateManagement: [
      { path: `/${tenant}/CandidateProfile`, name: 'Candidate Profile' },
      { path: `/${tenant}/CandidateExperience`, name: 'Candidate Experience' },
      { path: `/${tenant}/AssignTask`, name: 'Assign Task' },
      { path: `/${tenant}/ImportCandidates`, name: 'Import Candidates' },
    ],
    // internalJobs: [{ path: `/${tenant}/ImportNewJob`, name: 'Import New Job' }],
    preboarding: [{ path: `/${tenant}/CandidateDetails`, name: 'Candidate Details' }],
    // insights: [
    //   { path: `/${tenant}/Dashboard`, name: 'Dashboard' },
    //   { path: `/${tenant}/Reports`, name: 'Reports' },
    // ],
    admin: [
      { path: `/${tenant}/Users`, name: 'Users' },
      { path: `/${tenant}/Communication`, name: 'Communication' },
    ],
  };
  return childItems[section] || []; 
};
