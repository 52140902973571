import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, ButtonGroup } from '@mui/material';
import { useNavigate, useParams, useLocation, Outlet, Form } from 'react-router-dom'; // Use Outlet to render nested routes
import { StyledButton } from './CandidatePreboarding.styles';
import { usePreboarding } from './PreboardingContext';
import axios from 'axios';
import APIBaseURL from '../../../../AppConfig/AppConfig.json'
import { jwtDecode } from 'jwt-decode';

interface DrivingLicense {
  licenseNumber: string;
  issueDate: Date | string;
  expiryDate: Date | string;
}

interface Passport {
  nameInPassport: string;
  passportNumber: string;
  issueDate: Date | string;
  expiryDate: Date | string;
  issuePlace: string;
}

interface BankDetails {
  bankName: string;
  accountNumber: string;
  ifsc: string;
}

interface SocialMedia {
  twitter: string;
  facebook: string;
}

interface SkillsData {
  field: string;
  value: string;
}
interface FormData {
  fullName: string;
  knownAs: string;
  fatherName: string;
  motherName: string;
  email: string;
  placeOfBirth: string;
  nationality: string;
  maritalStatus: string;
  weddingDate: Date | string;
  motherTongue: string;
  religion: string;
  speciallyAble: boolean;
  drivingLicense: DrivingLicense;
  passport: Passport;
  permanentAddress: string;
  bankDetails: BankDetails;
  socialMedia: SocialMedia;
  skillsData: SkillsData[]; // Added skills data to formData
}

interface Document {
  DocumentNum: number;
  DocumentName: string;
  UploadedStatus: boolean;
  UploadedDate: number;
  VerificationDate: number | null;
  VerifiedStatus: boolean;
  Comment: string | null;
  DocumentData: string;
  Mimetype:string // File URL for preview or download
}

type Question = {
  QuestionNum: number;
  Question: string;
};

interface savedOnboardingResponseType{
  Question:string;
  QuestionNum:number;
  Response:string
}



const CandidatePreboarding: React.FC = () => {
  const [selectedButton, setSelectedButton] = useState<string>('your-offer'); // Default to 'your-offer'
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false); // Track submission status
  const navigate = useNavigate();
  const { offerAccepted, offerDeclined ,setOfferAccepted, setOfferDeclined} = usePreboarding();
  const { tenant } = useParams<{ tenant: string }>();
  const location = useLocation()
  const { candidate } = location.state || {}
  const [candidateNum,setCandidateNum]=useState<number|null>(null)
  const [candidateJobNum,setCandidateJobNum]=useState<number | null>(null)
  const [errors, setErrors] = useState<any>({});
  const [parentFormData, setParentFormData] = useState<FormData>()
  const token = localStorage.getItem('access_token')
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const [personalInfoNum, setPersonalInfoNum] = useState<number | null>(null)
  const [documents, setDocuments] = useState<Document[]>([])
  const [updateDocumentStatus, setUpdateDocumentStatus] = useState<{ DocumentNum: number; VerifiedStatus: boolean }[]>([])
  const [emailBody, setEmailBody] = useState<string | null>(null);
  const [candidateOfferletterData,setCandidateOfferletterData]=useState<{ActionDate:number,CandidateNum:number,Offerdate:string,OfferReviewed:boolean,OfferStatus:boolean,RecruiterName:string,JobNum:number}>()
  const [candidatePersonalInfo,setCandidatePersonalInfo]=useState()
  const [onboardingData,setOnboardingData]=useState<any>()
  const candidate_access_token=localStorage.getItem('candidate_access_token')
  const [userRole, setUserRole] = useState<string|null>(null);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [onboardingErrors, setOnboardingErrors] = useState<Record<number, boolean>>({});
  const [feedbackError, setFeedbackError] = useState<boolean>(false);
  const [savedOnboardingResponses,setSavedOnboardingResponses]=useState<savedOnboardingResponseType[]>([])
  const [savedFeedbackData,setSavedFeedbackData]=useState<string>('')

  const tabMapping: Record<string, string> = {
    'your-offer': 'your-offer',
    'personal-details': 'personal-details',
    'documents': 'documents',
    'onboarding-experience': 'onboarding-experience',
  };

  useEffect(()=>{
    if(token){
      const decodeToken:any=jwtDecode(token)
      setUserRole(decodeToken["RoleKey"])
    }
    else{
      setUserRole(null)
    }
  },[token])

  useEffect(()=>{
    if(candidate_access_token){
      const decode=jwtDecode<any>(candidate_access_token)
      setCandidateJobNum(decode["JobNum"])
      setCandidateNum(decode["sub"])
    }
  },[candidate_access_token])

  useEffect(() => {
    const currentPath = location.pathname.split('/').pop(); // Get the last segment of the URL
    if (currentPath && tabMapping[currentPath]) {
      setSelectedButton(tabMapping[currentPath]);
    } else {
      navigate(`/${tenant}/CandidateDetails/CandidatePreboarding/your-offer`);
    }
  }, [location.pathname]);



  useEffect(()=>{
    const fetchPersonalInfoByUser = async () => {
      if (candidate?.CandidateNum && candidate?.JobNum) {
        if(!token){
          navigate(`/${tenant}/SignIn`)
          return
        }
        try {
          const response = await axios.get(`${APIBaseURL.apiBaseUrl}/job/get_candidate_personalinfo/${candidate.CandidateNum}/${candidate.JobNum}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Tenant': tenant,
              'X-App': baseURL,
            }
          })
          if (response.status === 200 && response.data) {
            setCandidatePersonalInfo(response.data)
            setPersonalInfoNum(response.data.PersonalInfoNum)
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
    fetchPersonalInfoByUser()

    const fetchOfferDataByUser = async () => {    
      if (candidate?.CandidateNum && candidate?.JobNum) {
        if(!token){
          navigate(`/${tenant}/SignIn`)
          return
        }
        try {
          // Make your API call here
          const response = await axios.get(`${APIBaseURL.apiBaseUrl}/job/candidate_offerletter_data/${candidate.CandidateNum}/${candidate.JobNum}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Tenant': tenant,
              'X-App': baseURL,
            },
          });
          if (response.status === 200 && response.data) {
            setCandidateOfferletterData(response.data)
            if (response.data.OfferStatus) {
              setOfferAccepted(response.data.OfferStatus)
            }
            else {
              setOfferDeclined(response.data.OfferStatus)
            }
          }
  
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    }
    fetchOfferDataByUser()


    const fetchOfferLetterByUser=async()=>{
      if(candidate?.CandidateNum && candidate?.JobNum){
        if(!token){
          navigate(`/${tenant}/SignIn`)
          return
        }
        try {
          const response = await axios.get(`${APIBaseURL.apiBaseUrl}/job/candidate_offerletter_by_user/${candidate.CandidateNum}/${candidate.JobNum}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Tenant': tenant,
              'X-App': baseURL,
            },
          });
          if (response.status === 200) {
            if(response.data.OfferLetter){
              setEmailBody(response.data.OfferLetter.replace('Click Here to offer letter',''))
            }
            else{
              setEmailBody('There is no offer letter for this candidate')
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    }

    fetchOfferLetterByUser()

    const fetchFeedbackAndSubmittedByUser=async()=>{
      
      if(candidate?.CandidateNum && candidate?.JobNum){
        if(!token){
          navigate(`/${tenant}/SignIn`)
          return
        }
        try{
          const response= await axios.get(`${APIBaseURL.apiBaseUrl}/job/get_feedback_submitted/${candidate.CandidateNum}/${candidate.JobNum}`,{
            headers:{
              Authorization: `Bearer ${token}`,
              'X-Tenant': tenant,
            'X-App': baseURL
            }
          })
          if(response.status===200){
            setSavedFeedbackData(response.data.Feedback || '')
            setIsSubmitted(response.data.Submitted)
          }
        }catch(error){
          console.log(error)
        }
      }
    }
    fetchFeedbackAndSubmittedByUser()
  },[candidate?.CandidateNum,candidate?.JobNum])




  useEffect(() => {
    const fetchPersonalInfo = async () => {
      if (candidateNum && candidateJobNum) {
        try {
          const response = await axios.get(`${APIBaseURL.apiBaseUrl}/job/get_candidate_personalinfo/${candidateNum}/${candidateJobNum}`, {
            headers: {
              'X-Tenant': tenant,
              'X-App': baseURL,
            }
          })
          if (response.status === 200 && response.data) {
            setCandidatePersonalInfo(response.data)
            setPersonalInfoNum(response.data.PersonalInfoNum)
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
    fetchPersonalInfo()

    const fetchOfferData = async () => {
      if (candidateNum && candidateJobNum) {
        try {
          // Make your API call here
          const response = await axios.get(`${APIBaseURL.apiBaseUrl}/job/candidate_offerletter_data/${candidateNum}/${candidateJobNum}`, {
            headers: {
              'X-Tenant': tenant,
              'X-App': baseURL,
            },
          });
          if (response.status === 200 && response.data) {
            setCandidateOfferletterData(response.data)
            if (response.data.OfferStatus) {
              setOfferAccepted(response.data.OfferStatus)
            }
            else {
              setOfferDeclined(response.data.OfferStatus)
            }
          }
  
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    }
    fetchOfferData()

    const fetchOfferLetter=async()=>{
      if(candidateNum && candidateJobNum){
        try {
          const response = await axios.get(`${APIBaseURL.apiBaseUrl}/job/candidate_offerletter/${candidateNum}/${candidateJobNum}`, {
            headers: {
              'X-Tenant': tenant,
              'X-App': baseURL,
            },
          });
          if (response.status === 200) {
            if(response.data.OfferLetter){
              setEmailBody(response.data.OfferLetter.replace('Click Here to offer letter',''))
            }
            else{
              setEmailBody('')
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    fetchOfferLetter()

    const fetchQuestionsData=async()=>{
      try{
        const response=await axios.get(`${APIBaseURL.apiBaseUrl}/job/get_onboardingquestions`,{
          headers:{
            'X-Tenant': tenant,
            'X-App': baseURL
          }
        })
        if(response.status===200){
          setQuestions(response.data); // Transform API data
        }
      }catch(error){
        console.log(error)
      }
    }
    fetchQuestionsData()

    const fetchFeedbackAndSubmitted=async()=>{
      if(candidateNum && candidateJobNum){
        try{
          const response= await axios.get(`${APIBaseURL.apiBaseUrl}/job/get_feedback_submitted/${candidateNum}/${candidateJobNum}`,{
            headers:{
              'X-Tenant': tenant,
            'X-App': baseURL
            }
          })
          if(response.status===200){
            setSavedFeedbackData(response.data.Feedback || '')
            setIsSubmitted(response.data.Submitted)
          }
        }catch(error){
          console.log(error)
        }
      }
    }
    fetchFeedbackAndSubmitted()
  }, [candidateNum,candidateJobNum])



  useEffect(() => {
    const fetchDocuments = async () => {
     
      if (personalInfoNum) {
        try {
          const response = await axios.get(
            `${APIBaseURL.apiBaseUrl}/job/get_candidate_documents/${personalInfoNum}`,
            {
              headers: {
                'X-Tenant': tenant,
                'X-App': baseURL,
              },
            }
          );
          if (response.status === 200) {
            setDocuments(response.data || []); // Ensure correct structure
          }
        } catch (error: any) {
          console.error('Error fetching documents:', error.response || error.message);
        }
      }
    };

    fetchDocuments();


    const fetchSavedOnboardingResponses=async()=>{
      if(personalInfoNum){
        try{
          const response=await axios.get(`${APIBaseURL.apiBaseUrl}/job/get_saved_onboarding_feedback/${personalInfoNum}`,{
            headers:{
              'X-Tenant': tenant,
              'X-App': baseURL,
            }
          })
          if(response.status===200 && response.data){
            setSavedOnboardingResponses(response.data)
          }
        }catch(error){
          console.log(error)
        }
      }
    }
    fetchSavedOnboardingResponses()
  }, [personalInfoNum]);

  const validateForm = (formData: FormData) => {
    const newErrors: Record<string, string> = {};
    let isValid = true;

    // Helper to validate grouped/nested fields conditionally
    const validateNestedFields = <T extends Record<string, any>>(
      fieldName: string,
      fields: T
    ) => {
      const isAnyFieldFilled = Object.values(fields).some((value) => !!value);

      if (isAnyFieldFilled) {
        (Object.keys(fields) as Array<keyof T>).forEach((key) => {
          const stringKey = String(key);
          const value = fields[key];

          if (!value) {
            newErrors[`${fieldName}.${stringKey}`] = `${stringKey} is required`;
            isValid = false;
          }

          // Validate date fields specifically
          if (
            (key.toString().includes('expiryDate') || stringKey.includes('issueDate')) &&
            value
          ) {
            const dateValue = new Date(value as string);
            if (key.toString().includes('expiryDate') && dateValue < new Date()) {
              newErrors[`${fieldName}.${stringKey}`] = `${stringKey} cannot be in the past`;
              isValid = false;
            }
          }
        });
      }
    };

    // Validate Personal Information
    const requiredFields: Array<keyof FormData> = [
      'fullName',
      'knownAs',
      'fatherName',
      'motherName',
      'email',
      'placeOfBirth',
      'nationality',
      'maritalStatus',
      'motherTongue',
      'religion',
    ];

    requiredFields.forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = `${key} is required`;
        isValid = false;
      }
    });

    // Email validation
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'A valid email is required';
      isValid = false;
    }

    // Marital Status Specific Validation
    if (
      formData.maritalStatus &&
      formData.maritalStatus.toLowerCase() === 'married' &&
      !formData.weddingDate
    ) {
      newErrors.weddingDate = 'Wedding Date is required if married';
      isValid = false;
    }

    if (!formData.permanentAddress) {
      newErrors.permanentAddress = 'Permanent address is required';
      isValid = false;
    }
    // Conditional Group Validations
    validateNestedFields('drivingLicense', formData.drivingLicense);
    validateNestedFields('passport', formData.passport);
    validateNestedFields('bankDetails', formData.bankDetails);

    // Set errors to state
    setErrors(newErrors);
    return isValid;
  };



  const handleNavigation = (path: string, buttonName: string) => {
    setSelectedButton(buttonName);
    navigate(path, { state: { candidate, isSubmitted } });
  };

 

  const validateOnboardingExperinceForm = (onboardingdata:any) => {
    let isValid = true;

    // Validate answers for all questions
    const newErrors: Record<number, boolean> = {};
    questions.forEach((question) => {
      if (!onboardingdata.answers[question.QuestionNum]) {
        newErrors[question.QuestionNum] = true;
        isValid = false;
      }
    });
    setOnboardingErrors(newErrors);

    // Validate feedback
    if (!onboardingdata.feedback.trim()) {
      setFeedbackError(true);
      isValid = false;
    }
    else{
      setFeedbackError(false);
    }

    return isValid;
  };

  const handleSaveChanges = async (formData: any) => {
    if (selectedButton === 'personal-details') {
      if (candidateNum && candidateJobNum) {
        formData.CandidateNum = candidateNum;
        formData.JobNum=candidateJobNum
      }

      if (validateForm(formData)) {
        try {
          const response = await axios.post(`${APIBaseURL.apiBaseUrl}/job/candidate_personalinfo`, { formData }, {
            headers: {
              'X-Tenant': tenant,
              'X-App': baseURL,
            }
          })
          if (response.status === 200) {
            navigate(`/${tenant}/CandidateDetails/CandidatePreboarding/documents`)
          }
        } catch (error) {
          console.log(error)
        }
      }
    }

    if(selectedButton==='documents' && updateDocumentStatus.length>0){
      handleSaveChangesDocuments(updateDocumentStatus)
    }

    if(selectedButton==='onboarding-experience' && onboardingData){
      if(validateOnboardingExperinceForm(onboardingData)&&personalInfoNum){
        onboardingData.PersonalInfoNum=personalInfoNum
        onboardingData.CandidateNum=candidateNum
        onboardingData.JobNum=candidateJobNum
        handleSaveChangesOnboarding(onboardingData)
      }
      
    }
  }

  const handleSaveChangesDocuments = async (DocumentData: any) => {

    if (!token) {
      navigate(`/${tenant}/SignIn`)
      return
    }
    try {
      const response = await axios.post(`${APIBaseURL.apiBaseUrl}/job/update_candidate_documents_status`, { DocumentData }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Tenant': tenant,
          'X-App': baseURL,
        }
      })
      if(response.status===200){
        const uploadedDate = Math.floor(new Date().getTime() / 1000);
        setDocuments((prevDocuments: any) =>
          prevDocuments.map((doc: any) => {
            const updatedDoc = DocumentData.find(
              (updated: any) => updated.DocumentNum === doc.DocumentNum
            );
            if (updatedDoc) {
              return {
                ...doc,
                VerifiedStatus: updatedDoc.VerifiedStatus,
                VerificationDate: uploadedDate, // Format: YYYY-MM-DD
              };
            }
            return doc;
          })
        );
      }
    } catch (error) {
      console.log(error)
    }
  }

  

  const handleSaveChangesOnboarding=async(feedback:any)=>{
    try{
      const response=await axios.post(`${APIBaseURL.apiBaseUrl}/job/save_onboarding_feedback`,{feedback},{
        headers:{
          'X-Tenant': tenant,
              'X-App': baseURL,
        }
      })
    }catch(error){
      console.log(error)
    }
  }

  const handleSubmit = async() => {
    try{
      const response= await axios.post(`${APIBaseURL.apiBaseUrl}/job/save_final_submit`,{
        "Submitted":true,"CandidateNum":candidateNum,"JobNum":candidateJobNum
      },{headers:{
        'X-Tenant': tenant,
        'X-App': baseURL,
      }})
      if(response.status===200){
        navigate(`/${tenant}/ThankYou`)
        setIsSubmitted(true);
        localStorage.removeItem('candidate_access_token')
      }
    }catch(error){
      console.log(error)
    }
  };
  return (
    <Box sx={{ width: '100%', position: 'relative', top: '1.389vw', m: '0 0.22vw 0 1vw' }}>
      <Box display="flex" justifyContent="space-between" gap={5} alignItems="center" sx={{ mb: 1.6 }}>
        <Typography variant="h5">Candidate Preboarding</Typography>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <ButtonGroup size="small" variant="outlined" aria-label="button group" sx={{ width: '40vw' }}>
          <StyledButton
            variant="outlined"
            onClick={() => handleNavigation(`/${tenant}/CandidateDetails/CandidatePreboarding/your-offer`, 'your-offer')}
            sx={{ mr: 0.13, flexGrow: 1 }}
            isselected={selectedButton === 'your-offer'}
          >
            Your Offer
          </StyledButton>

          <StyledButton
            variant="outlined"
            onClick={() => handleNavigation(`/${tenant}/CandidateDetails/CandidatePreboarding/personal-details`, 'personal-details')}
            sx={{ mr: 0.13, flexGrow: 1 }}
            isselected={selectedButton === 'personal-details'}
            disabled={!offerAccepted || offerDeclined} // Disable if offer not accepted or declined
          >
            Personal Details
          </StyledButton>

          <StyledButton
            variant="outlined"
            onClick={() => handleNavigation(`/${tenant}/CandidateDetails/CandidatePreboarding/documents`, 'documents')}
            sx={{ mr: 0.13, flexGrow: 1 }}
            isselected={selectedButton === 'documents'}
            disabled={!offerAccepted || offerDeclined} // Disable if offer not accepted or declined
          >
            Documents
          </StyledButton>

          <StyledButton
            variant="outlined"
            onClick={() => handleNavigation(`/${tenant}/CandidateDetails/CandidatePreboarding/onboarding-experience`, 'onboarding-experience')}
            sx={{ flexGrow: 1 }}
            isselected={selectedButton === 'onboarding-experience'}
            disabled={!offerAccepted || offerDeclined} // Disable if offer not accepted or declined
          >
            Onboarding Experience
          </StyledButton>
        </ButtonGroup>

        <Box 
  sx={{
    width: '17vw', 
    display: 'flex',  // Ensure the flex container is set
    justifyContent: 'flex-end',  // Align items to the right
    gap: '0.556vw',  // Space between buttons
  }}
>
  {selectedButton !== 'your-offer' && (
    <>
    {(selectedButton==='personal-details' && isSubmitted ) ||(selectedButton==='documents' && userRole===null) ||(selectedButton==='onboarding-experience' && isSubmitted)?(<></>):(<Button 
        variant="outlined" 
        sx={{ color: 'primary.main', borderColor: 'primary.main', gap: '0.4448vw' }} 
        size="small" 
        onClick={()=>{handleSaveChanges(parentFormData)}} // Log the data when Save Changes is clicked
      >
        <i className="fa-regular fa-floppy-disk fa-lg"></i> Save Changes
      </Button>)}

      {/* <Button 
        variant="outlined" 
        sx={{ color: 'primary.main', borderColor: 'primary.main', gap: '0.4448vw' }} 
        size="small" 
        onClick={()=>{handleSaveChanges(parentFormData)}} // Log the data when Save Changes is clicked
      >
        <i className="fa-regular fa-floppy-disk fa-lg"></i> Save Changes
      </Button> */}

      {(selectedButton === 'onboarding-experience' && !isSubmitted) && (
        <Button 
          variant="contained" 
          color="primary" 
          size="small" 
          sx={{ ml: '0.556vw' }} 
          onClick={handleSubmit}
        >
          Submit
        </Button>
      )}
    </>
  )}
</Box>

      </Box>

      {/* Render child routes using Outlet */}
      <Outlet context={{ errors, setParentFormData, personalInfoNum, documents, setDocuments, setUpdateDocumentStatus ,emailBody,candidateOfferletterData,candidatePersonalInfo,setOnboardingData,candidateNum,candidateJobNum,userRole,onboardingErrors,feedbackError,questions,setQuestions,savedOnboardingResponses,savedFeedbackData,isSubmitted}} />
    </Box>
  );
};

export default CandidatePreboarding;
