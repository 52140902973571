import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

const UserTableHead: React.FC = (props: any) => {
  const { onSelectAllClick, numSelected, rowCount } = props;

  const headCells = [
    {
      id: 'profilePicture',
      disablePadding: true,
      label: 'Profile Picture',
    },
    {
      id: 'Username',
      disablePadding: false,
      label: 'Username',
    },
    {
      id: 'emailAddress',
      disablePadding: false,
      label: 'Email address',
    },
    {
      id: 'role',
      disablePadding: false,
      label: 'Role',
    },
    {
      id: 'status',
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'updatedBy',
      disablePadding: false,
      label: 'Updated By',
    },
    {
      id: 'actions',
      disablePadding: false,
      label: 'Actions',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all users',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >{headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default UserTableHead;