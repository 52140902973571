import axios from 'axios'
import React, { useEffect, useState } from 'react'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useParams } from 'react-router-dom';
interface imageUrlDatatype{
    imageUrl:string
}
const AvatarImage:React.FC<imageUrlDatatype> = ({imageUrl}) => {
    const [imgSrc,setImgSrc]=useState('')
    const { tenant } = useParams<{ tenant: string }>();
    const baseURL = window.location.origin.endsWith('/')
            ? window.location.origin
            : `${window.location.origin}/`;

    useEffect(() => {
        const fetchImage = async () => {
            if (!imageUrl) return; 
            try {
                const response = await axios.get(imageUrl, {
                    headers:{
                        'X-Tenant': tenant,
                        'X-App': baseURL
                    },
                    responseType: 'blob'  
                });
                const blob = await response.data
                const imageObjectURL = URL.createObjectURL(blob);
                setImgSrc(imageObjectURL);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        fetchImage();
        
    }, [imageUrl]);

  return imgSrc ? <img style={{height:"40",width:"40px",borderRadius:"50px",marginRight:"10px"}} src={imgSrc} alt="Authenticated" /> : <AccountCircleOutlinedIcon fontSize='large'/>;
}

export default AvatarImage