import React,{useState} from 'react';
import {
    Box,
    Button,
    InputAdornment,
    TextField,
    useTheme,
} from '@mui/material';
import JobsHead from './JobsHead';
import { StyledHeadWrapper } from './JobsSearchPane.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSliders } from '@fortawesome/free-solid-svg-icons';
import JobsResult from './JobsResult';
import JobsSearchBar from './JobsSearchBar';

const JobsSearchPane: React.FC = (props) => {
    const theme = useTheme();
    const [filters, setFilters] = useState<string[]>([]); 
    const [searchTerm,setSearchTerm]=useState<string>('')

    const handleSearch = (term: string) => {
        setSearchTerm(term);
    };
    return (
        <StyledHeadWrapper>
            <JobsHead></JobsHead>
            <JobsSearchBar filters={filters} setFilters={setFilters} onSearch={handleSearch}></JobsSearchBar>
            <JobsResult filters={filters} jobTitleSearch={searchTerm}></JobsResult>
        </StyledHeadWrapper>
    );
};

export default JobsSearchPane;
