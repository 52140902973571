import {css,styled} from '@mui/material/styles'

export const StyledWrapper= styled('div',{},)(({theme})=>css`
    background-color:aliceblue;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    height:100vh;
    
    .changepassword-container{
        background-color:${theme.palette.custom.white};
        padding:20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;

         .logo-container{
            display:flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            h2{
            font-size:20px;
            }
        }
        .changepassword-form{
            display:flex;
            flex-direction:column;
            gap:20px;
            

            .changepassword-label-input{
                display:flex;
                flex-direction:column;

            label{
                font-size: 15px;
                color: gray;
            }
            
            input{
                width: 250px;
                padding: 7px;
                border-radius: 8px;
                border: 0.5px solid gray;
            }
        }
        Button{
                color:white;
                font-size: 15px;
                padding: 5px;
                border-radius: 7px;
                border: none;
                background-color: #3795BD;
        }
        
    }
    }
`)