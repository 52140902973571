import React from "react";
import CandidateContent from "../CandidateContent/CandidateContent";
import { Box } from "@mui/material";

const CandidateProfile: React.FC = () => {
    const handleNameClick = (id: string) => {
        console.log(`Name clicked for Candidate ID: ${id}`);
        // Handle name click (e.g., open a dialog with candidate details)
    };

    const handlePositionClick = (id: string) => {
        console.log(`Position clicked for Candidate ID: ${id}`);
        // Handle position click (e.g., show position details)
    };

    const handleFunctionClick = (id: string) => {
        console.log(`Function clicked for Candidate ID: ${id}`);
        // Handle function click (e.g., show function details)
    };

    const handleNotesClick = (id: string) => {
        console.log(`Notes clicked for Candidate ID: ${id}`);
        // Handle notes click (e.g., open notes dialog)
    };

    const handleStatusClick = (id: string) => {
        console.log(`Status clicked for Candidate ID: ${id}`);
        // Handle status click (e.g., show status details)
    };

    return (
        <Box sx={{'& .MuiBox-root': { padding: 0 },width:'100%' }}>
            
            <CandidateContent
            
           
             
    
            />
        </Box>
    );
};

export default CandidateProfile;
