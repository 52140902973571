import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, InputAdornment, TextField, Button } from '@mui/material';
import { TableWrapper, TableRowStyled } from './UserListTable.styles'; // Imported styles
import profilePicture from '../../../../../Assets/AuthBackGround/profile.avif';
import axios from 'axios';
import { useNavigate,useParams } from 'react-router-dom';
import APIBaseURL from '../../../../../AppConfig/AppConfig.json'
import AvatarImage from '../../../../Navbar/AvatarImage';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Pagination from '../../../../Common/Pagination/pagination';
import { theme } from '../../../../../theme';

interface userDataType {
  UserNum: number;
  UserName: string;
  EmailID: string;
  UserID: string;
  DeleteItem: boolean;
  UpdatedBy: string;
  UserRole: string;
  UserStatus: string;
  photoURL?: string
}


const sampleUsers = Array.from({ length: 30 }, (_, index) => ({
  id: index + 1,
  userName: `User ${index + 1}`,
  email: `user${index + 1}@gmail.com`,
  profilePic: profilePicture, // Using a static image for demonstration
}));

const UserList: React.FC = () => {
  const [page, setPage] = useState(1); // Adjusted to start at page 1
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]); // Store selected user IDs
  const [isSelectAll, setIsSelectAll] = useState(false); // Control Select All checkbox
  const [searchQuery, setSearchQuery] = useState(''); // State to track search input
  const [userData, setUserData] = useState<userDataType[]>([])
  const { tenant } = useParams<{ tenant: string }>();
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const token = localStorage.getItem('access_token')
  const navigate=useNavigate()

  useEffect(() => {
    if (!token){
      navigate(`/${tenant}/SignIn`)
      return
    }
    const fetchData = async () => {
      try {
        const response = await axios.get(`${APIBaseURL.apiBaseUrl}/user/get_users`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Tenant': tenant,
            'X-App': baseURL
          }
        })
        if (response.status === 200) {
          let users: userDataType[] = response.data
          setUserData(users.filter((user) => !user.DeleteItem))
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setRowsPerPage(rows);
    setPage(1); // Reset to first page when rows per page changes
  };

  // Checkbox selection handler for individual rows
  const handleCheckboxChange = (id: number) => {
    setSelectedUsers((prev) =>
      prev.includes(id) ? prev.filter((userId) => userId !== id) : [...prev, id]
    );
  };

  // Handle Select All checkbox
  const handleSelectAll = () => {
    if (isSelectAll) {
      setSelectedUsers([]); // Deselect all
    } else {
      const newSelectedUsers = userData.slice((page-1 )* rowsPerPage, page * rowsPerPage).map(user => user.UserNum);
      setSelectedUsers(newSelectedUsers); // Select all visible users
    }
    setIsSelectAll(!isSelectAll);
  };

  // Check if all current page rows are selected

  // Filter users based on search input (userName, email, or id)
  const filteredUsers = userData.filter(user =>
    user.UserName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.EmailID.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.UserID.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage); // Total pages logic from code 2

  return (
    <Box mt={4} sx={{ maxWidth: '100%', margin: '5vh 0', boxSizing: 'border-box' }}>
      {/* Search Box and Send Message Button */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1.1111vw' }}>
        <TextField
          size="small"
          label="Search by User ID, Username, or Email"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ width: '35vw' }} // Set width for search box in vw
          autoComplete='off'
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box sx={{ position: 'relative', right: '-0.3vw' }}>
                  <i className="fa-solid fa-magnifying-glass"></i>
                </Box>
              </InputAdornment>
            ),
          }}
        />
        <Button size='small' sx={{ p: '0 2vw' }} variant="contained">
          Send Message
        </Button>
      </Box>

      <TableWrapper sx={{ border: '0.0694vw  solid #E4E7EC', borderRadius: '1vw', boxShadow: 0 }}>
        <TableContainer component={Paper} sx={{ boxShadow: 0, border: '0.0694vw  solid #E4E7EC', borderRadius: '0' }} elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '10vw', textAlign: 'left', p: 0, pl: '3%' }}>
                  <Checkbox
                    size='small'
                    checked={selectedUsers.length > 0 && isSelectAll}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell sx={{ width: '20vw', p: 0, pl: '1.0417vw ' }}> 
                <Typography
                  variant='body3'
                    sx={{
                      color: theme.palette.text.secondary,
                      
                      paddingY: '0.6vw',
                      justifyContent: 'center',
                      whiteSpace: 'nowrap',
                      
                    }}
                  >
                    User ID
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '30vw', p: 0 }}>
                  <Typography
                  variant='body3'
                    sx={{
                      color: theme.palette.text.secondary,
                      
                      paddingY: '0.6vw',
                      justifyContent: 'center',
                      whiteSpace: 'nowrap',
                      
                    }}
                  >
                    User Name
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '40vw', textAlign: 'left', p: 0 }}>
                  <Typography
                  variant='body3'
                    sx={{
                      color: theme.palette.text.secondary,
                      
                      paddingY: '0.6vw',
                      justifyContent: 'center',
                      whiteSpace: 'nowrap',
                      
                    }}
                  >
                    Email
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.slice((page-1) * rowsPerPage, page * rowsPerPage ).map((row, index) => (
                <TableRowStyled key={row.UserNum} isEven={index % 2 === 0} sx={{ width: '100%', justifyContent: 'space-between' }}>
                  <TableCell sx={{ padding: '0.5vw 0', width: '10vw', textAlign: 'center' }}>
                    <Checkbox
                      checked={selectedUsers.includes(row.UserNum)}
                      onChange={() => handleCheckboxChange(row.UserNum)}
                    />
                  </TableCell>
                  <TableCell sx={{ width: '20%', padding: '5px 0px', textAlign: 'left', pl: '15px' }}>
                      {row.UserID}
                  </TableCell>
                  <TableCell sx={{ display: 'flex', gap: '5px', alignItems: 'center', 
                    width: '30%',
                     padding: '5px 0px', textAlign: 'left' }}>
                    {row.UserNum ? (
                      <AvatarImage imageUrl={`${APIBaseURL.apiBaseUrl}/user/get_user_image/${row.UserNum}`} />
                    ) : (
                      <AccountCircleOutlinedIcon fontSize='large' />
                    )}
                    <Typography variant="body2" color="textPrimary">
                      {row.UserName}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: '0.5vw 0', width: '45vw', textAlign: 'left' }}>
                    <Typography variant="body2" color="textPrimary">
                      {row.EmailID}
                    </Typography>
                  </TableCell>
                </TableRowStyled>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination component */}
        <Box sx={{ mt: '0.83vw', display: 'flex', justifyContent: 'center', borderRadius: '1vw', pb: '0.56vw' }}>
          <Pagination
            totalRows={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            currentPage={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Box>
      </TableWrapper>
    </Box>
  );
};

export default UserList;
