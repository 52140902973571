import { styled } from '@mui/material/styles';
import { Box, TableRow, Button } from '@mui/material';

// TableWrapper to style the table container
export const TableWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: theme.shadows[1],
}));

// TableRowStyled to differentiate between odd/even rows
export const TableRowStyled = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'isEven',
})<{ isEven: boolean }>(({ isEven, theme }) => ({
  backgroundColor: isEven ? theme.palette.primary.light : '#ffffff',
  '&:hover': {
    backgroundColor: '#F9F5FF'
  },
}));

// Styled PaginationButton used in the pagination controls
export const PaginationButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  minWidth: '30px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));
