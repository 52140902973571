import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#3AAFA9',
            light: '#2B7A77',
        },
        secondary: {
            main: '#013243',
            light: '#2a7673',
        },
        success: {
            main: '#119306',
        },
        error: {
            main: '#F14949',
        },
        warning: {
            main: '#EB9500',
        },
        info: {
            main: '#4069F5',
        },
        background: {
            default: '#FFFFFF',
            paper: '#F5F5F5',
        },
        text: {
            primary: '#121315',
            secondary: '#808080',
        },
        divider: '#E3E8EB',
        action: {
            disabledBackground: '#E1E1E1',
        },
        custom: {
            border: '#E3E8EB',
            white: '#ffffff',
            searchBg: '#244151',
            infoBg: '#E0F6FF',
            hrCardPrimary: '#9747FF',
            hrCardBg: '#F4EBFF',
            calendar1: '#FFEAFA',
            calendar2: '#E2F7FF',
            calendar3: '#EFE0FF',
            whiteBg: '#EBFFFE',
            lightWhiteBg: '#FAFAFA',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: 'Inter',
                    fontSize: '12px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter',
                },
            },
        },
    },
    typography: {
        button: {
            textTransform: 'none',
            fontFamily: 'Inter',
        },
    },
});
