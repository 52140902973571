import React, { useEffect } from 'react';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Paper, Checkbox, Stack, Alert, Avatar, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UserTableHead from './UserTableHead';
import UserTableToolbar from './UserTableToolbar';
import AvatarImage from './AvatarImage';
import APIBaseUrl from '../../../AppConfig/AppConfig.json'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
interface UserTableProps {
    rows: any[];  // Define a more specific type if possible
    sendDeleteIdToParent: (value: number) => void;
    sendEditIdToParent: (value: number) => void;
    selectedId: (selectedIds: number[]) => void;

}
const UserTable: React.FC<UserTableProps> = (props: any) => {
    const [selected, setSelected] = React.useState<any>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [manualPageNo, setManualPageNo] = React.useState(0);
    const [pageNo, setPageNo] = React.useState(1);

    const { rows } = props;

    // const filteredRows = rows.filter(row => !row.DeleteItem);


    const handleSelectAllClick = (event: { target: { checked: any } }) => {
        if (event.target.checked) {
            const newSelected: any = rows.map((n: any) => n.UserNum);
            setSelected(newSelected);
            props.selectedId(newSelected)
            return;
        }
        setSelected([]);
        props.selectedId([])
    };

    const handleClick = (id: any) => {
        const selectedIndex: any = selected.indexOf(id);
        let newSelected: any = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        props.selectedId(newSelected)
    };

    const handleEdit = (id: number) => {
        props.sendEditIdToParent(id);
    };

    const handleDelete = (id: number) => {
        props.sendDeleteIdToParent(id);
    };

    const handleChangePage = (
        _event: any,
        newPage: any,
    ) => {
        setPage(newPage);
        setPageNo(newPage + 1)
    };

    const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setPageNo(1)
    };

    const padValue = (value: number): string => {
        return value.toString().padStart(2, '0');
    };

    const handleDate = (dateVal: string): string => {
        // Convert epoch time to milliseconds and create a new Date object
        const newDate = new Date(parseInt(dateVal) * 1000);

        // Get date components
        const sMonth = newDate.toLocaleString('default', { month: 'short' }); // Abbreviated month name
        const sDay = padValue(newDate.getDate());
        const sYear = newDate.getFullYear();
        let sHour: any = newDate.getHours();
        const sMinute = padValue(newDate.getMinutes());
        let sAMPM = 'AM';

        // Convert 24-hour time to 12-hour time
        if (sHour >= 12) {
            sAMPM = 'PM';
            if (sHour > 12) sHour -= 12;
        } else if (sHour === 0) {
            sHour = 12; // Midnight case
        }

        sHour = padValue(sHour);

        // Return formatted date string
        return `${sDay} ${sMonth} ${sYear} ${sHour}:${sMinute} ${sAMPM}`;
    };


    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleManualPageChange = (event: any) => {
        setPageNo(event.target.value);
        if (!Number.isInteger(parseInt(event.target.value)) || event.target.value < 0) {
            event.target.value = '';
        }
        setManualPageNo(event.target.value);
    }

    const handleManualPageClick = () => {
        let totalPages = Math.ceil(rows.length / rowsPerPage);
        let typedPageNo = manualPageNo;
        if (totalPages < typedPageNo || !typedPageNo) {
            return
        }
        setPage(manualPageNo - 1)
    }

    useEffect(() => {
        setPage(0);
    }, [rows]);


    return (
        <Box sx={{ width: '100%' }}>
            <Paper
                className="user-table"
                sx={{ width: '100%', mb: 2, background: 'none' }}
            >
                <UserTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size="medium"
                    >
                        <UserTableHead
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={rows.length}
                        />
                        {rows.length >= 1 && (
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? rows.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage,
                                    )
                                    : rows
                                ).map((row: any, index: any) => {
                                    const isItemSelected = isSelected(row.UserNum);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.UserNum}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    onClick={(event) =>
                                                        handleClick(row.UserNum)
                                                    }
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="td"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.UserNum ? (
                                                    <AvatarImage imageUrl={`${APIBaseUrl.apiBaseUrl}/user/get_user_image/${row.UserNum}`} />
                                                ) : (
                                                    <AccountCircleOutlinedIcon fontSize="large" />
                                                )}

                                            </TableCell>
                                            <TableCell align="left">
                                                {row.UserName}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.EmailID}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.UserRole}
                                            </TableCell>
                                            <TableCell align="left">
                                                <div
                                                    className={
                                                        'user-status ' +
                                                        (row.UserStatus == 'Active'
                                                            ? 'active-color'
                                                            : 'inactive-color')
                                                    }
                                                >
                                                    {row.UserStatus}
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className="update-by"
                                            >
                                                <div>{row.UpdatedBy}</div>
                                                <div>
                                                    {handleDate(row.UpdatedDate)}
                                                </div>
                                            </TableCell>
                                            <TableCell align="left">
                                                <IconButton type="button" onClick={(event) => handleEdit(row.UserNum)} className="edit-btn" >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton type="button" onClick={(event) => handleDelete(row.UserNum)} className="delete-btn" >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                {emptyRows > 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        )}
                        {rows.length === 0 && (
                            <TableBody>
                                <TableRow className=''>
                                    <TableCell colSpan={8} align='center'>
                                        No Rows Found
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <Box className="pagination-wrapper">
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={3}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={!rows.length || rows.length <= 0 ? 0 : page}
                        onPageChange={handleChangePage}
                        labelRowsPerPage={"Results per page"}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '4px',
                    }}>
                        <p className="pagination-text">Page</p>
                        <Box sx={{
                            display: 'flex'
                        }}>
                            <TextField
                                className="pagination-textbox"
                                id="outlined-controlled"
                                type="number"
                                variant="standard"
                                InputProps={{
                                    inputProps: { min: 0 }
                                }}
                                sx={{
                                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                                        WebkitAppearance: "none",
                                        margin: 0,
                                    },
                                    "input[type=number]": {
                                        MozAppearance: "textfield",
                                    },
                                }}
                                value={pageNo}
                                onChange={handleManualPageChange}
                            />
                        </Box>
                        <p onClick={handleManualPageClick} className="pagination-btn">GO</p>
                    </Box>
                </Box>
            </Paper >
        </Box >
    );
}

export default UserTable;