import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Checkbox, IconButton, Typography, Box
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system'; // Import styled utility
import Pagination from '../../../Common/Pagination/pagination'; // Import your custom pagination component
import AddNewUser from './AddNewUser/AddNewUser'; // Import the AddNewUser component
import { AddButton, Root, TableRowStyled, TableWrapper } from './User.styles';
import EditUser from './EditUser/EditUser';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import APIBaseURL from '../../../../AppConfig/AppConfig.json'
import AvatarImage from '../../../Navbar/AvatarImage';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useNavigate } from 'react-router-dom'; import { theme } from '../../../../theme';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

// Styled components for Active and Inactive statuses
const ActiveStatus = styled('span')(({ theme }) => ({
  color: '#067647',
  border: '0.0694vw  solid #47CD89',
  background: '#ECFDF3',
  padding: '0.2778vw 0.97vw',
  borderRadius: '69.44vw ',
  fontWeight: 500,
}));

const InactiveStatus = styled('span')(({ theme }) => ({
  color: '#B54708',
  border: '0.0694vw  solid #FECDCA',
  background: '#FEF3F2',
  padding: '0.2778vw 0.83vw',
  borderRadius: '69.44vw ',
  fontWeight: 500,
}));
interface userData {
  UserNum: number;
  UserName: string;
  EmailID: string;
  UserID: string;
  DeleteItem: boolean;
  UpdatedBy: string;
  UserRole: string;
  UserStatus: string;
  photoURL?: string
}
const User: React.FC = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page
  const [selected, setSelected] = useState<string[]>([]); // Array to track selected users
  const [selectAll, setSelectAll] = useState(false); // State to track if the header checkbox is selected
  const [openAddUser, setOpenAddUser] = useState(false); // State to manage AddNewUser component visibility
  const [users, setUsers] = useState<userData[]>([]); // Default users list
  const [openEditUser, setOpenEditUser] = useState(false); // State for EditUser dialog
  const [currentUser, setCurrentUser] = useState<any>(null); // State for the user being edited
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // State for delete confirmation dialog
  const [userNumToDelete, setUserNumToDelete] = useState<number | null>(null); // Store the ID of the user to delete
  const [filteredUserData, setFilteredUserData] = useState<userData[]>([])
  const navigate = useNavigate()
  const { tenant } = useParams<{ tenant: string }>();
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const token = localStorage.getItem('access_token')
  const handleEditUser = (usernum: number) => {
    const userToEdit = filteredUserData.find(user => user.UserNum === usernum);
    if (userToEdit) {
      setCurrentUser(userToEdit); // Set the current user to be edited
      setOpenEditUser(true); // Open the EditUser dialog
    }
  };

  const cancelDeleteUser = () => {
    setUserNumToDelete(null)
    setDeleteDialogOpen(false)
  }

  const confirmDeleteUser = async () => {
    if (userNumToDelete !== null) {
      console.log(userNumToDelete)
      if (!token) {
        navigate(`/${tenant}/SignIn`)
        return
      }
      else {
        try {
          const response = await axios.post(`${APIBaseURL.apiBaseUrl}/user/delete_user`, { "UserNum": userNumToDelete }, {
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Tenant': tenant,
              'X-App': baseURL
            }
          })
          if(response.status===200){
            setUsers((prevData)=>prevData.filter((user)=>user.UserNum!==userNumToDelete))
            setFilteredUserData((prevData)=>prevData.filter((user)=>user.UserNum!==userNumToDelete))
          }
        } catch (error) {
          console.log(error)
        }
        setUserNumToDelete(null)
        setDeleteDialogOpen(false)
      }
    }
  }

  const fetchData = async () => {
    if (!token) {
      navigate(`/${tenant}/SignIn`)
      return
    }
    else {
      try {
        const response = await axios.get(`${APIBaseURL.apiBaseUrl}/user/get_users`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Tenant': tenant,
            'X-App': baseURL
          }
        })
        if (response.status === 200) {
          setUsers(Array.isArray(response.data) ? response.data : []);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setFilteredUserData(users.filter((user) => !user.DeleteItem) || []);
  }, [users]);

  const handleUpdateUser = async (updatedUser: any) => {
    if (!token) {
      navigate(`/${tenant}/SignIn`)
      return
    }
    // Find the existing user in filteredUserData
    const existingUser = filteredUserData.find(user => user.UserNum === updatedUser.usernum);

    // Check for changes
    const hasChanges = existingUser && (
      existingUser.UserName !== updatedUser.username ||
      existingUser.EmailID !== updatedUser.email ||
      existingUser.UserRole !== updatedUser.role ||
      existingUser.UserStatus !== updatedUser.status ||
      (existingUser.photoURL || "") !== (updatedUser.photoURL || "")// Assuming this holds the URL of the user's photo
    );

    if (!hasChanges) {
      // No changes detected, just close the dialog
      setOpenEditUser(false);
      return; // Exit the function early
    }
    else {
      try {
        await axios.post(`${APIBaseURL.apiBaseUrl}/user/edit_user`, {
          "UserName": updatedUser.username, "EmailID": updatedUser.email, "RoleKey": updatedUser.role, "UserNum": updatedUser.usernum, "StatusKey": updatedUser.status
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Tenant': tenant,
            'X-App': baseURL
          }
        })
        if (updatedUser.photo) {
          const formData = new FormData()
          formData.append('UserNum', updatedUser.usernum)
          formData.append('Image', updatedUser.photo)
          await axios.post(`${APIBaseURL.apiBaseUrl}/user/edit_user_image`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
              'X-Tenant': tenant,
              'X-App': baseURL
            }
          })
        }
        setUsers(prevFilteredUsers => {
          return prevFilteredUsers.map(user => {
            if (user.UserNum === updatedUser.usernum) {

              return {
                ...user,
                UserName: updatedUser.username,
                EmailID: updatedUser.email,
                UserRole: updatedUser.role,
                UserStatus: updatedUser.status,
                // Update photoURL to refresh the image
                photoURL: updatedUser.photoURL

              };
            }
            return user;
          });
        });
        
        setFilteredUserData(prevFilteredUsers => {
          return prevFilteredUsers.map(user => {
            if (user.UserNum === updatedUser.usernum) {

              return {
                ...user,
                UserName: updatedUser.username,
                EmailID: updatedUser.email,
                UserRole: updatedUser.role,
                UserStatus: updatedUser.status,
                // Update photoURL to refresh the image
                photoURL: updatedUser.photoURL

              };
            }
            return user;
          });
        });
        setOpenEditUser(false); // Close the dialog
        setCurrentUser(null);
      } catch (error) {
        console.log(error)
      }
      // Clear current user
    }
  };

  // Handle individual row selection
  const handleSelect = (username: string) => {
    const newSelected = selected.includes(username)
      ? selected.filter(name => name !== username)
      : [...selected, username];
    setSelected(newSelected);
  };

  // Handle header checkbox selection
  const handleSelectAll = () => {
    if (selectAll) {
      setSelected([]); // Clear all selections
    } else {
      const allUsernames = filteredUserData.map(user => user.UserName);
      setSelected(allUsernames); // Select all users
    }
    setSelectAll(!selectAll);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1); // Reset to the first page when rows per page changes
  };

  // Function to handle opening and closing of AddNewUser component
  const toggleAddUser = () => {
    setOpenAddUser(prev => !prev);
  };

  // Add new user from the AddNewUser dialog
  const handleAddUser = async (newUser: any) => {

    if (!token) {
      navigate(`/${tenant}/SignIn`)
    }
    else {
      try {
        const response = await axios.post(`${APIBaseURL.apiBaseUrl}/user/add_user`, {
          "UserName": newUser.username, "EmailID": newUser.email, "UserRole": newUser.role, "UPassword": newUser.password, "UserID": newUser.userid, "UserStatus": newUser.status
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Tenant': tenant,
            'X-App': baseURL
          }
        })
        if (response.status === 201) {
          const usernum = response.data.UserNum
          const updated_by_name=response.data.UpdatedByName
          if (newUser.photo) {
            const formData = new FormData()
            formData.append('UserNum', usernum)

            const response = await fetch(newUser.photo);
            const blob = await response.blob();
            formData.append('Image', newUser.photo);
            const imageResponse = await axios.post(`${APIBaseURL.apiBaseUrl}/user/add_user_image`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
                'X-Tenant': tenant,
                'X-App': baseURL
              }
            });
          }
          setUsers((prevUsers) => [
            ...prevUsers.map((user) => ({
              ...user, // Ensures existing users retain their full properties
            })),
            {
              UserNum: usernum,
              UserName: newUser.username,
              EmailID: newUser.email,
              UserRole: newUser.role,
              UserStatus: newUser.status,
              UserID: newUser.userid, // Ensure UserID is included
              photoURL: newUser.photoURL || '', // Optionally include photoURL
              DeleteItem: false, // or other default value
              UpdatedBy: updated_by_name, // or other default value
            },
          ]);
          setFilteredUserData((prevFilteredUsers) => [
            ...prevFilteredUsers.map((user) => ({
              ...user, // Ensures existing filtered users retain their full properties
            })),
            {
              UserNum: usernum,
              UserName: newUser.username,
              EmailID: newUser.email,
              UserRole: newUser.role,
              UserStatus: newUser.status,
              UserID: newUser.userid, // Ensure UserID is included
              photoURL: newUser.photoURL || '', // Optionally include photoURL
              DeleteItem: false, // or other default value
              UpdatedBy: updated_by_name, // or other default value
            },
          ]);
        }
      } catch (error) {
        console.log(error)
      }
    }
  };



  const handleDeleteUser = (userNum: number) => {
    setUserNumToDelete(userNum)
    setDeleteDialogOpen(true)
  }

  return (
    <Box sx={{width: '100%',}}>

      {/* Flex Container for Heading and Button */}
      <Box
  sx={{
    
    display: 'flex',
    flexDirection: 'column', // Align content in a column
    justifyContent: 'flex-start', // Ensure content aligns at the top
    minHeight: '100vh', // Ensure full height of the viewport
    pl: '1vw ',
    pr:'0.11vw', // Add padding for some spacing
    boxSizing: 'border-box', // Include padding and border in width and height calculations
  }}
>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box mb={2}>
            <Typography variant="h5">
              Manage Users
            </Typography>
            <Typography variant="subtitle1" mb={0.5} >
              Set user roles from a single place to manage recruitment activities seamlessly.
            </Typography>
          </Box>
          <AddButton variant="contained" onClick={toggleAddUser}>
            Add New Users
          </AddButton>
        </Box>

        {/* Conditionally render the AddNewUser component */}
        {openAddUser && <AddNewUser open={openAddUser} onClose={toggleAddUser} onSubmit={handleAddUser} existingUserIds={filteredUserData.map(user => user.UserID)} />}

        {/* Rendering EditUser component */}
        {openEditUser && currentUser && (
          <EditUser
            open={openEditUser}
            onClose={() => setOpenEditUser(false)}
            onSubmit={handleUpdateUser}
            userData={currentUser} // Pass the selected user data
          />
        )}

        <TableWrapper sx={{borderRadius:'0.9vw', tableLayout: 'auto'}}>
          <Box>
            <Table>
              <TableHead>
                <TableRow sx={{ padding: 0 }}>
                  <TableCell padding="checkbox" sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Checkbox checked={selectAll} onChange={handleSelectAll} />
                  </TableCell>
                  <TableCell
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: '12px',
                      padding: '8px 24px',
                      justifyContent: 'center',
                      whiteSpace: 'nowrap',
                      textAlign: 'start',
                      fontWeight: '300',
                    }}
                  >
                    Username
                  </TableCell>
                  <TableCell
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: '12px',
                      padding: '8px 24px',
                      justifyContent: 'center',
                      whiteSpace: 'nowrap',
                      textAlign: 'start',
                      fontWeight: '300',
                    }}
                  >
                    Email address
                  </TableCell>
                  <TableCell
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: '12px',
                      padding: '8px 24px',
                      justifyContent: 'center',
                      whiteSpace: 'nowrap',
                      textAlign: 'start',
                      fontWeight: '300',
                    }}
                  >
                    Role
                  </TableCell>
                  <TableCell
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: '12px',
                      padding: '8px 24px',
                      justifyContent: 'center',
                      whiteSpace: 'nowrap',
                      textAlign: 'start',
                      fontWeight: '300',
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: '12px',
                      padding: '8px 24px',
                      justifyContent: 'center',
                      whiteSpace: 'nowrap',
                      textAlign: 'start',
                      fontWeight: '300',
                    }}
                  >
                    Updated by
                  </TableCell>
                  <TableCell sx={{
                    color: theme.palette.text.secondary,
                    fontSize: '12px',
                    padding: '8px 24px',
                    justifyContent: 'center',
                    whiteSpace: 'nowrap',
                    textAlign: 'start',
                    fontWeight: '300',
                  }}>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredUserData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} // This should span the number of columns you have in the table (adjust this if needed)
                      sx={{
                        textAlign: 'center', // Horizontally center the text
                        fontWeight: 'bold',
                        padding: '20px', // Adjust padding for a better look
                        verticalAlign: 'middle', // Vertically center the text
                        color: theme.palette.text.secondary, // Optional: Set color for the message text
                      }}>
                      No users data available
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredUserData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((user, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: index % 2 === 0 ? 'primary.light' : 'inherit',
                        height: '70px',
                        padding: 0,
                      }}
                    >
                      <TableCell padding="checkbox" sx={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Checkbox
                          checked={selected.includes(user.UserName)}
                          onChange={() => handleSelect(user.UserName)}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '12px',
                          whiteSpace: 'nowrap',
                          padding: '16px 24px',
                        }}
                      >
                        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                          {user.photoURL ? (
                            <AvatarImage imageUrl={user.photoURL} height="40px" width="40px" />
                          ) : user?.UserNum ? (
                            <AvatarImage imageUrl={`${APIBaseURL.apiBaseUrl}/user/get_user_image/${user.UserNum}`} height="40px" width="40px" />
                          ) : (
                            <AccountCircleOutlinedIcon sx={{ fontSize: '40px' }} />
                          )}
                          <Typography variant="body2" color="textPrimary">
                            {user.UserName}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '12px',
                          whiteSpace: 'nowrap',
                          padding: '16px 24px',
                        }}
                      >
                        <Typography variant="body2" color="textPrimary">
                          {user.EmailID}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '12px',
                          whiteSpace: 'nowrap',
                          padding: '16px 24px',
                        }}
                      >
                        <Typography variant="body2" color="textPrimary">
                          {user.UserRole}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '12px',
                          whiteSpace: 'nowrap',
                          padding: '16px 24px',
                        }}
                      >
                        <Typography variant="body2" color="textPrimary">
                          {user.UserStatus === 'Active' ? (
                            <ActiveStatus>• {user.UserStatus}</ActiveStatus>
                          ) : (
                            <InactiveStatus>• {user.UserStatus}</InactiveStatus>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '12px',
                          whiteSpace: 'nowrap',
                          padding: '16px 24px',
                        }}
                      >
                        <Typography variant="body2" color="textPrimary">
                          {user.UpdatedBy}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>
                        <IconButton size="small" onClick={() => handleEditUser(user.UserNum)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton size="small" onClick={() => handleDeleteUser(user.UserNum)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </Box>
          <Box p={1}>
            <Pagination
              totalRows={filteredUserData.length}  // Adjusted for total rows
              rowsPerPage={rowsPerPage}        // Current rows per page
              currentPage={page}               // Current active page
              onPageChange={handlePageChange}  // Callback for page change
              onRowsPerPageChange={handleRowsPerPageChange} // Callback for rows per page change
            />
          </Box>
        </TableWrapper>
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={cancelDeleteUser}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure you want to delete this user?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting this user will remove them permanently. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDeleteUser} sx={{ color: theme.palette.text.secondary }}>
            Cancel
          </Button>
          <Button onClick={confirmDeleteUser} sx={{bgcolor:theme.palette.error.main}} variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );

};


export default User;
