import React from 'react';
import {  Typography } from '@mui/material';
import { CardContentStyled, CardItem } from './Card.styles';

interface StatusCardProps {
  title: string;
  count: number;
}

const StatusCard: React.FC<StatusCardProps> = ({ title, count }) => {
  return (
    <CardItem sx={{p:1}}>
      <CardContentStyled>
        <Typography variant="body2">{title}</Typography>
        <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '10px' }}>
          {count}
        </Typography>
      </CardContentStyled>
    </CardItem>
  );
};

export default StatusCard;
