import axios from 'axios';
import APIBaseUrl from '../../../AppConfig/AppConfig.json'


export const getUser= async (id:number | undefined,tenant:string | undefined)=>{
    
    const baseURL = window.location.origin.endsWith('/')
            ? window.location.origin
            : `${window.location.origin}/`;
    try{
        const response=await axios.get(`${APIBaseUrl.apiBaseUrl}/user/get_user/${id}`,{
            headers:{
                'X-Tenant': tenant,
                'X-App': baseURL
            }
        })
            return response.data
    }catch(erorr){
        console.log(erorr);
    }
}

export const UserImage=async (id:number | undefined,tenant:string | undefined)=>{
    const baseURL = window.location.origin.endsWith('/')
            ? window.location.origin
            : `${window.location.origin}/`;
    try{
        const response=await axios.get(`${APIBaseUrl.apiBaseUrl}/user/get_user_image/${id}`, {
            responseType: 'blob',  
            headers:{
                'X-Tenant': tenant,
                'X-App': baseURL
            }
        })
        const blob = await response.data
        const imageObjectURL = URL.createObjectURL(blob);
        return imageObjectURL
    }catch(erorr){
        console.log(erorr);
    }
}

