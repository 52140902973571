import { Dialog } from '@mui/material';
import { css, styled } from '@mui/material/styles';

export const StyledDialog = styled(
    Dialog,
    {},
)(
    ({ theme }) => css`
        .MuiPaper-root.MuiDialog-paperWidthSm {
            width: 100%;
            max-width: 973px;
            background-color: #ffffff;

            h2 {
                font-size: 18px;
                font-weight: 600;
                &.MuiDialogTitle-root {
                    padding: 16px 24px 16px;
                }
            }
        }
        .mandatory {
            color: #ff0000;
        }
        #customized-dialog-title p {
            margin: 0;
            font-weight: 400;
            font-size: 12px;
            color: ${theme.palette.text.secondary};
        }
        #customized-dialog-title span {
            font-weight: 400;
            color: ${theme.palette.text.secondary};
        }
        .job-detail-content {
            margin-bottom: 10px;
            .job-details-wrapper {
                border: 1px solid #e3e8eb;
                padding: 20px 15px;
                border-radius: 4px;
                overflow-y: scroll;
                height: 455px;
                /* width */
                ::-webkit-scrollbar {
                    width: 5px;
                }

                /* Track */
                ::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                /* Handle */
                ::-webkit-scrollbar-thumb {
                    background: #d9d9d9;
                }

                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {
                    background: #7c7878;
                }
            }
            .MuiTypography-root {
                margin-bottom: 10px;
                span:first-of-type:not(.MuiChip-label) {
                    width: 130px;
                    min-width: 130px;
                    display: inline-block;
                }
            }
            .job-details-section {
                ul {
                    margin-top: 0px;
                    padding-left: 20px;
                    text-align: left;
                    font-size: 12px;
                    color: #333333;
                }
                p {
                    color: #333333;
                    font-size: 12px;
                    font-family: Inter;
                }
            }
        }
        .MuiStack-root {
            flex-wrap: wrap;
            .MuiChip-root {
                margin: 5px 6px 0 0;
                background: none;
                &:nth-child(3n-1) {
                    color: #26b24f;
                    border: 1px solid #26b24f;
                }
                &:nth-child(3n-2) {
                    color: #598efc;
                    border: 1px solid #598efc;
                }
                &:nth-child(3n-3) {
                    color: #fe5f87;
                    border: 1px solid #fe5f87;
                }
            }
        }
        .file-wrapper {
            padding-left: 20px;
            .upload-section {
                .upload-area {
                    width: 262px;
                    height: 135px;
                    padding: 0 12px;
                    border-radius: 4px;
                    border: 2px dashed #d9d9d9;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }
                .MuiTypography-root {
                    margin-bottom: 0;
                }
            }
        }
    `,
);
