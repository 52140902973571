import React, { useState,useEffect, useCallback } from 'react';
import { Box, Typography, Grid, TextField, Paper, Divider, Button, Switch, FormControlLabel } from '@mui/material';
import { useLocation ,useOutletContext  } from 'react-router-dom';
import SkillsCertifications from './SkillsCertifications';  // Import the SkillsCertifications component
import { theme } from '../../../../../theme';

// Types

interface DrivingLicense {
  licenseNumber: string;
  issueDate: Date | string;
  expiryDate: Date | string;
}

interface Passport {
  nameInPassport: string;
  passportNumber: string;
  issueDate: Date | string;
  expiryDate: Date | string;
  issuePlace: string;
}

interface BankDetails {
  bankName: string;
  accountNumber: string;
  ifsc: string;
}

interface SocialMedia {
  twitter: string;
  facebook: string;
}

interface SkillsData {
  field: string;
  value: string;
}

interface FormData {
  fullName: string;
  knownAs: string;
  fatherName: string;
  motherName: string;
  email: string;
  placeOfBirth: string;
  nationality: string;
  maritalStatus: string;
  weddingDate: Date | string;
  motherTongue: string;
  religion: string;
  speciallyAble: boolean;
  drivingLicense: DrivingLicense;
  passport: Passport;
  permanentAddress: string;
  bankDetails: BankDetails;
  socialMedia: SocialMedia;
  skillsData: SkillsData[]; // Added skills data to formData
}

const PersonalDetails: React.FC= () => {
  const { state } = useLocation();
  const {errors,setParentFormData,candidatePersonalInfo,isSubmitted}=useOutletContext<any>()


  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    knownAs: '',
    fatherName: '',
    motherName: '',
    email: '',
    placeOfBirth: '',
    nationality: '',
    maritalStatus: '',
    weddingDate: '',
    motherTongue: '',
    religion: '',
    speciallyAble: false,
    drivingLicense: {
      licenseNumber: '',
      issueDate: '',
      expiryDate: '',
    },
    passport: {
      nameInPassport: '',
      passportNumber: '',
      issueDate: '',
      expiryDate: '',
      issuePlace: '',
    },
    permanentAddress: '',
    bankDetails: {
      bankName: '',
      accountNumber: '',
      ifsc: '',
    },
    socialMedia: {
      twitter: '',
      facebook: '',
    },
    skillsData: [
      { field: '', value: '' },
    ], // Initial skills data
  });

  // Handle input changes with useCallback to avoid unnecessary re-renders
  const handleChange = useCallback((key: keyof FormData, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  // Handle skills update
  const handleSkillsUpdate = useCallback((updatedSkills: SkillsData[]) => {
    setFormData((prev) => ({
      ...prev,
      skillsData: updatedSkills,
    }));
  }, []);

  useEffect(() => {
    if (candidatePersonalInfo) {
      setFormData((prev) => ({
        ...prev,
        fullName: candidatePersonalInfo.FullName || '',
        knownAs: candidatePersonalInfo.KnownName || '',
        fatherName: candidatePersonalInfo.FatherName || '',
        motherName: candidatePersonalInfo.MotherName || '',
        email: candidatePersonalInfo.Email || '',
        placeOfBirth: candidatePersonalInfo.PlaceOfBirth || '',
        nationality: candidatePersonalInfo.Nationality || '',
        maritalStatus: candidatePersonalInfo.MaritalStatus || '',
        weddingDate: candidatePersonalInfo.WeddingDate || '',
        motherTongue: candidatePersonalInfo.MotherTongue || '',
        religion: candidatePersonalInfo.Religion || '',
        speciallyAble: candidatePersonalInfo.Disablity || false,
        drivingLicense: {
          licenseNumber: candidatePersonalInfo.LicenseNumber || '',
          issueDate: candidatePersonalInfo.LicenseIssueDate ? new Date(candidatePersonalInfo.LicenseIssueDate).toISOString().split('T')[0] : '',
          expiryDate: candidatePersonalInfo.LicenseExpiryDate ? new Date(candidatePersonalInfo.LicenseExpiryDate).toISOString().split('T')[0] : '',
        },
        passport: {
          nameInPassport: candidatePersonalInfo.PassportholderName || '',
          passportNumber: candidatePersonalInfo.PassportNumber || '',
          issueDate: candidatePersonalInfo.PassportIssueDate ? new Date(candidatePersonalInfo.PassportIssueDate).toISOString().split('T')[0] : '',
          expiryDate: candidatePersonalInfo.PassportExpiryDate ? new Date(candidatePersonalInfo.PassportExpiryDate).toISOString().split('T')[0] : '',
          issuePlace: candidatePersonalInfo.CountryIssued || '',
        },
        permanentAddress: candidatePersonalInfo.CandidateAddress || '',
        bankDetails: {
          bankName: candidatePersonalInfo.BankName || '',
          accountNumber: candidatePersonalInfo.AccountNumber || '',
          ifsc: candidatePersonalInfo.IFSCCode || '',
        },
        socialMedia: {
          twitter: candidatePersonalInfo.TwitterLink || '',
          facebook: candidatePersonalInfo.FacebookLink || '',
        },
        skillsData: candidatePersonalInfo.Skills && candidatePersonalInfo.CertificateLinks
        ? candidatePersonalInfo.Skills.split(',').map((skill:string, index:number) => ({
            field: skill.trim(),
            value: candidatePersonalInfo.CertificateLinks.split(',')[index]?.trim() || '',
          }))
        : [{ field: '', value: '' }],
      }));
    }
  }, [candidatePersonalInfo]);
  
  
useEffect(()=>{
setParentFormData(formData)
},[formData])
 
  return (
    <Box sx={{ width: '76vw', p: '0 !important', mt: 4 }}>
      <Grid container spacing={3}>
        {/* Left Content */}
        <Grid item xs={7.5}>
          <Section title="Personal Information">
            <Grid container spacing={3}>
              {['fullName', 'knownAs', 'fatherName', 'motherName', 'email', 'placeOfBirth', 'nationality', 'maritalStatus', 'motherTongue', 'religion'].map((key) => (
                <Grid item xs={6} key={key}>
                  {isSubmitted ? (
                    <LabelField label={key} value={formData[key as keyof FormData]} />
                  ) : (
                    <TextField
                      label={key}
                      fullWidth
                      required
                      type={key === 'email' ? 'email' : 'text'}
                      value={formData[key as keyof FormData]}
                      onChange={(e) => handleChange(key as keyof FormData, e.target.value)}
                      size="small"
                      autoComplete='off'
                      error={Boolean(errors[key as keyof FormData])}
                      helperText={errors[key as keyof FormData]}
                    />
                  )}
                </Grid>
              ))}
              <Grid item xs={6}>
                {isSubmitted && formData.maritalStatus?.toLowerCase() === 'married' ? (
                  <LabelField label="Wedding Date" value={formData.weddingDate?.toString() || ''} />
                ) : (
                  formData.maritalStatus?.toLowerCase() === 'married' && (
                    <TextField
                      label="Wedding Date"
                      type="date"
                      fullWidth
                      required
                      value={formData.weddingDate || ''}
                      onChange={(e) => handleChange('weddingDate', e.target.value)}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      autoComplete="off"
                    />
                  )
                )}
              </Grid>
              <Grid item xs={7}>
                {isSubmitted ? (
                  <LabelField label="Specially Able" value={formData.speciallyAble ? 'Yes' : 'No'} />
                ) : (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.speciallyAble}
                        onChange={(e) => handleChange('speciallyAble', e.target.checked)}
                        color="primary"
                        size="small"
                      />
                    }
                    label="Specially Able"
                    sx={{ paddingLeft: 1 }}
                  />
                )}
              </Grid>
            </Grid>
          </Section>

          <Section title="Driving License">
            <Grid container spacing={3}>
              {Object.keys(formData.drivingLicense).map((key) => (
                <Grid item xs={6} key={key}>
                  {isSubmitted ? (
                    <LabelField
                      label={key}
                      value={formData.drivingLicense?.[key as keyof DrivingLicense]?.toString()}
                    />
                  ) : (
                    <TextField
                      label={key}
                      fullWidth
                      required
                      type={key === 'issueDate' || key === 'expiryDate' ? 'date' : 'text'}
                      value={formData.drivingLicense?.[key as keyof DrivingLicense] || ''}
                      autoComplete='off'
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          drivingLicense: {
                            ...prev.drivingLicense,
                            [key]: e.target.value,
                          },
                        }))
                      }
                      size="small"
                      InputLabelProps={key.includes('Date') || key.includes('issueDate') ? { shrink: true } : undefined}
                      // Ensure no placeholder is used for date fields

                      error={Boolean(errors[`drivingLicense.${key}`])}
                      helperText={errors[`drivingLicense.${key}`]} // Show error message
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </Section>





          <Section title="Passport Information">
            <Grid container spacing={3}>
              {Object.keys(formData.passport).map((key) => (
                <Grid item xs={6} key={key}>
                  {isSubmitted ? (
                    <LabelField label={key} value={formData.passport[key as keyof Passport]?.toString()} />
                  ) : (
                    <TextField
                      label={key}
                      fullWidth
                      required
                      type={key.includes('Date') ? 'date' : 'text'}
                      value={formData.passport[key as keyof Passport]}
                      autoComplete='off'
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          passport: {
                            ...prev.passport,
                            [key]: e.target.value,
                          },
                        }))
                      }
                      size="small"
                      InputLabelProps={key.includes('Date') ? { shrink: true } : undefined}
                      error={Boolean(errors[`passport.${key}`])}
                      helperText={errors[`passport.${key}`]}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </Section>

          <Section title="Address">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {isSubmitted ? (
                  <LabelField label="Permanent Address" value={formData.permanentAddress} />
                ) : (
                  <TextField
                    label="Permanent Address"
                    fullWidth
                    required
                    value={formData.permanentAddress}
                    autoComplete="off"
                    onChange={(e) => handleChange('permanentAddress', e.target.value)}
                    size="small"
                    multiline
                    rows={4}  // Adjust rows as needed
                    error={Boolean(errors.permanentAddress)}
                    helperText={errors.permanentAddress}
                  />
                )}
              </Grid>
            </Grid>
          </Section>

          <Section title="Skills & Certifications">
            <SkillsCertifications rows={formData.skillsData} onSkillsUpdate={handleSkillsUpdate} isSubmitted={isSubmitted} />
          </Section>

          <Section title="Bank Details">
            <Grid container spacing={3}>
              {Object.keys(formData.bankDetails).map((key) => (
                <Grid item xs={6} key={key}>
                  {isSubmitted ? (
                    <LabelField
                      label={key}
                      value={formData.bankDetails[key as keyof BankDetails]}
                    />
                  ) : (
                    <TextField
                      label={key}
                      fullWidth
                      required
                      value={formData.bankDetails[key as keyof BankDetails]}
                      autoComplete="off"
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          bankDetails: {
                            ...prev.bankDetails,
                            [key]: e.target.value,
                          },
                        }))
                      }
                      size="small"
                      error={Boolean(errors[`bankDetails.${key}`])}
                      helperText={errors[`bankDetails.${key}`]}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </Section>


        </Grid>


        {/* Right Content */}
        <Grid item xs={4}>
          <Section title="Social Media">
            <Grid container spacing={3}>
              {Object.keys(formData.socialMedia).map((key) => (
                <Grid item xs={12} key={key}>
                  {isSubmitted ? (
                    <LabelField label={key} value={formData.socialMedia[key as keyof SocialMedia]} />
                  ) : (
                    <TextField
                      label={key}
                      fullWidth
                      required
                      value={formData.socialMedia[key as keyof SocialMedia]}
                      autoComplete='off'
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          socialMedia: {
                            ...prev.socialMedia,
                            [key]: e.target.value,
                          },
                        }))
                      }
                      size="small"
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </Section>
        </Grid>
      </Grid>

    </Box>
  );
};

interface SectionProps {
  title?: string;
  children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ title, children }) => (
  <Box sx={{ mt: '2.222vw', mb: '2.222vw' }}>
    {title && <Typography variant="h6" fontWeight="bold">{title}</Typography>}
    <Divider sx={{ mb: '1.111vw', mt: '0.833vw' }} />
    <Paper variant="outlined" sx={{ p: '1.667vw', borderRadius: '1.111vw' }}>
      {children}
    </Paper>
  </Box>
);

const LabelField: React.FC<{ label: string; value: string | boolean | object }> = ({ label, value }) => {
  const displayValue = typeof value === 'object' ? JSON.stringify(value) : value;

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontWeight: 'bold', width: '7vw', marginRight: '0.556vw' }}>
        {label}:
      </Typography>
      {typeof displayValue === 'string' && displayValue.length > 0 && (
        <Typography variant="body2" sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
          {displayValue}
        </Typography>
      )}
    </Box>
  );
};

export default PersonalDetails;
