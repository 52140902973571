import React, { useEffect,useCallback, useState } from 'react';
import { Box, Button, Grid, Icon, IconButton, Typography, Menu, MenuItem, TextField } from '@mui/material';
import { StyledWrapper } from '../Jobs.styles';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import UserProfileImg from '../../../../assets/images/userprofile/user3.jpg';
// import JobsDetailsPopup from './JobsDetailsPopup';
import { useJobStore, useRecruiterStore, useUserStore } from '../useJobStore';
import AvatarImage from '../../Users/AvatarImage';
import axios from 'axios';
import APIBaseUrl from '../../../../AppConfig/AppConfig.json'
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import 'react-quill/dist/quill.snow.css';

interface Field {
    FieldName: string;
    FieldValue: string;
}

const JobsDetails: React.FC = (props: any) => {
    const { selectedJob } = useJobStore();
    const{ UserData,setUserData } = useUserStore()
    const { RecruiterData,setRecruiterData } = useRecruiterStore()
    
    const [anchorEl, setAnchorEl] = useState(null)
    const [jobFieldData, setJobFieldData] = useState<Field[]>([])

    
    const open = Boolean(anchorEl);
    const { tenant } = useParams<{ tenant: string }>(); 
    const baseURL = window.location.origin.endsWith('/') 
  ? window.location.origin 
  : `${window.location.origin}/`;

    
    
    const fetchJobFieldData = useCallback(async () => {
        try {
            const response = await axios.get(`${APIBaseUrl.apiBaseUrl}/job/get_jobfield/${selectedJob?.JobNum}`,
                {
                    headers: {
                        'X-Tenant': tenant,
                        'X-App': baseURL
                    }
                });
            setJobFieldData(response.data);
        } catch (error) {
            console.error(error);
        }
    }, [selectedJob?.JobNum]);

    const fetchAssignedUsers = useCallback(async () => {
        try {
            const userResponse = await axios.get(`${APIBaseUrl.apiBaseUrl}/job/assigned_users/${selectedJob?.JobNum}`,
                {
                    headers: {
                        'X-Tenant': tenant,
                        'X-App': baseURL
                    }
                });
            setUserData(userResponse.data);
        } catch (error) {
            console.error(error);
        }
    }, [selectedJob?.JobNum]);

    const fetchRecruiters = useCallback(async () => {
        try {
            const recruiterResponse = await axios.get(`${APIBaseUrl.apiBaseUrl}/user/get_recruiter`,
                {
                    headers: {
                        'X-Tenant': tenant,
                        'X-App': baseURL
                    }
                });
            setRecruiterData(recruiterResponse.data);
        } catch (error) {
            console.error(error);
        }
    }, []);

        useEffect(() => {
            fetchJobFieldData();
            fetchAssignedUsers();
            fetchRecruiters();
        }, [selectedJob?.JobNum]);
        

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleAddUser  = async (user: any) => {
        if (user) {
            try {
                await axios.post(`${APIBaseUrl.apiBaseUrl}/job/add_assigned_user`, {
                    "JobNum": selectedJob?.JobNum, "UserNum": user.UserNum
                },
                {
                    headers: {
                        'X-Tenant': tenant,
                        'X-App': baseURL
                    }
                })
                fetchRecruiters()
                fetchAssignedUsers()
            } catch (error) {
                console.error(error);
            }
        }
        setAnchorEl(null)
    }


    const handleDeleteUser = async (UserNum: number) => {
        try {
            await axios.post(`${APIBaseUrl.apiBaseUrl}/job/delete_assigned_user`, {
                "UserNum": UserNum
            },
            {
                headers: {
                    'X-Tenant': tenant,
                    'X-App': baseURL
                }
            })
            fetchRecruiters()
            fetchAssignedUsers()
        } catch (error) {
            console.error(error)
        }
    }


   
    // const [isPopupOpen, setIsPopupOpen] = useState(false);

    // const handleOpenPopup = () => {
    //     setIsPopupOpen(true);
    // };

    // const handleClosePopup = () => {
    //     setIsPopupOpen(false);
    // };

    const date = new Date(selectedJob?.CreatedDate * 1000);
    const formattedDate = date.toLocaleDateString();

    return (
        <StyledWrapper className="job-details">
            <Grid container spacing={0} className="container">
                <Grid item className="" xs={8}>
                    <Box className="created-detail">
                        <Typography
                            variant="caption"
                            textAlign="left"
                            component="p"
                            sx={{ mb: 1 }}
                        >
                            <span> Created On </span> : <span>{formattedDate}</span>
                        </Typography>
                        <Typography
                            variant="caption"
                            textAlign="left"
                            component="p"
                            sx={{ mb: 1 }}
                        >
                            <span> Created By </span> : <span>{selectedJob?.CreatedBy} </span>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            variant="subtitle2"
                            textAlign="left"
                            component="h6"
                            sx={{ mb: 1, fontWeight: 'bold' }}
                        >
                            Job Descriptions
                        </Typography>
                        <Box>
                            <Typography
                                textAlign="left"
                                component="p"
                                sx={{ mb: 1 }}
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedJob?.JobDescr) }}
                            >
                                
                            </Typography>
                        </Box>
                        
                        {jobFieldData && jobFieldData.map((item, index) => {
                            return (
                                <Box key={index} sx={{ mb: 2 }}>
                                    <Typography
                                        variant="subtitle2"
                                        textAlign="left"
                                        component="h6"
                                        sx={{ mb: 1, fontWeight: 'bold' }}
                                    >
                                        {item.FieldName}
                                    </Typography>
                                    {item.FieldName.toLowerCase() == "skills" ? (
                                        <Box className="skill-chip">
                                            <Stack spacing={1}>
                                                <Stack direction="row" spacing={1} gap={'5px'} flexWrap="wrap">
                                                    {item.FieldValue.split(',').map((skill) => (
                                                        <Chip key={`${skill}-${index}`} label={skill}/>
                                                    ))}
                                                </Stack>
                                            </Stack>
                                        </Box> 
                                    ) : (
                                        <Typography
                                            variant="caption"
                                            textAlign="left"
                                            component="p"
                                            sx={{ mb: 1 }}
                                        >
                                            {item.FieldValue}
                                        </Typography>
                                    )}
                                </Box>
                            )
                        })}
                    </Box>


                    {/* <Button
                        variant="contained"
                        color="primary"
                        className="add-new-btn"
                        onClick={handleOpenPopup}
                    >
                        Add new users
                    </Button>

                    {isPopupOpen && (
                        <JobsDetailsPopup
                            onClose={handleClosePopup}
                            isOpen={isPopupOpen}
                        />
                    )}  */}


                </Grid>
                <Grid item className="right-card-wrapper" xs={4}>
                    <Card>
                        <CardActions>
                            <Typography
                                variant="caption"
                                textAlign="left"
                                component="h6"
                                sx={{ mb: 1 }}
                            >
                                Hiring Manager
                            </Typography>
                            <Button
                                className="import-btn"
                                size="small"
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                    />
                                }
                                onClick={handleClick}
                            >
                                <Typography>Add more</Typography>
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    sx: {
                                        width: '320px',
                                    },
                                }}
                            >
                                {/* <Box sx={{ p: 1 }}>
                                    <TextField
                                    id="UserSearchBar"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    placeholder="Search..."
                                    />

                                </Box> */}
                                {RecruiterData.length ? (
                                    RecruiterData.map((user) => (
                                        <MenuItem
                                            key={user.UserNum}
                                            onClick={() => handleAddUser(user)}
                                            sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                                        >
                                            {user.UserNum ? (
                                                <AvatarImage imageUrl={`${APIBaseUrl.apiBaseUrl}/user/get_user_image/${user.UserNum}`} />
                                            ) : (
                                                <Icon fontSize="large" />
                                            )}
                                            <Box>
                                                <Typography variant="body2" color="text.primary">
                                                    {user.UserName}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {user.EmailID}
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>No user found</MenuItem>
                                )}
                            </Menu>
                        </CardActions>
                        <CardContent>
                            {UserData && (UserData.map((item) => {
                                return (
                                    <Box className="card-content-wrapper" key={item.UserNum}>
                                        <Box className="card-content-left">
                                            <Typography
                                                sx={{ fontSize: 14 }}
                                                color="text.secondary"
                                                component="div"
                                                gutterBottom
                                            >
                                                {item.UserNum ? (
                                            <AvatarImage imageUrl={`${APIBaseUrl.apiBaseUrl}/user/get_user_image/${item.UserNum}`} />
                                        ) : (
                                            <Icon fontSize="large" />
                                        )}
                                            </Typography>
                                            <Typography
                                                sx={{ fontSize: 14 }}
                                                color="text.secondary"
                                                component="div"
                                                gutterBottom
                                            >
                                                <p>{item?.UserName}</p>
                                                <p>{item?.EmailID}</p>
                                            </Typography>
                                        </Box>
                                        {item.DefaultColumn ? <></>
                                            : <Box className="card-content-right">
                                                <Button
                                                    className="import-btn"
                                                    size="small"
                                                    onClick={() => { handleDeleteUser(item.UserNum) }}
                                                    startIcon={
                                                        <FontAwesomeIcon
                                                            icon={faTrash}
                                                        ></FontAwesomeIcon>
                                                    }
                                                ></Button>
                                            </Box>}
                                    </Box>
                                )
                            }))}

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {/* </Box> */}
        </StyledWrapper>
    );
};

export default JobsDetails;
