import { styled } from '@mui/material/styles';
import { Button, TextField, Divider, Box, Typography, Checkbox, Grid, Link } from '@mui/material';
import {theme} from '../../../theme';

// Custom styled components
export const ImageContainer = styled(Box)({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100vh',
});

export const FormContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Center align items in the form container
  justifyContent: 'center',
 
  padding: '2vh 2vw',
  height: '100vh',
  lineHeight: '1.8vh',
});

export const Heading = styled(Typography)({
  // // fontWeight: 'bold',
  // '&.MuiTypography-root': {
  //   marginBottom: 0, // Ensure no margin from the Typography root styles
  // },
  fontSize: '4vh',
  alignSelf: 'flex-start',
  // marginBottom: '10px',
  color: theme.palette.text.primary,
  
});

export const IconContainer = styled(Box)({
  display: 'flex',
  gap: '3vw',
  justifyContent: 'center',
  marginBottom: '2vh',
});

export const StyledDivider = styled(Divider)({
  width: '100%',
  margin: '2vh 0',
});

export const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',  
    // border: '1px solid #ccc', // Default border color
    transition: 'border-color 0.3s', // Smooth transition effect

    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#7F56D9', // Border color on hover
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#7F56D9', // Border color when focused
    },

  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#ccc', // Default border color
  },
  '& .MuiInputLabel-root': {
    color: '#7F56D9', // Change label text color
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#7F56D9', // Change label color when focused
  },

  width: '100%',
  marginTop: '0.1vh',
  marginBottom: '2.5vh',
});

export const StyledButton = styled(Button)({
  height: '6vh',
  borderRadius: '8px', // Maintain consistent border-radius
  alignSelf: 'flex-start',  // Ensure button stretches to fit the container
  // width:'98%',
  //  marginLeft: '0',
  width: '100%',
  marginTop: '2vh',
  marginBottom: '2vh',
  marginLeft: '14px',
  backgroundColor: '7F56D9',
});
export const RecaptchaContainer = styled(Box)({
  margin: '20px 0',
  width: '100%',
});

export const GridContainer = styled(Grid)({
  maxWidth: '40vw',
  width: '100%',
});

export const TermsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  margin: '2vh 0',
  gap: '1vw',
  width: '100%',
});

export const TermsText = styled(Typography)({
  fontSize: '1.2vh',
});

export const CheckboxStyled = styled(Checkbox)({
  padding: '0',
  marginRight: '1vw',
});

export const SignInLink = styled(Link)({
  color: '#3f51b5',
});
