import React, { useState, forwardRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    Box,
    Divider,
    Checkbox,
    FormControlLabel,
    Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { theme } from '../../../../../theme'; // Ensure your theme is imported correctly

// Slide transition component
const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

interface DeclineOfferProps {
    open: boolean;
    onClose: (reason:string) => void;
    setDeclinePopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeclineOffer: React.FC<DeclineOfferProps> = ({ open, onClose, setDeclinePopupOpen }) => {
    const [instructions, setInstructions] = useState('');
    const [selectedReason, setSelectedReason] = useState<string>('');
    const [error, setError] = useState(false);
    const [checkBoxError, setCheckBoxError] = useState(false)

    const handleReasonChange = (reason: string) => {
        if (reason === 'Other reasons') {
            setSelectedReason(selectedReason === reason ? '' : reason); // Toggle selection
            setInstructions(''); // Reset instructions if "Other reasons" is toggled off
        } else {
            setSelectedReason(selectedReason === reason ? '' : reason); // Toggle any other reason
        }
    };
   

    const handleAssign = () => {
        if (!selectedReason) {
            setCheckBoxError(true);
            return;
        } else {
            setCheckBoxError(false);
        }

        // Check if "Other reasons" is selected but the instructions are empty
        if (selectedReason === 'Other reasons' && !instructions.trim()) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        console.log('Reasons:',  selectedReason==='Other reasons'?instructions:selectedReason);
        const decline_reason=selectedReason==='Other reasons'?instructions:selectedReason
        onClose(decline_reason);
    };

    const handleClosePopup = () => {
        setDeclinePopupOpen(false)
        setError(false)
        setCheckBoxError(false)
        setInstructions('')
        setSelectedReason('')
    }

    return (
        <Dialog
            open={open}
            onClose={handleClosePopup}
            TransitionComponent={Transition}
            fullScreen
            BackdropProps={{ style: { backgroundColor: 'transparent' } }}
            PaperProps={{
                sx: {
                    maxWidth: '20vw',
                    height: '100vh',
                    margin: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    right: 0,

                },
            }}
        >
            {/* Header Area */}
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', p: '1.389vw 1.042vw 0 0.694vw' }}>
                <Box
                    color={theme.palette.secondary.main}
                    sx={{
                        border: '0.069vw solid #EEEEEE',
                        bgcolor: theme.palette.secondary.light,
                        margin: '0.417vw',
                        // mr: '10.417vw',
                        width: '2.361vw',
                        height: '2.361vw',
                        borderRadius: '0.694vw',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <i className="fa-solid fa-rectangle-xmark"></i>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">Decline Offer</Typography>
                </Box>
            </DialogTitle>

            <Divider sx={{ mb: 1.2, mt: 2 }} />

            {/* Content Area */}
            <DialogContent sx={{ overflowY: 'auto', pt: 0, '& .MuiFormControl-root': { mt: '0.4448vw' } }}>
                <Typography gutterBottom variant="body2" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
                    Sorry to see you go
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    We are sorry to see you go! It would be helpful to serve future candidates with improved
                    service if you could provide us with the reason for declining the offer.
                </Typography>

                <Divider sx={{ mb: 2 }} />

                {/* "Reason to decline offer" Section */}
                <Typography variant="body2" sx={{ fontWeight: 'bold', mb: '0.556vw' }}>
                    Reason to decline offer
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value="Had received an offer with higher pay"
                                checked={selectedReason.includes('Had received an offer with higher pay')}
                                onChange={() => handleReasonChange('Had received an offer with higher pay')}
                                color="primary"
                                disabled={selectedReason === 'Other reasons'} // Disable if "Other reasons" is selected
                            />
                        }
                        label="Had received an offer with higher pay"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                value="Current company gave me an increase"
                                checked={selectedReason.includes('Current company gave me an increase')}
                                onChange={() => handleReasonChange('Current company gave me an increase')}
                                color="primary"
                                disabled={selectedReason === 'Other reasons'} // Disable if "Other reasons" is selected
                            />
                        }
                        label="Current company gave me an increase"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                value="Personal reasons"
                                checked={selectedReason.includes('Personal reasons')}
                                onChange={() => handleReasonChange('Personal reasons')}
                                color="primary"
                                disabled={selectedReason === 'Other reasons'} // Disable if "Other reasons" is selected
                            />
                        }
                        label="Personal reasons"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                value={instructions}
                                checked={selectedReason === 'Other reasons'}
                                onChange={() => handleReasonChange('Other reasons')}
                                color="primary" // Explicitly set the color to primary
                                sx={{
                                    color: selectedReason === 'Other reasons' ? theme.palette.primary.main : 'inherit',
                                    '&.Mui-checked': {
                                        color: theme.palette.primary.main, // Ensure color when checked
                                    },
                                }}
                            />
                        }
                        label="Other reasons"
                    />
                    {checkBoxError && (
                        <Typography sx={{ color: 'red' }}>Please select a reason to decline the offer.</Typography>
                    )}
                </Box>
                {/* Other reasons Section */}
                {selectedReason === 'Other reasons' && (
                    <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column', mt: 2 }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
                            Please provide your reasons:
                        </Typography>
                        <TextField
                            placeholder="Reasons to decline offer"
                            multiline
                            rows={4}
                            fullWidth
                            margin="normal"
                            value={instructions}
                            onChange={(e) => setInstructions(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '10px',
                                },
                            }}
                            required={selectedReason === 'Other reasons'}
                            error={error && selectedReason === 'Other reasons' && !instructions.trim()}
                            helperText={
                                error && selectedReason === 'Other reasons' && !instructions.trim()
                                    ? 'Please provide a reason for declining the offer.'
                                    : ''
                            }
                        />
                    </Box>
                )}
            </DialogContent>

            <Divider />

            {/* Footer Area */}
            <DialogActions sx={{ padding: 2 }}>
                <Button onClick={handleClosePopup} variant="outlined" fullWidth>
                    Cancel
                </Button>
                <Button onClick={handleAssign} variant="contained" color="primary" fullWidth>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeclineOffer;
