import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { StyledPageWrapper } from '../../App.styles';
import Jobs from '../pages/Jobs';
import Users from '../pages/Users';
import CreateJob from '../pages/Jobs/CreateJob/CreateJob';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import EditJob from '../pages/Jobs/EditJob/EditJob';
const AppRoutes: React.FC = () => {
    const { tenant } = useParams<{ tenant: string }>();
    return (
        <Routes>
            <Route index element={<Navigate to={`/${tenant}/dashboard`} />} />
            <Route
                path={`dashboard`}
                element={
                    <StyledPageWrapper>
                        <Typography
                            variant="h5"
                            sx={{ textAlign: 'center', mt: 4 }}
                        >
                            RecEzy Home
                        </Typography>
                    </StyledPageWrapper>
                }
            />
            <Route
                path={`jobs`}
                element={
                    <StyledPageWrapper>
                        <Jobs />
                    </StyledPageWrapper>
                }
            />
            <Route
                path={`jobs/create`}
                element={
                    <StyledPageWrapper>
                        <CreateJob />
                    </StyledPageWrapper>
                }
            />
            <Route
                path={`jobs/edit`}
                element={
                    <StyledPageWrapper>
                        <EditJob/>
                    </StyledPageWrapper>
                }
            />
            <Route
                path={`users`}
                element={
                    <StyledPageWrapper>
                        <Users />
                    </StyledPageWrapper>
                }
            />
            <Route
                path="*"
                element={
                    <StyledPageWrapper>
                        <Typography
                            variant="h5"
                            sx={{ textAlign: 'center', mt: 4 }}
                        >
                            Coming Soon...{' '}
                        </Typography>
                    </StyledPageWrapper>
                }
            />
        </Routes>
    );
};

export default AppRoutes;
