import React, { useState, useEffect } from 'react'
import { StyledWrapper, FormContainer } from '../CreateJob/CreateNewField/CreateNewField.styles';
import {
    Autocomplete,
    Box,
    Grid,
    IconButton,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { HighlightOff, Close } from '@mui/icons-material';

interface EditJobFieldProps {
    fieldData: {
        FieldNum: number;
        FieldName: string;
        FieldValue: string;
    }
    onFieldDataUpdate: (updatedField: { FieldNum: number; FieldName: string; FieldValue: string }) => void;
    onDeleteField: (a: number) => void;
}

const EditJobField: React.FC<EditJobFieldProps> = ({ fieldData, onFieldDataUpdate,onDeleteField }) => {
    const theme = useTheme();
    const [fieldName, setFieldName] = useState(fieldData.FieldName);
    const [values, setValues] = useState(fieldData.FieldValue ? fieldData.FieldValue.split(',') : [])
    const id = 'newField' + fieldData.FieldNum;
    console.log(values, id)

    useEffect(() => {
        const element = document.getElementById(id);
        element?.scrollIntoView({ behavior: 'smooth' });
    }, [id]);
    
    useEffect(() => {
        // Call update method whenever fieldName or values change
        onFieldDataUpdate({
            FieldNum: fieldData.FieldNum,
            FieldName: fieldName,
            FieldValue: values.join(',')// Join values into a single string
        });
    }, [fieldName, values]);
    const handleFieldNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldName(e.target.value);
    };
    const handleOnDeleteField = () => {
        onDeleteField(fieldData.FieldNum);
    };
    return (
        <StyledWrapper>
            <FormContainer id={'newField' + fieldData.FieldNum}  >
                <Grid container>
                    <Grid xs={3} item={true} className="field-name">
                        <TextField
                            variant="outlined"
                            value={fieldName}
                            onChange={handleFieldNameChange}
                            placeholder="Enter field name"
                        ></TextField>
                    </Grid>

                    <Grid xs={9} item={true} className="field-value">
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Autocomplete
                                sx={{ width: '100%' }}
                                clearIcon={false}
                                freeSolo
                                multiple
                                options={[]}
                                value={values}
                                onChange={(e, newval: any, reason) => {
                                    setValues(newval);
                                }}
                                ChipProps={{
                                    deleteIcon: (
                                        <Close
                                            sx={{
                                                fill: theme.palette.custom
                                                    .white,
                                            }}
                                        />
                                    ),
                                }}
                                renderInput={(params: any) => {
                                    const isSpaceEntered =
                                        params.inputProps.value.charAt(
                                            params.inputProps.value.length - 1,
                                        ) === ' ';
                                    if (isSpaceEntered) {
                                        params.inputProps.value = '';
                                    }
                                    return (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Enter values separated by space"
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.stopPropagation();
                                                }
                                                if (
                                                    e.keyCode === 32 &&
                                                    e.target?.value
                                                ) {
                                                    setValues(
                                                        values.concat(
                                                            e.target?.value,
                                                        ),
                                                    );

                                                }
                                            }}
                                        />
                                    );
                                }}
                            />
                            <IconButton
                                type="button"
                                className="delete-btn"
                            onClick={handleOnDeleteField}
                            >
                                <HighlightOff
                                    sx={{ fill: theme.palette.error.main }}
                                ></HighlightOff>
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </FormContainer>
        </StyledWrapper>
    )
}

export default EditJobField