import React from 'react';
import { StyledWrapper } from './App.styles';
import Header from './components/Header';
import Navbar from './components/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppRoutes from './components/common/AppRoutes';
import Login from './components/pages/Login';
import ForgotPassword from './components/pages/Login/ForgotPassword/ForgotPassword';
import ChangePassword from './components/pages/Login/ChangePassword/ChangePassword';
import ProtectedRoute from './components/pages/Login/ProtectedRoute';
import DefaultPage from './DefaultPage';


const App: React.FC = () => {

    return (
        <div className="app">
            <StyledWrapper>
                <BrowserRouter>
                    <Routes>

                        <Route path="/:tenant" element={<Login />} />
                        {/* <Route index element={<Login />} /> Default page */}
                        <Route path="/:tenant/login" element={<Login />} />
                        <Route path='/:tenant/forgotpassword' element={<ForgotPassword />} />
                        <Route path='/:tenant/changepassword' element={<ChangePassword />} />

                        <Route element={<ProtectedRoute />}>
                            <Route
                                path="/:tenant/*"
                                element={
                                    <>
                                        <Header></Header>
                                        <Navbar></Navbar>
                                        <div className="main-content">
                                            <AppRoutes></AppRoutes>
                                        </div>
                                    </>
                                }
                            ></Route>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </StyledWrapper>
        </div>
    );
};

export default App;
