import React, { useState } from "react";

import CandidateContent from "../CandidateContent/CandidateContent";

import NotesPopup from "../AddNotes/NotesPopup";

import { Box } from "@mui/material";
import NotifyCandidate from "./CandidateStatusAction/Notifycandidate";


const CandidateExperience: React.FC = () => {
    const [showComponent, setShowComponent] = useState(false);

    const handleNameClick = (id: string) => {
        console.log(`Name clicked for Candidate ID: ${id}`);
        // Handle name click (e.g., open a dialog with candidate details)
    };

    const handlePositionClick = (id: string) => {
        console.log(`Position clicked for Candidate ID: ${id}`);
        // Handle position click (e.g., show position details)
    };

    const handleFunctionClick = (id: string) => {
        console.log(`Function clicked for Candidate ID: ${id}`);
        // Handle function click (e.g., show function details)
    };

    const handleNotesClick = (id: string) => {
        console.log(`Notes clicked for Candidate ID: ${id}`);
        // Handle notes click (e.g., open notes dialog)
    };

    const handleStatusClick = (id: string) => {
        setShowComponent(true);  // Open SetupInterview component
    };

    const handleClose = () => {
        setShowComponent(false);  // Close SetupInterview component
    };

    return (
        <Box sx={{'& .MuiBox-root': { padding: 0 },width:'100%' }}>
            <CandidateContent
showActionButton={true}

            />

            {/* Conditionally render SetupInterview */}
            {/* <NotifyCandidate open={showComponent} onClose={handleClose} /> */}

        </Box   >
    );
};

export default CandidateExperience;
