import React, { useState, useEffect } from 'react';
import {
  Box, Button, Grid, Typography, TextField, Paper, IconButton, Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { TableRowStyled } from '../../CandidateDetails.styles';
import { theme } from '../../../../../theme';

interface RowData {
  field: string;
  value: string;
}

interface SkillsCertificationsProps {
  rows: RowData[];
  onSkillsUpdate: (updatedSkills: RowData[]) => void;
  isSubmitted: boolean;
}

const SkillsCertifications: React.FC<SkillsCertificationsProps> = ({ rows, onSkillsUpdate, isSubmitted }) => {
  // Local state to control the values of fields and values
  const [localRows, setLocalRows] = useState<RowData[]>(rows);

  // Effect to sync local state with rows prop if it changes
  useEffect(() => {
    setLocalRows(rows);
  }, [rows]);

  const handleAddRow = () => {
    const newRow = { field: '', value: '' };
    const updatedRows = [...localRows, newRow];
    setLocalRows(updatedRows);
    onSkillsUpdate(updatedRows);
  };

  const handleDeleteRow = (index: number) => {
    const updatedRows = localRows.filter((_, rowIndex) => rowIndex !== index);
    setLocalRows(updatedRows);
    onSkillsUpdate(updatedRows);
  };

  const handleFieldChange = (index: number, value: string, type: 'field' | 'value') => {
    const updatedRows = [...localRows];
    updatedRows[index] = {
      ...updatedRows[index],
      [type]: value, // Update either 'field' or 'value'
    };
    setLocalRows(updatedRows);
    onSkillsUpdate(updatedRows);
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h6" fontWeight="bold">Skills & Certifications</Typography>
      <Divider sx={{ mb: '1.111vw' }} />
      <Paper variant="outlined" sx={{ p: 3, borderRadius: '1.111vw' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '1.111vw' }}>
          <Typography variant="body2" color="textSecondary">
            {isSubmitted ? "Your skills and certifications are listed below." : "Add, edit, and remove your skills and certifications here."}
          </Typography>
          {!isSubmitted && (
            <Button
              variant="contained"
              sx={{ backgroundColor: "#7F56D9" }}
              onClick={handleAddRow}
            >
              Add Custom Field
            </Button>
          )}
        </Box>

        {isSubmitted ? (
          <Grid container spacing={3}>
            {localRows.map((row, index) => (
              <Grid item xs={12} key={index}>
                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary', width: '7vw', fontWeight: 'bold', marginRight: '0.556vw' }}>
                    {row.field}:
                  </Typography>
                  <Typography variant="body2">{row.value}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <TableContainer sx={{ border: `0.069vw solid ${theme.palette.divider}`, borderRadius: '1vw' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localRows.map((row, index) => (
                  <TableRowStyled key={index} isEven={index % 2 === 0}>
                    <TableCell>
                      <TextField
                        label="Field"
                        fullWidth
                        size="small"
                        value={row.field}  // Use value instead of defaultValue
                        onChange={(e) => handleFieldChange(index, e.target.value, 'field')}  // Use onChange
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Value"
                        fullWidth
                        size="small"
                        value={row.value}  // Use value instead of defaultValue
                        onChange={(e) => handleFieldChange(index, e.target.value, 'value')}  // Use onChange
                      />
                    </TableCell>
                    <TableCell sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <IconButton sx={{ color: "#7F56D9" }} onClick={() => handleDeleteRow(index)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Box>
  );
};

export default SkillsCertifications;
