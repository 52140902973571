import React, { useEffect, useState } from 'react'
import axios from 'axios'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useParams } from 'react-router-dom';
interface imageUrlDatatype {
    imageUrl: string;
    height?: string ; // Optional height prop
    width?: string ;  // Optional width prop
}
const AvatarImage: React.FC<imageUrlDatatype> = ({ imageUrl, height = '38px', width = '38px' }) => {
    const [imgSrc, setImgSrc] = useState<string | null>(null)
    const { tenant } = useParams<{ tenant: string }>();
    const baseURL = window.location.origin.endsWith('/')
        ? window.location.origin
        : `${window.location.origin}/`;
    const token = localStorage.getItem('access_token')

    useEffect(() => {
        const fetchImage = async () => {
            try {
                if (!imageUrl) return;
                const response = await axios.get(imageUrl, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'X-Tenant': tenant,
                        'X-App': baseURL
                    },
                    responseType: 'blob'
                })
                if (response.status === 200) {
                    const blob = response.data;
                    if (blob.size > 35) { // Check if blob size is greater than 35 bytes
                        const imageObjectURL = URL.createObjectURL(blob);
                        setImgSrc(imageObjectURL);
                    } else {
                        setImgSrc(null); // Set to null for empty images
                    }
                }
            } catch (error) {
                console.log(error)
                setImgSrc(null)
            }
        }
        fetchImage()
        
    }, [imageUrl])
    
    return (
        imgSrc ?( <img style={{ height, width, borderRadius: "50%" }} src={imgSrc} alt="Authenticated" /> ): (<AccountCircleOutlinedIcon sx={{fontSize:height}} />)
    )
}

export default AvatarImage