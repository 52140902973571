import { styled } from '@mui/material/styles';
import { Box, Button, Paper, TableRow, Typography } from '@mui/material';

// Container
export const Container = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    maxWidth: 'calc(100vw - 24vw)', // For large screens (960px - 1280px)
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 'calc(100vw - 22vw)', // For extra-large screens (1280px and up)
  },
  
  boxSizing: 'border-box',
}));

// Header
export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2vh',
  flexWrap: 'wrap',
}));

// Action Buttons
export const ActionButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '1vw',
  marginBottom: '3vh',
  flexWrap: 'wrap',
}));

// Table Container
export const TableWrapper = styled(Paper)(({ theme }) => ({
  borderRadius: '1.2vw',
  marginTop: '3vh',
}));

// Table Row Styled
interface TableRowStyledProps {
  isEven: boolean;
}

export const TableRowStyled = styled(TableRow)<TableRowStyledProps>(({ isEven }) => ({
  backgroundColor: isEven ? '#F9F5FF' : 'transparent',
}));

export const ScreeningStatus = styled('span')(({ theme }) => ({
  color: '#067647',
  border: '1px solid #47CD89',
  background: '#ECFDF3',
  padding: '4px 14px',
  borderRadius: '999px',
  fontWeight: 500,  
}));

export const AssignmentStatus = styled('span')(({ theme }) => ({
  color: '#B54708',
  border: '1px solid #FECDCA',
  background: '#FEF3F2',
  padding: '4px 12px',
  borderRadius: '999px',
  fontWeight: 500,
  
}));


export const StyledTypography = styled(Typography)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[400],
  backgroundColor: '#ffffff',
  padding: '0px 6px',
  borderRadius: '4px',
  display: 'inline-block',
}));