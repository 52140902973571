import React, { useEffect, useState } from 'react';
import {
    Notifications,
    Search as SearchIcon,
    ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import { StyledHeader } from './Header.styles';
import {
    Badge,
    Button,
    Box,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import AppLogo from '../common/AppLogo';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import AvatarImage from '../pages/Users/AvatarImage';
import { useNavigate } from 'react-router-dom';
import APIBaseUrl from '../../AppConfig/AppConfig.json'
import { useParams } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
interface JWTClaims {
    UserNum: number; // Define UserNum claim
}

const Header: React.FC = (props) => {
    const appName = 'RecEzy';
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [userNum, setUserNum] = useState<number | null>(null);
    const [userData, setUserData] = useState<any>(null);
    const navigate = useNavigate()
    const { tenant } = useParams<{ tenant: string }>();
    const baseURL = window.location.origin.endsWith('/')
        ? window.location.origin
        : `${window.location.origin}/`;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        const fetchData = async () => {
            if (userNum) {
                try {
                    const response = await axios.get(`${APIBaseUrl.apiBaseUrl}/user/get_user/${userNum}`,
                        {
                            headers: {
                                'X-Tenant': tenant,
                                'X-App': baseURL
                            }
                        }
                    );
                    setUserData(response.data);
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            }
        };

        const token = localStorage.getItem('access_token');
        if (token) {
            try {
                const decoded: JWTClaims = jwtDecode(token);
                // Check if UserNum is present before setting it
                if (decoded.UserNum) {
                    setUserNum(decoded.UserNum);
                }
            } catch (error) {
                console.error("Token decoding failed:", error);
            }
        }

        fetchData();
    }, [userNum]); // Effect depends on userNum

    const handleLogout = () => {
        localStorage.removeItem('access_token')
        navigate(`/${tenant}`)
        handleClose()
    }
    const theme = useTheme();
    return (
        <StyledHeader className="header">
            <Box className="logo-container">
                <Box className="logo">
                    <Box
                        component="a"
                        sx={{
                            textDecoration: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.custom.white,
                        }}
                        href={`/${tenant}/jobs`}
                    >
                        <AppLogo></AppLogo>
                        <Typography sx={{ fontSize: '25px' }}>{appName}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box className="search-container">
                <FormControl className="search">
                    <TextField
                        id="searchBar"
                        placeholder="Search"
                        type="search"
                        variant="outlined"
                        size="small"
                        InputProps={{
                            style: {
                                borderRadius: '50px',
                                width: '115px',
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </Box>
            <Box className="header-menu-container">
                <Box className="header-menu" sx={{ display: 'flex' }}>
                    <IconButton
                        type="button"
                        className="menu-item notification-btn"
                    >
                        <Badge variant="dot" color="primary">
                            <Notifications fontSize={'medium'} />
                        </Badge>
                    </IconButton>
                    <Box className="menu-item profile-menu">
                        <Button
                            id="profileMenuBtn"
                            aria-haspopup="true"
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<ArrowDropDownIcon />}
                            color="secondary"
                        >
                            {userNum ? (
                                <AvatarImage imageUrl={`${APIBaseUrl.apiBaseUrl}/user/get_user_image/${userNum}`} />
                            ) : (
                                <AccountCircleOutlinedIcon fontSize="large" />
                            )}
                            {userData?.UserName}
                        </Button>
                        <Menu
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            id="profileMenu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem
                                className="profile-menu-item"
                                onClick={handleClose}
                                disableRipple
                            >
                                My Profile
                            </MenuItem>
                            <MenuItem
                                className="profile-menu-item"
                                onClick={handleClose}
                                disableRipple
                            >
                                Settings
                            </MenuItem>
                            <MenuItem
                                className="profile-menu-item"
                                onClick={handleLogout}
                                disableRipple
                            >
                                Log out
                            </MenuItem>
                        </Menu>
                    </Box>
                </Box>
            </Box>
        </StyledHeader>
    );
};

export default Header;
