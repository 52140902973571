import { styled } from '@mui/material/styles';
import { Box, Button, Paper, TableRow, Typography ,TableCell} from '@mui/material';

// Container
export const Container = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    maxWidth: 'calc(100vw - 24vw)', // For large screens (960px - 1280px)
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 'calc(100vw - 22vw)', // For extra-large screens (1280px and up)
  },
  margin: '0 auto',
  boxSizing: 'border-box',
}));

// Header
export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2vh',
  flexWrap: 'wrap',
}));

// Action Buttons
export const ActionButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '1vw',
  marginBottom: '3vh',
  flexWrap: 'wrap',
}));

// Table Container
export const TableWrapper = styled(Paper)(({ theme }) => ({
  borderRadius: '1.2vw',
  marginTop: '3vh',
}));

interface TableRowStyledProps {
  iseven: string; // Change type to string
}

export const TableRowStyled = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'iseven', // Ensure that 'iseven' is not forwarded
})<TableRowStyledProps>(({ iseven, theme }) => ({
  backgroundColor: iseven === 'true' ? '#F9F5FF' : 'transparent', // Use string comparison
}));

export const ScreeningStatus = styled('span')(({ theme }) => ({
  color: '#067647',
  border: '1px solid #47CD89',
  background: '#ECFDF3',
  padding: '4px 14px',
  borderRadius: '999px',
  fontWeight: 500,  
}));

export const AssignmentStatus = styled('span')(({ theme }) => ({
  color: '#B54708',
  border: '1px solid #FECDCA',
  background: '#FEF3F2',
  padding: '4px 12px',
  borderRadius: '999px',
  fontWeight: 500,
  
}));
export const TableDescription=styled(TableCell)(({theme})=>({
  fontSize: '12px',
  padding: '1.6vh 2.4vw',
  whiteSpace: 'nowrap', 
  overflow: 'hidden', 
  textOverflow: 'ellipsis', 
  maxWidth: '200px', 
  minWidth: '100px', 
}))

export const StyledTypography = styled(Typography)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[400],
  backgroundColor: '#ffffff',
  padding: '0px 6px',
  borderRadius: '4px',
  display: 'inline-block',
}));

export const OpenStatus = styled('span')(({ theme }) => ({
  color: '#067647',            // Dark green text color
  border: '1px solid #47CD89',  // Light green border color
  background: '#ECFDF3',        // Light green background
  padding: '0.5vh 1vw',
  borderRadius: '50vw',
  fontWeight: 500,
}));

export const CloseStatus = styled('span')(({ theme }) => ({
  color: '#D32F2F',            // Dark red text color
  border: '1px solid #F88379', // Light red border color
  background: '#FFEBEE',       // Light red background
  padding: '0.5vh 1vw',
  borderRadius: '50vw',
  fontWeight: 500,
}));

export const OnHoldStatus = styled('span')(({ theme }) => ({
  color: '#E68900',            // Orange text color
  border: '1px solid #FFC107', // Light orange border color
  background: '#FFF4E0',       // Light orange background
  padding: '0.5vh 1vw',
  borderRadius: '50vw',
  fontWeight: 500,
}));
