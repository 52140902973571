import React, { useCallback } from 'react';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Chip,
    Typography,
    useTheme,
} from '@mui/material';
import { StyledCardWrapper } from './JobsSearchPane.styles';
import { useJobStore } from '../useJobStore';



type TJobData = {
    JobTitle: string;
    Department: string;
    Status: string;
    JobNum: number;
    CreatedDate: number;
};

interface IJobCardProps {
    jobData: TJobData;
    selected: boolean;
    jobSelectHandler: (a: TJobData) => void;
}

const JobCard: React.FC<IJobCardProps> =React.memo( ({
    jobData,
    selected,
    jobSelectHandler,
}: IJobCardProps) => {
    const theme = useTheme();
    const { selectedJob } = useJobStore();
    const status = selectedJob?.JobNum === jobData.JobNum ? selectedJob.Status : jobData.Status;

    const handleClick = useCallback(() => {
        jobSelectHandler(jobData);
    }, [jobData, jobSelectHandler]);
   
    const date = new Date(jobData.CreatedDate * 1000);
    const formattedDate = date.toLocaleDateString();

    return (
        <StyledCardWrapper selectedCard={selected}>
            <Card
                onClick={handleClick}
                className="job-card"
                sx={{
                    minWidth: 275,
                    backgroundColor: selected
                        ? theme.palette.primary.main
                        : theme.palette.custom.white,
                    textAlign: 'left',
                    boxShadow: 'none',
                    border: '1px solid ' + theme.palette.custom.border,
                    borderRadius: '6px',
                }}
            >
                <CardContent>
                <Box sx={{ display: 'flex' }}>
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: '500',
                            fontFamily: 'Work Sans Medium',
                            fontSize: '18px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            color: selected
                                ? theme.palette.custom.white
                                : theme.palette.text.primary,
                        }}
                        component="div"
                        title={jobData.JobTitle}
                    >
                        {jobData.JobTitle}
                        </Typography>
                        {status==='Close'?<Chip
                            className="job-status"
                            icon={
                                <Box component="li" sx={{ width: '6px' }}></Box>
                            }
                            sx={{
                                ml: 1,
                                backgroundColor: theme.palette.custom.whiteBg,
                                border: 'none',
                            }}
                            size="small"
                            label={status}
                            variant="outlined"
                        />:<Chip
                            className="job-status"
                            icon={
                                <Box component="li" sx={{ width: '6px' }}></Box>
                            }
                            sx={{
                                ml: 1,
                                backgroundColor: theme.palette.custom.whiteBg,
                                border: 'none',
                            }}
                            size="small"
                            label={status}
                            variant="outlined"
                        />}
                    </Box>
                    <Typography
                        className="subtitle"
                        variant="caption"
                        sx={{ fontSize: '10px' }}
                        color="text.secondary"
                    >
                        {jobData.Department}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Typography
                        variant="caption"
                        sx={{ ml: 1, fontSize: '10px' }}
                        color="text.secondary"
                        className="card-created"
                    >
                        Created on: {formattedDate}
                    </Typography>
                </CardActions>
            </Card>
        </StyledCardWrapper>
    );
});

export default JobCard;
