export const getCroppedImg = async (
    imageSrc: string,
    {
        width,
        height,
        x,
        y,
    }: { width: number; height: number; x: number; y: number },
): Promise<string> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = imageSrc;
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                reject('Failed to get canvas context');
                return;
            }
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(
                image,
                x * scaleX,
                y * scaleY,
                width * scaleX,
                height * scaleY,
                0,
                0,
                width,
                height,
            );
            canvas.toBlob((blob) => {
                if (blob) {
                    const croppedImageUrl = URL.createObjectURL(blob);
                    resolve(croppedImageUrl);
                } else {
                    reject('Failed to crop image');
                }
            }, 'image/jpeg');
        };
        image.onerror = () => {
            reject('Failed to load image');
        };
    });
};
