import { styled } from '@mui/material/styles';
import { Box, css } from '@mui/material';

export const StyledWrapper = styled(Box)(
    ({ theme }) => css`
        .field-name {
            padding: 4px;
            .MuiTextField-root {
                width: 100%;
                height: 46px;
            }
            .MuiOutlinedInput-root {
                height: 46px;
                .MuiOutlinedInput-input {
                    font-size: 12px;
                    &::placeholder {
                        font-size: 12px;
                    }
                }
            }
        }
        .field-value {
            padding: 4px;
            .MuiOutlinedInput-root {
                min-height: 46px;
                padding: 4px 8px;
                .MuiOutlinedInput-input {
                    font-size: 12px;
                    &::placeholder {
                        font-size: 12px;
                    }
                }
                .MuiChip-filled {
                    background-color: ${theme.palette.primary.main};
                    color: ${theme.palette.custom.white};
                    border-radius: 4px;
                    font-family: 'Inter';
                    font-weight: 400;
                    .MuiChip-label {
                        font-size: 12px;
                    }
                    .MuiChip-deleteIcon {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
        .delete-btn {
            &:hover {
                background: none;
            }
        }
    `,
);

export const FormContainer = styled(Box)(({ theme }) => ({}));
