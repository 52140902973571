import axios from 'axios';
import APIBaseUrl from '../../../AppConfig/AppConfig.json'

export const deleteItem = async (id: number | undefined,tenant:string | undefined) => {
    const baseURL = window.location.origin.endsWith('/')
            ? window.location.origin
            : `${window.location.origin}/`;
    try {
        const response = await axios.post(`${APIBaseUrl.apiBaseUrl}/user/delete_user`, { UserNum: id },{
            headers:{
                'X-Tenant': tenant,
                'X-App': baseURL
            }
        });
        // Handle success response if needed
    } catch (error) {
        // Handle error response
        if (axios.isAxiosError(error)) {
            console.error('Error deleting item:', error.response?.data || error.message);
        } else {
            console.error('Unexpected error:', error);
        }
    }
};
