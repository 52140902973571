import React, { useState, ChangeEvent, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Grid, Box, Typography } from '@mui/material';
import {
  ImageContainer,
  FormContainer,
  StyledButton,
  StyledTextField,
  GridContainer,
} from './ChangePassword.styles';
import BackGround from '../../../../Assets/AuthBackGround/AuthBG.png';
import { theme } from '../../../../theme';
import { getDecryptValue } from './decrypt';
import { validatePassword } from '../../Validation/validation';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import APIBaseURL from '../../../../AppConfig/AppConfig.json'

interface ChangePasswordProps { }

interface FormValues {
  newPassword: string;
  confirmPassword: string;
}

const ChangePassword: React.FC<ChangePasswordProps> = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [errors,setErrors]=useState<{ [key: string]: string }>({})
  const { tenant } = useParams<{ tenant: string }>();
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const [formValues, setFormValues] = useState<FormValues>({
    newPassword: '',
    confirmPassword: '',
  });

  const encryptedData = searchParams.get('sxormoh5'); // Use the meaningless parameter name
  let userId = '';
  let userNum = '';
  let encryptedSecretKey = '';
  let expiryTime: number | null = null; // Declare as number or null


  if (encryptedData) {
    const decryptedData = getDecryptValue(encryptedData);
    const [encUserId, encUserNum, encSecretKey, decExpiry] = decryptedData.split(':');
    userId = getDecryptValue(encUserId);
    userNum =getDecryptValue( encUserNum);
    encryptedSecretKey = encSecretKey;
    expiryTime = parseInt(decExpiry, 10); // Ensure expiryTime is a number
  }

  useEffect(() => {
    const validateConditions = async () => {
      if (expiryTime !== null) { // Check if expiryTime is not null
        const currentTime = Math.floor(Date.now() / 1000);
        if (currentTime > expiryTime) {
          navigate(`/${tenant}/SignIn`, {
            state: {
              snackbarMessage: "The link has expired.",
              snackbarSeverity: 'error',
            },
          });
          return;
        }
      }

      try {
        const response = await axios.post(`${APIBaseURL.apiBaseUrl}/user/validate_secret_key`, {
          SecretKey: encryptedSecretKey,
          UserNum: Number(userNum),
        }, {
          headers: {
            'X-Tenant': tenant,
            'X-App': baseURL,
          },
        });

        if (response.status !== 200) {
          navigate(`/${tenant}/SignIn`, {
            state: {
              snackbarMessage: "The link has expired.",
              snackbarSeverity: 'error'
            }
          });
        }
      } catch (error) {
        navigate(`/${tenant}/SignIn`, {
          state: {
            snackbarMessage: "The link has expired.",
            snackbarSeverity: 'error'
          }
        });
      }
    };

    validateConditions();
  }, [expiryTime, userNum, searchParams]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // Implement password change logic here
    const newErrors: { [key: string]: string } = {};
    if (!validatePassword(formValues.newPassword)) {
      newErrors.newpassword='Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character.'
    }

    if (formValues.newPassword !== formValues.confirmPassword) {
      newErrors.confirmpassword='Passwords do not match.'
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return
  }

    try {
      const resposne = await axios.post(`${APIBaseURL.apiBaseUrl}/user/change_password`, {
        "password": formValues.confirmPassword,
        "SecretKey": encryptedSecretKey,
        "UserNum": Number(userNum)
      }, {
        headers: {
          'X-Tenant': tenant,
          'X-App': baseURL
        }
      })
      if (resposne.status === 201) {
        navigate(`/${tenant}/SignIn`, {
          state: {
            snackbarMessage: "Password has been changed successfully",
            snackbarSeverity: 'success'
          }
        });
      }
      if (resposne.status === 404) {
        newErrors.error='User not found. Please check your credentials.'
      }
      setErrors(newErrors)
    } catch (error) {
      console.log(error)
    }

  };



  return (
    <Grid container className="change-password-container" sx={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
      <Grid item xs={12} md={6} className="image-grid-item" sx={{ width: '50%', height: '100vh' }}>
        <ImageContainer
          sx={{
            backgroundImage: `url(${BackGround})`,
            height: '100vh',
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          className="image-container"
        />
      </Grid>

      <Grid item xs={12} md={6} className="form-grid-item" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <FormContainer className="form-container" sx={{ width: '32vw', padding: '2rem' }}>
          <Box className="group-one" sx={{ width: '100%', marginBottom: '20px' }}>
            <Typography
              variant="h4"
              color={theme.palette.primary.main}
              className="change-password-title"
              sx={{ fontSize: '24px', fontWeight: 600, marginBottom: '15px' }}
            >
              Change Password
            </Typography>
            <Typography variant="subtitle1" className="instruction-text" sx={{ fontSize: '18px', color: '#92929D' }}>
              Please create a new password.
            </Typography>
          </Box>



          <Box className="group-two" sx={{ width: '100%' }}>
            <GridContainer container spacing={2} className="grid-container">
              <Grid item xs={12}>
                <StyledTextField
                  label="New Password"
                  type="password"
                  name="newPassword"
                  value={formValues.newPassword}
                  onChange={handleChange}
                  required
                  sx={{width:'30vw'}}
                  size="small"
                  className="styled-text-field"
                  error={!!errors.newpassword}
                  helperText={errors.newpassword}
                  autoComplete='off'
                />
              </Grid>
            </GridContainer>

            <GridContainer container spacing={2} className="grid-container-password">
              <Grid item xs={12}>
                <StyledTextField
                  label="Confirm New Password"
                  type="password"
                  name="confirmPassword"
                  value={formValues.confirmPassword}
                  onChange={handleChange}
                  fullWidth
                  required
                  size="small"
                  sx={{width:'30vw'}}
                  className="styled-text-field"
                  error={!!errors.confirmpassword}
                  helperText={errors.confirmpassword}
                  autoComplete='off'
                />
              </Grid>
            </GridContainer>

            <GridContainer container spacing={3} className="grid-container" sx={{ alignItems: 'center' }}>
              <Grid item xs={12}>
                <StyledButton
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="small"
                  sx={{width:'30vw'}}
                  className="styled-button"
                >
                  Change Password
                </StyledButton>
              </Grid>
            </GridContainer>
          </Box>
        </FormContainer>
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
