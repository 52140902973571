import React, { useState, useRef, useEffect } from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import {
    PopupContainer,
    ProfilePicContainer,
    ProfilePicLabel,
    ProfilePic,
    InputContainer,
    CustomInputLabel,
    CustomInput,
    ButtonContainer,
    CancelButton,
    SaveButton,
    StyledTypography,
    Asterisk,
    CroppingContainer,
    RoleSelect,
    ErrorText,
} from './AddUserPopup.styles';
import MenuItem from '@mui/material/MenuItem';
import {
    Checkbox,
    ListItemText,
    SelectChangeEvent,
    Button,
    Dialog,
} from '@mui/material';
import {
    validateEmail,
    validatePassword,
    validateUsername,
} from '../../../../utils/validationUtils';
import { getCroppedImg } from '../../../../utils/cropUtils';



const AddUserPopup: React.FC<{
    onClose: () => void;
    onSave: (data: any) => void;
    isPopupOpen:boolean;
}> = ({ onClose, onSave,isPopupOpen}) => {
    const [username, setUsername] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [role, setRole] = useState<string[]>([]);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [image, setImage] = useState<string | null>(null);
    const [croppedImage, setCroppedImage] = useState<string | null>(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
    const [isCropping, setIsCropping] = useState(false);
    const [showCropping, setShowCropping] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result as string);
            setShowCropping(true);
        };
        reader.readAsDataURL(file);
    };

    const onCropComplete = (_: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const handleCropComplete = async () => {
        if (!image || !croppedAreaPixels) return;

        try {
            setIsCropping(true);
            const croppedImage = await getCroppedImg(image, croppedAreaPixels);
            setCroppedImage(croppedImage);
            setIsCropping(false);
            setShowCropping(false);
        } catch (error) {
            console.error('Error cropping image:', error);
            setIsCropping(false);
        }
    };

    const handleCancelCrop = () => {
        setShowCropping(false);
        setImage(null);
        setCroppedImage(null);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setCroppedAreaPixels(null);
    };
    const handleRoleChange = (event: SelectChangeEvent<unknown>) => {
        const {
            target: { value },
        } = event;

        setRole(
            typeof value === 'string' ? value.split(',') : (value as string[]),
        );
    };
    const handleSave = async () => {
        const newErrors: { [key: string]: string } = {};

        if (!validateUsername(username)) {
            newErrors.username =
                'Username can only contain letters and numbers.';
        }
        if (!validateEmail(email)) {
            newErrors.email = 'Please enter a valid email address.';
        }
        if (!validatePassword(password)) {
            newErrors.password =
                'Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character.';
        }
        if (!role) {
            newErrors.role = 'Please select a role.';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return
        }
        onSave({
            username,
            email,
            password,
            role,
            profilePic: croppedImage,
        });
    };


    return (
        <Dialog open={isPopupOpen} onClose={onClose}>
        <PopupContainer>
            <StyledTypography variant="h5" align="left" gutterBottom>
                Add New User
            </StyledTypography>
            <ProfilePicContainer hasProfilePic={!!croppedImage}>
                <ProfilePicLabel htmlFor="profile-pic-upload">
                    {!image && !croppedImage && (
                        <ProfilePic icon={faUser} />
                    )}
                    {croppedImage && (
                        <img
                            src={croppedImage}
                            alt="Profile"
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                            }}
                        />
                    )}
                </ProfilePicLabel>
                <input
                    type="file"
                    id="profile-pic-upload"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleFileChange}
                    ref={inputRef}
                />
            </ProfilePicContainer>
            {showCropping && image && (
                <CroppingContainer>
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                    />
                    <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => setZoom(zoom as number)}
                    />
                    <ButtonContainer>
                        <CancelButton variant='outlined' sx={{backgroundColor:'white'}} onClick={handleCancelCrop}>
                            Cancel
                        </CancelButton>
                        <SaveButton onClick={handleCropComplete}>
                            Save
                        </SaveButton>
                    </ButtonContainer>
                </CroppingContainer>
            )}
            {isCropping && <CircularProgress />}
            <InputContainer>
                <CustomInputLabel>
                    Username<Asterisk>*</Asterisk>
                </CustomInputLabel>
                <CustomInput
                    type="text"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    disableUnderline
                    required
                />
                {errors.username && (
                    <ErrorText>{errors.username}</ErrorText>
                )}
            </InputContainer>
            <InputContainer>
                <CustomInputLabel>
                    Email Address<Asterisk>*</Asterisk>
                </CustomInputLabel>
                <CustomInput
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disableUnderline
                    required
                />
                {errors.email && <ErrorText>{errors.email}</ErrorText>}
            </InputContainer>
            <InputContainer>
                <CustomInputLabel>
                    Password<Asterisk>*</Asterisk>
                </CustomInputLabel>
                <CustomInput
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disableUnderline
                    required
                />
                {errors.password && (
                    <ErrorText>{errors.password}</ErrorText>
                )}
            </InputContainer>
            <InputContainer>
                <CustomInputLabel>
                    Role<Asterisk>*</Asterisk>
                </CustomInputLabel>
                <RoleSelect
                    // multiple
                    value={role}
                    onChange={handleRoleChange}
                    renderValue={(selected) =>
                        (selected as string[]).join(', ')
                    }
                    required
                >
                    <MenuItem value="Admin">
                        <Checkbox checked={role.indexOf('Admin') > -1} />
                        <ListItemText primary="Admin" />
                    </MenuItem>
                    <MenuItem value="Recruiter">
                        <Checkbox
                            checked={role.indexOf('Recruiter') > -1}
                        />
                        <ListItemText primary="Recruiter" />
                    </MenuItem>
                </RoleSelect>
                {errors.role && <ErrorText>{errors.role}</ErrorText>}
            </InputContainer>
            <ButtonContainer>
                <Button
                    className="cancel-btn"
                    variant="outlined"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    className="save-btn"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={handleSave}
                    sx={{color:'white'}}
                >
                    Save
                </Button>
            </ButtonContainer>
        </PopupContainer>
    </Dialog>
    );
};

export default AddUserPopup;
