import * as React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { theme } from '../../theme';

// Dynamic breadcrumb labels for parts of the path
const breadcrumbNameMap: { [key: string]: string } = {
  dashboard: 'Dashboard',
  joblisting: 'Job Listing',
  'joblisting/create-job': 'Create Job Posting',
  importjobs: 'Import Jobs',
  'internaljobs': 'Import New Job',
  'candidateprofile': 'Candidate Profile',
  'candidateexperience': 'Candidate Experience',
  assigntask: 'Assign Task',
  user: 'User Management',
  communication: 'Communication',
  'communication/dashboard-messages': 'Dashboard Messages',
  'communication/email-templates': 'Manage Email Templates',
  'candidatepreboarding': 'Preboarding',
  'candidatepreboarding/your-offer': 'Your Offer',
  'candidatepreboarding/personal-details': 'Personal Details',
  'candidatepreboarding/documents': 'Documents',
  'candidatepreboarding/onboarding-experience': 'Onboarding Experience',
};

export default function CustomBreadcrumbs() {
  const location = useLocation();
  const { tenant } = useParams<{ tenant: string }>();
  // Break down the current path into breadcrumb segments
  const pathnames = location.pathname.split('/').filter((x) => x);


  // Remove the tenant name (first part of the path)
  const filteredPathnames = pathnames.slice(1);

  // Helper function to convert hyphenated paths to readable format
  const formatBreadcrumbLabel = (label: string) => {
    return label
      .split('-') // Split by hyphen
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join with a space
  };

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ padding: '10px 0px', marginBottom: '10px' }}>
      {/* Home Link with Icon */}
      <Link   style={{textDecoration:'none',color:theme.palette.text.secondary}} to={`/${tenant}/Joblisting`}>
        <i className="fa-solid fa-house" style={{ marginRight: '5px' }} />
      </Link>

      {/* Dynamically create breadcrumbs from the path, excluding tenant */}
      {filteredPathnames.map((value, index) => {
        // Construct path up to this point
        const to = `/${tenant}/${filteredPathnames.slice(0, index + 1).join('/')}`;

        // Get the label from breadcrumbNameMap or format it if not found
        const breadcrumbLabel = breadcrumbNameMap[value.toLowerCase()] 
          || formatBreadcrumbLabel(value);

        const isLast = index === filteredPathnames.length - 1;

        return isLast ? (
          // Last breadcrumb without highlighting and with a different color
          <Typography color={theme.palette.text.secondary} key={to}>
            {breadcrumbLabel}
          </Typography>
        ) : (
          <Link  style={{textDecoration:'none',color:theme.palette.text.secondary}}  to={to} key={to}>
            {breadcrumbLabel}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}

