import React, { useState, forwardRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
  Box,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { theme } from '../../../../theme';

// Slide transition component
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface FilterSortDialogProps {
  open: boolean;
  onClose: () => void;
  onApply: (filters: any) => void;
  statusOptions?:{value:string,label:string}[]
}

const FilterCandidates: React.FC<FilterSortDialogProps> = ({ open, onClose, onApply,statusOptions=[] }) => {
  const [recruiter, setRecruiter] = useState<string>('');
  const [functionName, setFunctionName] = useState<string>('');
  const [position, setPosition] = useState<string>('');
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]); // Track selected statuses

  const handleApplyFilters = () => {
    onApply({
      recruiter,
      functionName,
      position,
      sortByStatus: selectedStatuses,
    });
    onClose();
  };

  const handleClearFilters = () => {
    setRecruiter('');
    setFunctionName('');
    setPosition('');
    setSelectedStatuses([]);
  };

  const handleStatusChange = (value: string) => {
    setSelectedStatuses((prev) =>
      prev.includes(value) ? prev.filter((status) => status !== value) : [...prev, value]
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullScreen
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      PaperProps={{
        sx: {
          width: '90%',
          maxWidth: '350px',
          height: '100vh',
          margin: 0,
          overflow: 'hidden',
          position: 'absolute',
          right: 0,
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', p: '20px 15px 0px 10px' }}>
        <Box
          color={theme.palette.secondary.main}
          sx={{
            border: '1px solid #EEEEEE',
            bgcolor: theme.palette.secondary.light,
            margin: '6px',
            mr: '16px',
            width: '34px',
            height: '34px',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <i className="fa-solid fa-sliders"></i>
        </Box>
        <Box>
          <Typography variant="h6" component="span">Filter & Sort</Typography>
          <Typography variant="body2">Filters to get the best results</Typography>
        </Box>
      </DialogTitle>

      <Divider sx={{ mb: 1.2, mt: 2 }} />

      <DialogContent sx={{ overflowY: 'auto', pt: 0 }}>
        <DialogActions sx={{ justifyContent: 'space-between', p: 0, height: '24px' }}>
          <Typography variant="subtitle1" whiteSpace={"nowrap"}>Add Filter</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button onClick={handleClearFilters} color="secondary" sx={{ ml: 'auto', position: 'relative', right: -10 }}>
              Clear
            </Button>
          </Box>
        </DialogActions>

        {/* Recruiter Input */}
        <TextField
          fullWidth
          size="small"
          label="Recruiter"
          margin="dense"
          value={recruiter}
          onChange={(e) => setRecruiter(e.target.value)}
          autoComplete='off'
        />

        <TextField
          fullWidth
          size="small"
          label="Function"
          margin="dense"
          value={functionName}
          onChange={(e) => setFunctionName(e.target.value)}
          autoComplete='off'
        />

        <TextField
          fullWidth
          size="small"
          label="Position"
          margin="dense"
          value={position}
          onChange={(e) => setPosition(e.target.value)}
          autoComplete='off'
        />

        {/* Sorting Options as Checkboxes */}
        {statusOptions.length > 0 && (
          <>
            <Typography variant="subtitle1" sx={{ mt: 1.5 }}>Sort By Status</Typography>
            {statusOptions.map((option) => (
              <FormControlLabel
                sx={{ display: 'flex' }}
                key={option.value}
                control={
                  <Checkbox
                    checked={selectedStatuses.includes(option.value)}
                    onChange={() => handleStatusChange(option.value)}
                  />
                }
                label={option.label}
              />
            ))}
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box sx={{ display: 'flex', gap: 2, width: '100%', p: '0px 16px' }}>
          <Button onClick={onClose} variant="outlined" fullWidth>
            Cancel
          </Button>
          <Button onClick={handleApplyFilters} variant="contained" fullWidth>
            Apply
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FilterCandidates;
