import React from 'react'
import { Box,Typography } from '@mui/material'
const DefaultPage:React.FC = () => {
  return (
    <Box sx={{textAlign:'center'}}>
        <Typography variant='h2'>
            RecEzy
        </Typography>
    </Box>
  )
}

export default DefaultPage