import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
 
export const StyledButton = styled(Button)<{ isselected?: boolean }>(({ theme, isselected }) => ({
   minWidth: '80px' ,
  '&:hover': {
    color: theme.palette.primary.main, // Font color on hover
    borderRight: `0.0694vw solid ${theme.palette.primary.main}`,
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light, // Background color on hover
  },
  '&:focus': {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderRight: `0.0694vw solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.light,
  },
  ...(isselected && {
 
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main, // Border color when selected
    borderRight: `1px solid ${theme.palette.primary.main} !important`,
    backgroundColor: theme.palette.primary.light, // Background color when selected
  }),
  '&.MuiButton-root': {
    ...(isselected && {
      marginRight: '1px !important',
      borderRight: `1px solid ${theme.palette.primary.main} !important`,
    }),
}}));
 
