import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import JobCard from './JobCard';
import axios from 'axios';
import JobsRightPane from '../JobsRightPane/JobsRightPane';
import { useJobStore } from '../useJobStore';
import APIBaseUrl from '../../../../AppConfig/AppConfig.json'
import { useParams } from 'react-router-dom';

type TJobData = {
    JobTitle: string;
    JobSector: string;
    JobStatusData: string;
    JobNum: number;
    CreatedDate: number;
};
interface JobsResultProps {
    filters: string[]; // New prop to receive filters
    jobTitleSearch: string;
}


const JobsResult: React.FC<JobsResultProps> = ({ filters, jobTitleSearch }) => {
    const [jobResults, setJobResults] = useState<TJobData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [user, setUser] = useState()
    const { selectedJob, selectJob } = useJobStore();
    const { tenant } = useParams<{ tenant: string }>();
    const baseURL = window.location.origin.endsWith('/')
        ? window.location.origin
        : `${window.location.origin}/`;


    // Fetch job details for the selected job
    const fetchJobDetails = useCallback(async (jobNum: number) => {
        try {
            const response = await axios.get(`${APIBaseUrl.apiBaseUrl}/job/jobdetails/${jobNum}`,
                {
                    headers: {
                        'X-Tenant': tenant,
                        'X-App': baseURL
                    }
                });
            selectJob(response.data); // Update the Zustand store with job details
        } catch (error) {
            console.error('Failed to fetch job details:', error);
        }
    }, [selectJob]);


    useEffect(() => {
        const fetchJobResults = async () => {
            try {
                let response
                if (filters.includes("Open") && filters.includes("Close")) {
                    response = await axios.get(`${APIBaseUrl.apiBaseUrl}/job/jobs`,
                        {
                            headers: {
                                'X-Tenant': tenant,
                                'X-App': baseURL
                            }
                        });
                } else if (filters.includes("Open")) {
                    response = await axios.get(`${APIBaseUrl.apiBaseUrl}/job/get_openjobs`,
                        {
                            headers: {
                                'X-Tenant': tenant,
                                'X-App': baseURL
                            }
                        });
                } else if (filters.includes("Close")) {
                    response = await axios.get(`${APIBaseUrl.apiBaseUrl}/job/get_closejobs`,
                        {
                            headers: {
                                'X-Tenant': tenant,
                                'X-App': baseURL
                            }
                        });
                } else if (jobTitleSearch.length > 0) {
                    response = await axios.get(`${APIBaseUrl.apiBaseUrl}/job/jobtitle_search/${jobTitleSearch.toLowerCase()}`,
                        {
                            headers: {
                                'X-Tenant': tenant,
                                'X-App': baseURL
                            }
                        })
                }
                else {
                    response = await axios.get(`${APIBaseUrl.apiBaseUrl}/job/get_openjobs`,
                        {
                            headers: {
                                'X-Tenant': tenant,
                                'X-App': baseURL
                            }
                        });
                }
                const jobs = response.data
                setJobResults(jobs);
                if (jobs.length > 0) {
                    const defaultJob = jobs[0];
                    selectJob(defaultJob); // Set the first job as selected
                    await fetchJobDetails(defaultJob.JobNum);
                }
                else {
                    selectJob(null); // Clear selected job if no jobs available
                }
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch job data');
                setLoading(false);
            }
        };

        fetchJobResults();
    }, [fetchJobDetails, selectJob, filters, jobTitleSearch, selectedJob?.Status]);


    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography variant="body1" color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box className="job-search-result">
            <Typography
                variant="caption"
                textAlign="left"
                component="p"
                sx={{ mb: 1 }}
            >
                Showing {jobResults.length} Jobs
            </Typography>
            <Box>
                {jobResults.length > 0 ? (
                    jobResults.map((jobData) => (
                        <JobCard
                            key={jobData.JobNum}
                            jobData={jobData}
                            selected={jobData.JobNum === selectedJob?.JobNum}
                            jobSelectHandler={(jobData) => { selectJob(jobData); fetchJobDetails(jobData.JobNum); }}

                        />

                    ))
                ) : (
                    <Typography variant="body1">No jobs available.</Typography>
                )}
            </Box>
        </Box>
    );
};

export default JobsResult;
