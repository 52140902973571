import React, { useState, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { StyledDialog } from './JobsDetailsPopup.styles';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Grid,
    useTheme,
    LinearProgress,
    CircularProgress,
    Dialog
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import DeleteIcon from '@mui/icons-material/Delete';
import { IFieldData } from '../CreateJob/CreateJob';
import { StyledNewFieldContainer } from '../CreateJob/CreateJob.styles';
import CreateNewField from '../CreateJob/CreateNewField/CreateNewField';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import APIBaseUrl from '../../../../AppConfig/AppConfig.json'
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import 'react-quill/dist/quill.snow.css';

type TJobData = {
    JobTitle: string;
    Department: string;
    Status: string;
    JobNum: number;
    CreatedDate: any | Date;
    JobDescr: string;
    CreatedBy: string;
};
interface Field {
    FieldName: string;
    FieldValue: string;
}
const JobsDetailsPopup: React.FC<{
    onClose: () => void;
    isOpen: any;
    data: TJobData | null;
    onUploadSuccess:()=>void;
}> = ({ onClose, isOpen, data,onUploadSuccess }) => {
    const theme = useTheme();
    const [newFieldsData, setNewFieldsData] = useState<IFieldData[]>([]);
    const [showNewFieldContainer, setShowNewFieldContainer] = useState(
        !!newFieldsData.length,
    );
    const [jobFieldData, setJobFieldData] = useState<Field[]>([])
    const { tenant } = useParams<{ tenant: string }>(); 
    const baseURL = window.location.origin.endsWith('/') 
  ? window.location.origin 
  : `${window.location.origin}/`;
    

    useEffect(() => {
        const fetchdata = async () => {
            try {
                const response = await axios.get(`${APIBaseUrl.apiBaseUrl}/job/get_jobfield/${data?.JobNum}`,
                    {
                        headers: {
                            'X-Tenant': tenant,
                            'X-App': baseURL
                        }
                    })
                setJobFieldData(response.data)
            } catch (error) {
                console.error(error)
            }
        }
        fetchdata()
    }, [data?.JobNum])

    

    const handleAddMoreField = () => {
        if (!showNewFieldContainer) setShowNewFieldContainer(true);
        const initFieldData: IFieldData = {
            fieldKey: newFieldsData.length + 1,
            fieldName: '',
            values: [],
        };
        setNewFieldsData(newFieldsData.concat(initFieldData));
    };

    const onFieldDataUpdate = (newData: IFieldData) => {
        const updatedFieldsData: IFieldData[] = newFieldsData.map(
            (data: IFieldData) => {
                if (data.fieldKey === newData.fieldKey) {
                    return newData;
                }
                return data;
            },
        );
        setNewFieldsData(updatedFieldsData);
    };

    const onDeleteField = (deleteKey: number) => {
        const updatedFieldsData: IFieldData[] = newFieldsData.filter(
            (data: IFieldData) => data.fieldKey !== deleteKey,
        );
        setNewFieldsData(updatedFieldsData);
    };
    const [includeAllCandidates, setIncludeAllCandidates] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [dragOver, setDragOver] = useState(false);
    const navigate=useNavigate()
    const [loading, setLoading] = useState<boolean>(false);

    

    const fileHandle= async()=>{
        if (!selectedFile) {
            console.error('No file selected.');
            return;
        }
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('JobNum', data?.JobNum);
        setLoading(true)
        try{
           const response=await axios.post(`${APIBaseUrl.apiBaseUrl}/job/upload_file`,formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Tenant': tenant,
                    'X-App': baseURL
                }
            })
            if (response.status===200){
                onUploadSuccess()
                onClose()
            }
            
        }catch(error){
            console.error(error)
        }
        finally{
            setLoading(false)
        }
    }

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setIncludeAllCandidates(event.target.checked);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.name.endsWith('.zip')) {
            setSelectedFile(file);
            setUploadProgress(0);
            setUploading(true);
            setUploadComplete(false);
            uploadFile(file);
        } else {
            alert('Please upload a ZIP file only.');
        }
    };

    const uploadFile = (file: File) => {
        const totalChunks = 100;
        let uploadedChunks = 0;

        const interval = setInterval(() => {
            uploadedChunks += 10;
            setUploadProgress((uploadedChunks / totalChunks) * 100);

            if (uploadedChunks >= totalChunks) {
                clearInterval(interval);
                setUploading(false);
                setUploadComplete(true);
            }
        }, 200);
    };

    const handleUploadClick = () => {
        document.getElementById('file-input')?.click();
    };

    const handleDeleteFile = () => {
        setSelectedFile(null);
        setUploadProgress(0);
        setUploading(false);
        setUploadComplete(false);
        setDragOver(false);
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        setDragOver(true);
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        setDragOver(false);
        const file = e.dataTransfer.files[0];
        if (file && file.name.endsWith('.zip')) {
            setSelectedFile(file);
            setUploading(true);
            setUploadComplete(false);
            uploadFile(file);
        } else {
            alert('Please upload a ZIP file only.');
        }
    };

    const handleDragLeave = () => {
        setDragOver(false);
    };

    if (loading) {
        return (
            <Dialog open={loading} PaperProps={{style:{width:'500px',height:'250px'}}}>
            <DialogContent style={{ textAlign: 'center', display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'center' }}>
                <CircularProgress style={{ height: '60px', width: '60px' }}/>
                <Typography variant="h6" style={{ marginTop: '16px' }}>
                    Analyzing the resumes data please wait...
                </Typography>
            </DialogContent>
        </Dialog>
        );
    }
    
    return (
        <StyledDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            className="job-details-dialog"
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Job profiling - {data?.JobTitle}{' '}
                <span>({data?.Department})</span>
                <p>
                    The job details entered during the creation process are
                    pre-populated and you can add more fields to enhance
                    filtering accuracy.
                </p>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent className="job-detail-content">
                <Grid container spacing={0} className="container">
                    <Grid item className="job-details-wrapper" xs={8}>
                        <Box className="job-details-section">
                            <Typography
                                variant="h6"
                                textAlign="left"
                                sx={{ mb: 1 }}
                            >
                                Job Descriptions{' '}
                                <span className="mandatory">*</span>
                            </Typography>
                            <Box>
                            <Typography
                                textAlign="left"
                                component="p"
                                sx={{ mb: 1 }}
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.JobDescr) }}
                            >
                            </Typography>
                            </Box>
                            
                            {jobFieldData && jobFieldData.map((item, index) => {
                                return (
                                    <Box key={index} sx={{ mb: 2 }}>
                                        <Typography
                                            variant="h6"
                                            textAlign="left"
                                            sx={{ mb: 1 }}
                                        >
                                            {item.FieldName}
                                        </Typography>
                                        {item.FieldName.toLowerCase() == "skills" ? (
                                            <Box className="skill-chip">
                                                    <Stack spacing={1}>
                                                        <Stack direction="row" spacing={1} gap={'5px'} flexWrap="wrap">
                                                            {item.FieldValue.split(',').map((skill) => (
                                                                <Chip key={`${skill}-${index}`} label={skill} />
                                                            ))}
                                                        </Stack>
                                                    </Stack>
                                            
                                            </Box>
                                        ) : (
                                            <Typography
                                                variant="caption"
                                                textAlign="left"
                                                sx={{ mb: 1 }}
                                            >
                                                {item.FieldValue}
                                            </Typography>
                                        )}
                                    </Box>
                                )
                            })}
                            <Box
                                sx={{
                                    mt: 3,
                                }}
                            >
                                {showNewFieldContainer &&
                                    newFieldsData.length > 0 && (
                                        <StyledNewFieldContainer
                                            sx={{
                                                border: 'none',
                                            }}
                                        >
                                            <Grid container>
                                                <Grid xs={3} item={true}>
                                                    <Typography
                                                        variant="body1"
                                                        className="title-text"
                                                    >
                                                        Input Field
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={9} item={true}>
                                                    <Typography
                                                        variant="body1"
                                                        className="title-text"
                                                    >
                                                        Value
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            {newFieldsData.map((data) => (
                                                <CreateNewField
                                                    key={data.fieldKey}
                                                    fieldKey={data.fieldKey}
                                                    onFieldDataUpdate={
                                                        onFieldDataUpdate
                                                    }
                                                    onDeleteField={
                                                        onDeleteField
                                                    }
                                                ></CreateNewField>
                                            ))}
                                        </StyledNewFieldContainer>
                                    )}
                                <Box className="add-more">
                                    <Button
                                        variant="contained"
                                        disableElevation
                                        sx={{
                                            mb: 2,
                                            py: '10px',
                                            borderRadius: '30px',
                                            backgroundColor:
                                                theme.palette.custom.infoBg,
                                            '&:hover': {
                                                color: theme.palette.custom
                                                    .white,
                                            },
                                        }}
                                        onClick={handleAddMoreField}
                                    >
                                        + Add fields
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item className="file-wrapper" xs={4}>
                        <Box
                            className="upload-section"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box>
                                <RadioGroup
                                    aria-labelledby="upload-radio-buttons-group-label"
                                    defaultValue="upload-file"
                                    name="upload-radio-buttons-group"
                                    sx={{ mb: 2, flexDirection: 'row' }}
                                >
                                    <FormControlLabel
                                        value="upload-file"
                                        control={<Radio />}
                                        label="Upload File"
                                    />
                                    <FormControlLabel
                                        value="link"
                                        control={<Radio />}
                                        label="Link"
                                        disabled
                                    />
                                </RadioGroup>

                                {!selectedFile && (
                                    <Box
                                        className={`upload-area ${dragOver ? 'drag-over' : ''}`}
                                        onClick={handleUploadClick}
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleDrop}
                                        sx={{
                                            cursor: 'pointer',
                                            border: dragOver
                                                ? '2px dashed #2B7A77'
                                                : '2px dashed #d9d9d9',
                                            padding: '20px',
                                            textAlign: 'center',
                                            borderRadius: '4px',
                                            backgroundColor: dragOver
                                                ? '#e8f9f4'
                                                : 'transparent',
                                            transition:
                                                'background-color 0.3s ease-in-out',
                                        }}
                                    >
                                        <CloudUploadIcon
                                            sx={{
                                                fontSize: 40,
                                                color: '#2B7A77',
                                                mb: 1,
                                            }}
                                        />
                                        <Typography
                                            variant="body2"
                                            textAlign="center"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontWeight: 600,
                                                    color: '#2B7A77',
                                                }}
                                            >
                                                Click to upload &nbsp;
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontWeight: 400,
                                                }}
                                            >
                                                or Drag and drop file
                                            </Typography>
                                        </Typography>

                                        <Typography
                                            variant="caption"
                                            textAlign="center"
                                        >
                                            (Max file size: 5MB)
                                        </Typography>
                                        <input
                                            id="file-input"
                                            type="file"
                                            accept=".zip"
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                    </Box>
                                )}

                                {(uploading || uploadComplete) &&
                                    selectedFile && (
                                        <Box
                                            sx={{
                                                width: '262px',
                                                height: '73px',
                                                padding: '8px',
                                                borderRadius: '4px',
                                                border: '1px solid #d9d9d9',
                                                position: 'relative',
                                            }}
                                        >
                                            <IconButton
                                                onClick={handleDeleteFile}
                                                sx={{
                                                    position: 'absolute',
                                                    top: '10px',
                                                    right: '4px',
                                                    padding: '2px',
                                                    color: '#ff0000',
                                                }}
                                            >
                                                <DeleteIcon
                                                    style={{
                                                        color: '#C7D1D8',
                                                        fontSize: 30,
                                                        marginTop: '10px',
                                                    }}
                                                />
                                            </IconButton>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        mr: 1,
                                                        marginTop: '10px',
                                                    }}
                                                >
                                                    <FolderZipIcon
                                                        style={{
                                                            color: '#EFD631',
                                                            fontSize: 40,
                                                        }}
                                                    />
                                                </Box>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems:
                                                            'flex-start',
                                                        marginTop: '10px',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        noWrap
                                                        sx={{
                                                            fontSize: '12px',
                                                            maxWidth: '180px',
                                                            whiteSpace:
                                                                'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow:
                                                                'ellipsis',
                                                        }}
                                                    >
                                                        {selectedFile.name}
                                                    </Typography>

                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontSize: '10px',
                                                            color: '#9AA4AA',
                                                        }}
                                                    >
                                                        {(
                                                            selectedFile.size /
                                                            (1024 * 1024)
                                                        ).toFixed(2)}{' '}
                                                        MB
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <LinearProgress
                                                variant="determinate"
                                                value={uploadProgress}
                                                sx={{
                                                    mt: 1,
                                                    width: '85%',
                                                    backgroundColor: '#D9D9D9',
                                                    '& .MuiLinearProgress-bar':
                                                    {
                                                        backgroundColor:
                                                            '#598EFC',
                                                    },
                                                }}
                                            />

                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    position: 'absolute',
                                                    bottom: '12px',
                                                    right: '4px',
                                                }}
                                            >
                                                {Math.round(uploadProgress)}%
                                            </Typography>
                                        </Box>
                                    )}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={includeAllCandidates}
                                            onChange={handleCheckboxChange}
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="body2"
                                            sx={{ fontSize: '12px', mt: '5px' }}
                                        >
                                            Include all candidates from the
                                            system.
                                        </Typography>
                                    }
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    mt: 3,
                                }}
                            >
                                
                                <Button
                                    variant="outlined"
                                    onClick={onClose}
                                    sx={{ mr: 2 }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={ fileHandle}
                                    sx={{
                                        color: '#fff',
                                    }}
                                    disabled={uploading}
                                >
                                    Save & Run
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </StyledDialog>
    );
};

export default JobsDetailsPopup;
